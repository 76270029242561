import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GmailIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-76.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<g>
				<path
					d="M5.52273 17.625H7.90909V11.8295L4.5 9.27271V16.6023C4.5 17.1682 4.95852 17.625 5.52273 17.625Z"
					fill="#4285F4"
				/>
				<path
					d="M16.0908 17.625H18.4772C19.0431 17.625 19.4999 17.1665 19.4999 16.6023V9.27271L16.0908 11.8295"
					fill="#34A853"
				/>
				<path
					d="M16.0908 7.3977V11.8295L19.4999 9.2727V7.90906C19.4999 6.64429 18.0562 5.92327 17.0454 6.68179"
					fill="#FBBC04"
				/>
				<path
					d="M7.90918 11.8295V7.39771L12.0001 10.4659L16.091 7.39771V11.8295L12.0001 14.8977"
					fill="#EA4335"
				/>
				<path
					d="M4.5 7.90931V9.27294L7.90909 11.8298V7.39794L6.95455 6.68203C5.94205 5.92351 4.5 6.64453 4.5 7.90931Z"
					fill="#C5221F"
				/>
			</g>
		</svg>
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="37 37 115 80">
			<path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6" />
			<path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15" />
			<path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2" />
			<path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92" />
			<path fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2" />
		</svg>
	);
};

import type { EntityATI } from '@atlassian/search-client';

/**
 * The set of possible values the universal type filter shows a user
 *
 * This is the superset. Only values that have results for a 1P/3P available to an end user will be shown
 *
 * Each connector maps nouns, subtypes into this list
 */
export const TypeFilterValueKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	Repository: 'repository',
	PullRequest: 'pull-request',
	Branch: 'branch',
	Commit: 'commit',
	WebPage: 'web-page',
	Conversation: 'conversation',
	Message: 'message',
	Video: 'video',
	Design: 'design',
	Page: 'page',
	Blogpost: 'blogpost',
	Attachment: 'attachment',
	Space: 'space',
	Whiteboard: 'whiteboard',
	Comment: 'comment',
	Database: 'database',
	Embed: 'embed',
	Folder: 'folder',
	Calendar: 'calendar',
	SpaceCalendar: 'space-calendar',
	Question: 'question',
	Answer: 'answer',
	Issue: 'issue',
	Component: 'component',
	Card: 'card',
	Project: 'project',
	Goal: 'goal',
	Tag: 'tag',
	WorkItem: 'work-item',
	Incident: 'incident',
	FocusArea: 'focus-area',
	Dashboard: 'dashboard',
	Invoice: 'invoice',
	Deal: 'deal',
	Gliffy: 'gliffy',
	CustomerOrg: 'customer-org',
} as const;
export type TypeFilterValueKey = (typeof TypeFilterValueKey)[keyof typeof TypeFilterValueKey];
export const allTypeFilterValueKeys = Object.values(TypeFilterValueKey);

/**
 * Subtypes used to separate nouns
 *
 * These are statically mapped to a noun below
 */
export const NounSubtypeKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	File: 'file',
	Database: 'database',
	WebPage: 'web-page',
	Page: 'page',
	Blogpost: 'blogpost',
	Dashboard: 'dashboard',
	Invoice: 'invoice',
	Design: 'design',
	Deal: 'deal',
	Video: 'video',
	Task: 'task',
	Issue: 'issue',
	Space: 'space',
	Project: 'project',
	Repository: 'repository',
	CustomerOrg: 'customer-org',
} as const;
export type NounSubtypeKey = (typeof NounSubtypeKey)[keyof typeof NounSubtypeKey];

export type TypeFilterValue3PEntities = { entities3P: EntityATI[]; subTypes3P: NounSubtypeKey[] };

export type TypeFilterConfig3P = Map<TypeFilterValueKey, TypeFilterValue3PEntities>;

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GmailSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-71.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<g>
				<path
					d="M7.36364 23.8302H10.5455V16.1029L6 12.6938V22.4665C6 23.2211 6.61136 23.8302 7.36364 23.8302Z"
					fill="#4285F4"
				/>
				<path
					d="M21.4546 23.8302H24.6364C25.391 23.8302 26 23.2188 26 22.4665V12.6938L21.4546 16.1029"
					fill="#34A853"
				/>
				<path
					d="M21.4546 10.1939V16.103L26 12.6939V10.8757C26 9.18931 24.075 8.22795 22.7273 9.23931"
					fill="#FBBC04"
				/>
				<path
					d="M10.5454 16.1029V10.1938L16 14.2848L21.4545 10.1938V16.1029L16 20.1938"
					fill="#EA4335"
				/>
				<path
					d="M6 10.876V12.6942L10.5455 16.1033V10.1942L9.27273 9.23968C7.92273 8.22832 6 9.18968 6 10.876Z"
					fill="#C5221F"
				/>
			</g>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_19058)">
				<path
					d="M7.04545 31.25H11.8182V19.659L5 14.5454V29.2045C5 30.3363 5.91705 31.25 7.04545 31.25Z"
					fill="#4285F4"
				/>
				<path
					d="M28.1818 31.25H32.9546C34.0864 31.25 35 30.3329 35 29.2045V14.5454L28.1818 19.659"
					fill="#34A853"
				/>
				<path
					d="M28.1818 10.7955V19.6591L35 14.5455V11.8182C35 9.28864 32.1125 7.8466 30.0909 9.36364"
					fill="#FBBC04"
				/>
				<path
					d="M11.8182 19.659V10.7954L20 16.9318L28.1818 10.7954V19.659L20 25.7954"
					fill="#EA4335"
				/>
				<path
					d="M5 11.8182V14.5455L11.8182 19.6591V10.7955L9.90909 9.36364C7.88409 7.8466 5 9.28864 5 11.8182Z"
					fill="#C5221F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2230_19058">
					<rect width="30" height="22.5" fill="white" transform="translate(5 8.75)" />
				</clipPath>
			</defs>
		</svg>
	);
};

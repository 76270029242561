import { AnyAri } from '@atlassian/ari';

import type { Maybe, SearchResult } from '../../../../common/types';
import type { ThirdPartyUser } from '../types';

import { ExternalBranchPartial } from './ExternalBranchPartial';
import { ExternalCalendarEventPartial } from './ExternalCalendarEventPartial';
import { ExternalCommentPartial } from './ExternalCommentPartial';
import { ExternalCommitPartial } from './ExternalCommitPartial';
import { ExternalConversationPartial } from './ExternalConversationPartial';
import { ExternalDesignPartial } from './ExternalDesignPartial';
import { ExternalDocumentPartial } from './ExternalDocumentPartial';
import { ExternalMessagePartial } from './ExternalMessagePartial';
import { ExternalPullRequestPartial } from './ExternalPullRequestPartial';
import { ExternalRepositoryPartial } from './ExternalRepositoryPartial';
import { ExternalVideoPartial } from './ExternalVideoPartial';
import { ExternalWorkItemPartial } from './ExternalWorkItemPartial';

const TEAMS_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';

const SearchThirdPartyGraphDocumentPartial = `
... on SearchResultGraphDocument {
	excerpt,
	permissionLevel,
	initialContributors {
		name
	}
	subtype
	integrationId
	providerId
	lastModifiedDate
	linkedEntities {
		id
		title
		excerpt,
		permissionLevel,
		subtype
		integrationId
		lastModifiedDate
		entity @skip(if: $skip3pEntityHydration) {
			${ExternalMessagePartial}
		}
	}
	entity @skip(if: $skip3pEntityHydration) {
		${ExternalBranchPartial}
		${ExternalCalendarEventPartial}
		${ExternalCommitPartial}
		${ExternalConversationPartial}
		${ExternalDesignPartial}
		${ExternalDocumentPartial}
		${ExternalMessagePartial}
		${ExternalPullRequestPartial}
		${ExternalRepositoryPartial}
		${ExternalVideoPartial}
		${ExternalWorkItemPartial}
		${ExternalCommentPartial}
	}
}
`;

export type SearchResultEntity =
	| ExternalBranch
	| ExternalCalendarEvent
	| ExternalComment
	| ExternalCommit
	| ExternalConversation
	| ExternalDesign
	| ExternalDocument
	| ExternalMessage
	| ExternalPullRequest
	| ExternalRepository
	| ExternalVideo
	| ExternalWorkItem;

export type SearchResultBranchPartial = SearchResultGraphDocument<ExternalBranch>;
export type SearchResultCalendarEventPartial = SearchResultGraphDocument<ExternalCalendarEvent>;
export type SearchResultCommitPartial = SearchResultGraphDocument<ExternalCommit>;
export type SearchResultCommentPartial = SearchResultGraphDocument<ExternalComment>;
export type SearchResultConversationPartial = SearchResultGraphDocument<ExternalConversation>;
export type SearchResultDesignPartial = SearchResultGraphDocument<ExternalDesign>;
export type SearchResultDocumentPartial = SearchResultGraphDocument<ExternalDocument>;
export type SearchResultMessagePartial = SearchResultGraphDocument<ExternalMessage>;
export type SearchResultPullRequestPartial = SearchResultGraphDocument<ExternalPullRequest>;
export type SearchResultRepositoryPartial = SearchResultGraphDocument<ExternalRepository>;
export type SearchResultSpacePartial = SearchResultGraphDocument<ExternalSpace>;
export type SearchResultVideoPartial = SearchResultGraphDocument<ExternalVideo>;
export type SearchResultWorkItemPartial = SearchResultGraphDocument<ExternalWorkItem>;

export type ThirdPartyRestrictionLevel = 'restricted' | 'public' | 'private';

export interface SearchResultGraphDocument<EntityType extends SearchResultEntity>
	extends SearchResult {
	excerpt?: string;
	permissionLevel: Maybe<ThirdPartyRestrictionLevel>;
	subtype?: string;
	integrationId?: string;
	providerId?: string;
	lastModifiedDate?: string;
	initialContributors?: ThirdPartyUser[];
	linkedEntities?: SearchResultGraphDocument<EntityType>[];
	entity?: EntityType;
	containerName?: string;
}

enum AccountStatus {
	active,
	inactive,
	closed,
}

export interface ExternalUser {
	thirdPartyUser?: ThirdPartyUser;
	user?: {
		id: string;
		accountId: string;
		canonicalAccountId: string;
		accountStatus: AccountStatus;
		name: string;
		picture: string;
	};
}

interface ExternalThumbnail {
	externalUrl?: string;
}

enum ExternalDocumentCategory {
	ARCHIVE,
	AUDIO,
	CODE,
	DOCUMENT,
	FOLDER,
	FORM,
	IMAGE,
	OTHER,
	PDF,
	PRESENTATION,
	SHORTCUT,
	SPREADSHEET,
	VIDEO,
	WEB_PAGE,
}

interface ExternalDocumentType {
	category?: ExternalDocumentCategory;
	fileExtension?: string;
	iconUrl?: string;
	mimeType?: string;
}

interface ExternalLargeContent {
	asText?: string;
	mimeType?: string;
}

interface ExternalExportLink {
	mimeType?: string;
	url?: string;
}

export interface ExternalDocument {
	byteSize?: number;
	collaborators?: ExternalUser[];
	content?: ExternalLargeContent;
	createdAt?: string;
	createdBy?: ExternalUser;
	displayName?: string;
	exportLinks?: ExternalExportLink[];
	externalId?: string;
	id: string;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	owners?: ExternalUser[];
	parentId?: string;
	thumbnail?: ExternalThumbnail;
	thirdPartyId?: string;
	truncatedDisplayName?: boolean;
	type?: ExternalDocumentType;
	updateSequenceNumber?: number;
	url?: string;
	parent?: ExternalDocument;
	container?: ExternalSpace;
}

export interface ExternalDesign {
	thumbnail?: { externalUrl?: string };
	thirdPartyId?: string;
}

export type ExternalVideo = {
	thumbnailUrl?: string;
	durationInSeconds?: number;
	chapters?: ExternalChapter[];
	createdBy?: ExternalUser;
	thirdPartyId?: string;
};

export type ExternalChapter = {
	title?: string;
	startTimeInSeconds?: number;
};

export interface ExternalMessage {
	attachments?: ExternalAttachment[];
	container?: ExternalConversation;
	createdAt?: string;
	createdBy?: ExternalUser;
	description?: string;
	displayName?: string;
	externalId?: string;
	hidden?: boolean;
	id: string;
	isPinned?: boolean;
	largeContentDescription?: ExternalLargeContent;
	lastActive?: string;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	owners?: ExternalUser[];
	parentId?: string;
	thumbnail?: ExternalThumbnail;
	updateSequenceNumber?: number;
	url?: string;
	associatedWith?: {
		edges?: AssociationEdge<AssociationEdgeEntity>[];
	};
	thirdPartyId?: string;
}

export type AssociationEdgeEntity = ThirdPartyUser | ExternalConversation;
export interface AssociationEdge<T extends AssociationEdgeEntity> {
	node: { entity: T; title?: string };
}

export interface ExternalAttachment {
	title?: string;
	byteSize?: number;
	mimeType?: string;
	thumbnailUrl?: string;
	url?: string;
}

export interface ExternalBranch {
	name?: string;
	container?: Pick<ExternalRepository, 'name'>;
	thirdPartyId?: string;
}

export interface ExternalComment {
	container?: Pick<ExternalSpace, 'displayName' | 'url'>;
	thirdPartyId?: string;
}

export interface ExternalCommit {
	author: ExternalUser;
	container?: Pick<ExternalRepository, 'name'>;
	thirdPartyId?: string;
}

export interface ExternalPullRequest {
	title?: string;
	lastUpdate?: string;
	pullRequestId?: string;
	container?: Pick<ExternalRepository, 'name'>;
	thirdPartyId?: string;
}

export interface ExternalRepository {
	avatarDescription?: string;
	avatarUrl?: string;
	description?: string;
	forkOfId?: string;
	id: string;
	repositoryId?: string;
	url?: string;
	name?: string;
	thirdPartyId?: string;
}

export interface ExternalConversation {
	__typename?: 'ExternalConversation';
	createdAt?: string;
	createdBy?: ExternalUser;
	channelId?: string;
	description?: string;
	displayName?: string;
	externalId?: string;
	id: string;
	isArchived?: boolean;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	memberCount?: number;
	members?: Array<ExternalUser | null>;
	membershipType?: ExternalMembershipType;
	owners?: Array<ExternalUser | null>;
	topic?: string;
	type?: ExternalConversationType;
	updateSequenceNumber?: number;
	url?: string;
	workspace?: string;
	associatedWith?: {
		edges?: AssociationEdge<AssociationEdgeEntity>[];
	};
	thirdPartyId?: string;
}

export interface ExternalAttendee {
	isOptional?: boolean;
	rsvpStatus?: ExternalAttendeeRsvpStatus;
	user?: ExternalUser;
}

export interface ExternalCalendarEvent {
	attachments?: ExternalCalendarEventAttachment[];
	attendees?: ExternalAttendee[];
	createdBy: ExternalUser;
	eventEndTime: string;
	eventStartTime: string;
	eventType?: ExternalEventType;
	location?: ExternalLocation;
	recurringEventId?: string;
	videoMeetingUrl?: string;
	thirdPartyId?: string;
}

export interface ExternalCalendarEventAttachment {
	byteSize?: number;
	mimeType?: string;
	thumbnailUrl?: string;
	title?: string;
	url?: string;
}

export interface ExternalLocation {
	name?: string;
	address?: string;
	url?: string;
	coordinates?: string;
}

enum ExternalEventType {
	APPOINTMENT = 'appointment',
	BIRTHDAY = 'birthday',
	DEFAULT = 'default',
	EVENT = 'event',
	FOCUS_TIME = 'focus_time',
	OUT_OF_OFFICE = 'out_of_office',
	REMINDER = 'reminder',
	TASK = 'task',
	WORKING_LOCATION = 'working_location',
}

enum ExternalAttendeeRsvpStatus {
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
	NOT_RESPONDED = 'not_responded',
	OTHER = 'other',
	TENATIVELY_ACCEPTED = 'tenatively_accepted',
}

export interface ExternalWorkItem {
	assignee?: ExternalUser;
	attachments?: ExternalWorkItemAttachment[];
	collaborators?: ExternalUser[];
	createdBy: ExternalUser;
	dueDate?: string;
	exceedsMaxCollaborators?: boolean;
	workItemProject?: ExternalWorkItemProject;
	status: string;
	subtype: ExternalWorkItemType;
	team: string;
	thirdPartyId?: string;
}

interface ExternalWorkItemProject {
	id: string;
	name: string;
}

interface ExternalWorkItemAttachment {
	byteSize?: number;
	mimeType?: string;
	thumbnailUrl?: string;
	title?: string;
	url?: string;
}

enum ExternalWorkItemType {
	TASK = 'task',
	DEFAULT_TASK = 'default_task',
	WORK_ITEM = 'work_item',
	EPIC = 'epic',
	BUG = 'bug',
	STORY = 'story',
	MILESTONE = 'milestone',
	SECTION = 'section',
	APPROVAL = 'approval',
	PROBLEM = 'problem',
	INCIDENT = 'incident',
	QUESTION = 'question',
	ISSUE = 'issue',
	OTHER = 'other',
}

enum ExternalMembershipType {
	PRIVATE = 'PRIVATE',
	PUBLIC = 'PUBLIC',
	SHARED = 'SHARED',
}

enum ExternalConversationType {
	CHANNEL = 'CHANNEL',
	DIRECT_MESSAGE = 'DIRECT_MESSAGE',
	GROUP_DIRECT_MESSAGE = 'GROUP_DIRECT_MESSAGE',
}

enum ResourceType {
	BRANCH = 'branch',
	CALENDAR_EVENT = 'calendar-event',
	COMMENT = 'comment',
	COMMIT = 'commit',
	CONVERSATION = 'conversation',
	DESIGN = 'design',
	DOCUMENT = 'document',
	MESSAGE = 'message',
	PULL_REQUEST = 'pull-request',
	REPOSITORY = 'repository',
	VIDEO = 'video',
	WORK_ITEM = 'work-item',
}

export interface ExternalSpace {
	associatedWith?: {
		edges?: AssociationEdge<AssociationEdgeEntity>[];
	};
	createdAt?: string;
	createdBy?: ExternalUser;
	description?: string;
	displayName: string;
	externalId?: string;
	icon?: ExternalIcon;
	id: string;
	key: string;
	labels?: string[];
	lastUpdated: string;
	lastUpdatedBy?: string;
	spaceType: string;
	subtype?: string;
	updateSequenceNumber: number;
	url: string;
}

export type ExternalIcon = {
	height?: number;
	isDefault?: boolean;
	url?: string;
	width?: number;
};

export const getGraphPartials = () => {
	//TODO: update for GitHub graph entities
	return SearchThirdPartyGraphDocumentPartial;
};

export const isSearchResultGraphEntity = (
	partial: SearchResult,
): partial is SearchResultGraphDocument<SearchResultEntity> =>
	partial.__typename === 'SearchResultGraphDocument';

export const isConversationEntity = (
	partial: SearchResult,
): partial is SearchResultConversationPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.CONVERSATION;
};

export const isDesignEntity = (partial: SearchResult): partial is SearchResultDesignPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.DESIGN;
};

export const isMessageEntity = (partial: SearchResult): partial is SearchResultMessagePartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.MESSAGE;
};

export const isVideoEntity = (partial: SearchResult): partial is SearchResultVideoPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.VIDEO;
};

export const isBranchEntity = (partial: SearchResult): partial is SearchResultBranchPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.BRANCH;
};

export const isCommitEntity = (partial: SearchResult): partial is SearchResultCommitPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.COMMIT;
};

export const isCommentEntity = (partial: SearchResult): partial is SearchResultCommentPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.COMMENT;
};

export const isPullRequestEntity = (
	partial: SearchResult,
): partial is SearchResultPullRequestPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.PULL_REQUEST;
};

export const isRepositoryEntity = (
	partial: SearchResult,
): partial is SearchResultRepositoryPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.REPOSITORY;
};

export const isDocumentEntity = (partial: SearchResult): partial is SearchResultDocumentPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.DOCUMENT;
};

export const isCalendarEventEntity = (
	partial: SearchResult,
): partial is SearchResultCalendarEventPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.CALENDAR_EVENT;
};

export const isTeamsMessage = (partial: SearchResult) =>
	isMessageEntity(partial) && partial.integrationId === TEAMS_INTEGRATION_ARI;

export const isWorkItemEntity = (partial: SearchResult): partial is SearchResultWorkItemPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.WORK_ITEM;
};

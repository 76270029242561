import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createBooleanFilter } from '../../../../filters/boolean-filter';

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('verified');

export const filter = createBooleanFilter({
	id: 'verified',
	queryParams: { key: 'verified' },
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: true,
	universal: false,
});

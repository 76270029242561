import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SharePointIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-650.5"
				y="-28.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#0B1228"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<g>
				<path
					d="M12.1863 13.1429C14.6523 13.1429 16.6514 11.0962 16.6514 8.57143C16.6514 6.0467 14.6523 4 12.1863 4C9.72029 4 7.72119 6.0467 7.72119 8.57143C7.72119 11.0962 9.72029 13.1429 12.1863 13.1429Z"
					fill="#036C70"
				/>
				<path
					d="M15.907 16.9525C18.1675 16.9525 20 15.0763 20 12.762C20 10.4477 18.1675 8.57153 15.907 8.57153C13.6465 8.57153 11.814 10.4477 11.814 12.762C11.814 15.0763 13.6465 16.9525 15.907 16.9525Z"
					fill="#1A9BA1"
				/>
				<path
					d="M12.7443 20.0001C14.4911 20.0001 15.9071 18.5504 15.9071 16.762C15.9071 14.9737 14.4911 13.5239 12.7443 13.5239C10.9976 13.5239 9.58154 14.9737 9.58154 16.762C9.58154 18.5504 10.9976 20.0001 12.7443 20.0001Z"
					fill="#37C6D0"
				/>
				<path
					opacity="0.1"
					d="M12.9302 8.12585V16.2554C12.9283 16.5379 12.7611 16.7916 12.506 16.8992C12.4248 16.9344 12.3374 16.9525 12.2492 16.9525H9.58506C9.58134 16.8878 9.58134 16.8268 9.58134 16.762C9.5801 16.6984 9.58258 16.6348 9.58878 16.5716C9.65688 15.3538 10.3896 14.2794 11.4827 13.7944V13.0858C9.04996 12.6911 7.39032 10.3521 7.77583 7.86137C7.77851 7.84412 7.78127 7.82688 7.78414 7.80966C7.80266 7.68118 7.82876 7.55396 7.86228 7.42871H12.2493C12.6247 7.43017 12.9287 7.74144 12.9302 8.12585Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8772 7.80957H7.78421C7.37074 10.2958 9.00415 12.6544 11.4325 13.0777C11.5061 13.0906 11.5799 13.1015 11.654 13.1105C10.5005 13.6705 9.65993 15.261 9.58849 16.5715C9.5823 16.6348 9.57981 16.6984 9.58104 16.7619C9.58104 16.8267 9.58104 16.8877 9.58476 16.9524C9.59147 17.0805 9.60765 17.2078 9.63313 17.3334H11.8769C12.1528 17.3315 12.4006 17.1603 12.5057 16.8991C12.54 16.8159 12.5578 16.7265 12.5578 16.6362V8.50671C12.5564 8.12245 12.2526 7.81125 11.8772 7.80957Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8772 7.80957H7.78418C7.37079 10.296 9.00445 12.6548 11.4331 13.078C11.4828 13.0867 11.5326 13.0945 11.5825 13.1014C10.4662 13.7018 9.65879 15.2884 9.58883 16.5715H11.8772C12.2521 16.5686 12.5553 16.2581 12.5581 15.8743V8.50671C12.5567 8.1223 12.2527 7.81103 11.8772 7.80957Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.505 7.80957H7.78405C7.39371 10.1568 8.8303 12.4172 11.0845 13.0027C10.231 14.0012 9.70744 15.2502 9.58871 16.5715H11.505C11.8805 16.57 12.1845 16.2587 12.1859 15.8743V8.50671C12.1857 8.12178 11.881 7.80978 11.505 7.80957Z"
					fill="black"
				/>
				<path
					d="M4.68205 7.80957H11.504C11.8807 7.80957 12.186 8.1222 12.186 8.50786V15.4922C12.186 15.8779 11.8807 16.1905 11.504 16.1905H4.68205C4.30536 16.1905 4 15.8779 4 15.4922V8.50786C4 8.1222 4.30536 7.80957 4.68205 7.80957Z"
					fill="#026D71"
				/>
				<path
					d="M7.04621 11.9147C6.88638 11.8062 6.75286 11.6617 6.65588 11.4922C6.56192 11.3151 6.51508 11.1158 6.52007 10.9143C6.5117 10.6415 6.60162 10.3751 6.77272 10.1658C6.95251 9.95618 7.1849 9.80099 7.44472 9.717C7.74082 9.61722 8.05096 9.56805 8.36267 9.57148C8.77258 9.55614 9.18193 9.61482 9.57197 9.74481V10.621C9.40249 10.5159 9.21791 10.4388 9.02499 10.3924C8.81567 10.3399 8.60088 10.3135 8.38536 10.314C8.15809 10.3054 7.93227 10.3544 7.72787 10.4564C7.57007 10.5261 7.46774 10.6851 7.46741 10.861C7.46677 10.9677 7.50682 11.0705 7.57904 11.1475C7.66434 11.2382 7.76525 11.3121 7.87672 11.3654C8.00074 11.4286 8.18679 11.5127 8.43485 11.6176C8.46217 11.6264 8.48879 11.6374 8.51448 11.6504C8.75862 11.748 8.99415 11.867 9.21848 12.0058C9.38837 12.113 9.53095 12.26 9.63449 12.4347C9.74064 12.6328 9.79209 12.8566 9.78332 13.0824C9.79544 13.3624 9.71174 13.6381 9.54667 13.8618C9.38213 14.0674 9.16216 14.219 8.91411 14.2976C8.62236 14.3912 8.31786 14.4365 8.01215 14.4317C7.73787 14.433 7.46399 14.41 7.19355 14.3631C6.96521 14.3249 6.74237 14.2578 6.53011 14.1635V13.2397C6.733 13.388 6.95967 13.4989 7.19988 13.5673C7.43928 13.6437 7.68814 13.6845 7.93886 13.6884C8.17091 13.7035 8.40255 13.6532 8.60863 13.5429C8.75299 13.4595 8.84079 13.3015 8.83709 13.1319C8.83806 13.0138 8.79244 12.9004 8.71058 12.8172C8.60879 12.7149 8.49093 12.6308 8.36194 12.5684C8.2131 12.4922 7.99393 12.3918 7.70445 12.2671C7.47415 12.1723 7.25365 12.0543 7.04621 11.9147Z"
					fill="white"
				/>
			</g>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_271_181641)">
				<g clipPath="url(#clip1_271_181641)">
					<path
						d="M12.2328 13.4286C15.3153 13.4286 17.8142 10.8702 17.8142 7.71429C17.8142 4.55837 15.3153 2 12.2328 2C9.15024 2 6.65137 4.55837 6.65137 7.71429C6.65137 10.8702 9.15024 13.4286 12.2328 13.4286Z"
						fill="#036C70"
					/>
					<path
						d="M16.8836 18.1906C19.7093 18.1906 21.9999 15.8454 21.9999 12.9525C21.9999 10.0595 19.7093 7.71436 16.8836 7.71436C14.058 7.71436 11.7673 10.0595 11.7673 12.9525C11.7673 15.8454 14.058 18.1906 16.8836 18.1906Z"
						fill="#1A9BA1"
					/>
					<path
						d="M12.9303 22C15.1137 22 16.8838 20.1878 16.8838 17.9524C16.8838 15.717 15.1137 13.9048 12.9303 13.9048C10.7468 13.9048 8.97681 15.717 8.97681 17.9524C8.97681 20.1878 10.7468 22 12.9303 22Z"
						fill="#37C6D0"
					/>
					<path
						opacity="0.1"
						d="M13.1628 7.15707V17.319C13.1605 17.6721 12.9515 17.9893 12.6326 18.1237C12.5311 18.1677 12.4219 18.1904 12.3117 18.1904H8.98144C8.9768 18.1094 8.9768 18.0333 8.9768 17.9523C8.97525 17.8728 8.97835 17.7933 8.9861 17.7142C9.07123 16.192 9.98715 14.849 11.3535 14.2428V13.3571C8.31257 12.8637 6.23802 9.93985 6.71991 6.82647C6.72326 6.8049 6.72671 6.78335 6.73029 6.76183C6.75344 6.60124 6.78607 6.44221 6.82797 6.28564H12.3117C12.781 6.28747 13.1611 6.67656 13.1628 7.15707Z"
						fill="black"
					/>
					<path
						opacity="0.2"
						d="M11.8464 6.76196H6.73014C6.2133 9.86973 8.25507 12.818 11.2906 13.3472C11.3825 13.3632 11.4748 13.3769 11.5673 13.3882C10.1255 14.0882 9.07479 16.0762 8.98549 17.7143C8.97775 17.7934 8.97463 17.8729 8.97618 17.9524C8.97618 18.0334 8.97618 18.1096 8.98083 18.1905C8.98922 18.3506 9.00944 18.5098 9.04129 18.6667H11.8459C12.1908 18.6644 12.5007 18.4504 12.632 18.1239C12.6749 18.0199 12.6971 17.9082 12.6971 17.7953V7.63339C12.6953 7.15306 12.3156 6.76406 11.8464 6.76196Z"
						fill="black"
					/>
					<path
						opacity="0.2"
						d="M11.8466 6.76196H6.73034C6.21361 9.87002 8.25569 12.8185 11.2915 13.3475C11.3536 13.3583 11.4158 13.3681 11.4783 13.3767C10.0829 14.1272 9.07361 16.1105 8.98616 17.7143H11.8466C12.3152 17.7107 12.6942 17.3227 12.6978 16.8429V7.63339C12.696 7.15288 12.316 6.76379 11.8466 6.76196Z"
						fill="black"
					/>
					<path
						opacity="0.2"
						d="M11.3814 6.76196H6.73019C6.24226 9.696 8.03799 12.5215 10.8558 13.2534C9.78883 14.5014 9.13442 16.0628 8.98601 17.7143H11.3814C11.8507 17.7125 12.2307 17.3234 12.2325 16.8429V7.63339C12.2323 7.15223 11.8513 6.76223 11.3814 6.76196Z"
						fill="black"
					/>
					<path
						d="M2.85256 6.76196H11.38C11.8509 6.76196 12.2326 7.15275 12.2326 7.63482V16.3653C12.2326 16.8474 11.8509 17.2382 11.38 17.2382H2.85256C2.3817 17.2382 2 16.8474 2 16.3653V7.63482C2 7.15275 2.3817 6.76196 2.85256 6.76196Z"
						fill="#04868B"
					/>
					<path
						d="M5.80776 11.8932C5.60797 11.7575 5.44108 11.5769 5.31985 11.3651C5.20239 11.1437 5.14385 10.8946 5.15008 10.6427C5.13962 10.3017 5.25202 9.96868 5.46589 9.70702C5.69064 9.44505 5.98113 9.25105 6.30589 9.14606C6.67602 9.02134 7.0637 8.95988 7.45333 8.96416C7.96573 8.945 8.47741 9.01835 8.96496 9.18083V10.2761C8.75312 10.1447 8.52238 10.0483 8.28124 9.99036C8.01958 9.92467 7.7511 9.89173 7.4817 9.89226C7.19761 9.88158 6.91534 9.94276 6.65984 10.0704C6.46259 10.1575 6.33467 10.3561 6.33426 10.5761C6.33346 10.7095 6.38352 10.838 6.4738 10.9342C6.58043 11.0476 6.70656 11.1399 6.84589 11.2066C7.00093 11.2856 7.23349 11.3907 7.54357 11.5218C7.57771 11.5328 7.61099 11.5465 7.6431 11.5628C7.94828 11.6849 8.24269 11.8335 8.5231 12.007C8.73546 12.1411 8.91368 12.3249 9.04311 12.5432C9.1758 12.7908 9.24011 13.0706 9.22915 13.3528C9.2443 13.7029 9.13968 14.0474 8.93334 14.327C8.72767 14.5841 8.45269 14.7736 8.14264 14.8718C7.77795 14.9888 7.39733 15.0454 7.01519 15.0394C6.67234 15.041 6.32999 15.0123 5.99194 14.9537C5.70651 14.9059 5.42796 14.8221 5.16264 14.7042V13.5494C5.41625 13.7349 5.69959 13.8735 5.99985 13.9589C6.2991 14.0544 6.61017 14.1054 6.92357 14.1104C7.21363 14.1292 7.50318 14.0663 7.76078 13.9285C7.94123 13.8242 8.05098 13.6267 8.04637 13.4147C8.04757 13.2671 7.99055 13.1253 7.88823 13.0213C7.76098 12.8934 7.61366 12.7883 7.45242 12.7104C7.26638 12.6151 6.99242 12.4896 6.63056 12.3337C6.34269 12.2152 6.06706 12.0677 5.80776 11.8932Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_271_181641">
					<rect width="20" height="20" fill="white" transform="translate(2 2)" />
				</clipPath>
				<clipPath id="clip1_271_181641">
					<rect width="20" height="20" fill="white" transform="translate(2 2)" />
				</clipPath>
			</defs>
		</svg>
	);
};

import cloneDeep from 'lodash/cloneDeep';

import { fg } from '@atlaskit/platform-feature-flags';
import { type EntityATI } from '@atlassian/search-client';

import { ThirdPartyConfigs } from '../../constants/3p-product-configs';
import {
	CONFLUENCE_ANSWER_ENTITY_ATI,
	CONFLUENCE_CALENDAR_ENTITY_ATI,
	CONFLUENCE_GLIFFY_ENTITY_ATI,
	CONFLUENCE_QUESTION_ENTITY_ATI,
	CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI,
	JIRA_ISSUE_ENTITY_ATI,
	JIRA_PROJECT_ENTITY_ATI,
} from '../../constants/entities';
import type { TypeFilterMapping, UniversalTypeFilterMapping } from '../../constants/filters/types';
import {
	allTypeFilterValueKeys,
	NounSubtypeKey,
	TypeFilterValueKey,
} from '../../constants/filters/universal-type';
import { ProductKeys, type ProductKeys1P } from '../../constants/products';

export type TypeFilterProductMapping1P = Map<TypeFilterValueKey, EntityATI[]>;
/**
 * A mapping between 1P Products
 * - ProductKey1P e.g. 'confluence'
 *
 * UI values for the type filter
 * - TypeFilterKey e.g. 'Spreadsheet'
 *
 * and entities to request from Aggregator
 *
 * - ATIString[] e.g. ['ati:cloud:confluence:blogpost']
 */
const typeFilter1PMapping = new Map<ProductKeys1P, TypeFilterProductMapping1P>([
	[
		ProductKeys.Confluence,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[
				TypeFilterValueKey.Document,
				[
					'ati:cloud:confluence:blogpost',
					'ati:cloud:confluence:page',
					'ati:cloud:confluence:attachment',
				],
			],
			[TypeFilterValueKey.Page, ['ati:cloud:confluence:page']],
			[TypeFilterValueKey.Blogpost, ['ati:cloud:confluence:blogpost']],
			[TypeFilterValueKey.Attachment, ['ati:cloud:confluence:attachment']],
			[TypeFilterValueKey.Space, ['ati:cloud:confluence:space']],
			[TypeFilterValueKey.Comment, ['ati:cloud:confluence:comment']],
			[TypeFilterValueKey.Whiteboard, ['ati:cloud:confluence:whiteboard']],
			[TypeFilterValueKey.Database, ['ati:cloud:confluence:database']],
			[TypeFilterValueKey.Embed, ['ati:cloud:confluence:embed']],
			[TypeFilterValueKey.Folder, ['ati:cloud:confluence:folder']],
			[TypeFilterValueKey.Calendar, [CONFLUENCE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.SpaceCalendar, [CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.Question, [CONFLUENCE_QUESTION_ENTITY_ATI]],
			[TypeFilterValueKey.Answer, [CONFLUENCE_ANSWER_ENTITY_ATI]],
			[TypeFilterValueKey.Gliffy, [CONFLUENCE_GLIFFY_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Jira,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraProductDiscovery,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraServiceManagement,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
			[TypeFilterValueKey.Incident, [JIRA_ISSUE_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Compass,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Component, ['ati:cloud:compass:component']],
		]),
	],
	[
		ProductKeys.Focus,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[
				TypeFilterValueKey.FocusArea,
				['ati:cloud:mercury:focus-area', 'ati:cloud:mercury:focus-area-status-update'],
			],
		]),
	],
	[
		ProductKeys.Trello,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Card, ['ati:cloud:trello:card']],
		]),
	],
	[
		ProductKeys.Atlas,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Project, ['ati:cloud:townsquare:project']],
			[TypeFilterValueKey.Goal, ['ati:cloud:townsquare:goal']],
			[TypeFilterValueKey.Tag, ['ati:cloud:townsquare:tag']],
		]),
	],
	[
		ProductKeys.Bitbucket,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Commit, ['ati:cloud:graph:commit']],
			[TypeFilterValueKey.PullRequest, ['ati:cloud:graph:pull-request']],
			[TypeFilterValueKey.Repository, ['ati:cloud:graph:repository']],
			[TypeFilterValueKey.Branch, ['ati:cloud:graph:branch']],
		]),
	],
]);

// Simple first-call-fills cache
let cachedTypeMapping: UniversalTypeFilterMapping;
const getCachedTypeMapping: () => UniversalTypeFilterMapping = () => {
	if (cachedTypeMapping) {
		return cachedTypeMapping;
	}

	const mappings: UniversalTypeFilterMapping = Object.fromEntries(
		allTypeFilterValueKeys.map((TypeFilterValueKey) => {
			const baseMapping: TypeFilterMapping = {
				entities: {},
				products: [],
				subCategories: [],
			};
			return [TypeFilterValueKey as TypeFilterValueKey, baseMapping];
		}),
	) as UniversalTypeFilterMapping;
	// Add all the 1P products
	typeFilter1PMapping.forEach((_, productKey1P) => {
		typeFilter1PMapping.get(productKey1P)?.forEach((_, typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey1P);
			mappings[typeFilterValueKey].entities[productKey1P] = typeFilter1PMapping
				.get(productKey1P)
				?.get(typeFilterValueKey);
		});
	});
	// Add all the 3P products
	ThirdPartyConfigs.forEach((thirdPartyConfig, productKey3P) => {
		const typeFilterConfigs = thirdPartyConfig?.typeFilterConfig;

		if (!typeFilterConfigs) {
			return;
		}

		typeFilterConfigs.forEach((typeFilterValue3PEntities, typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey3P);

			mappings[typeFilterValueKey].entities[productKey3P] = typeFilterValue3PEntities.entities3P;

			mappings[typeFilterValueKey].subCategories = [
				...mappings[typeFilterValueKey].subCategories,
				...typeFilterValue3PEntities.subTypes3P,
			];
			mappings[typeFilterValueKey].subCategories = [
				...new Set(mappings[typeFilterValueKey].subCategories),
			]; // dedupe
		});
	});

	cachedTypeMapping = mappings;
	return mappings;
};

// Simple first-call-fills cache
let cachedTypeMappingWithSmartlinkSubtypes: UniversalTypeFilterMapping;
// Cleanup during fg('smartlink-subtypes')
const getTypeMappingWithSmartlinkSubtypes: () => UniversalTypeFilterMapping = () => {
	if (cachedTypeMappingWithSmartlinkSubtypes) {
		return cachedTypeMappingWithSmartlinkSubtypes;
	}

	// we need to deep clone, otherwise the below modifies our constant
	// This is short-lived, smartlink-subtypes is rolled out and during cleanup this can be removed
	// cloneDeep shouldn't have performance concerns, this is a small fixed size object
	let typeMappings = cloneDeep(getCachedTypeMapping());

	typeMappings[TypeFilterValueKey.Video].subCategories = [NounSubtypeKey.Video];
	typeMappings[TypeFilterValueKey.Design].subCategories = [NounSubtypeKey.Design];
	typeMappings[TypeFilterValueKey.Dashboard].subCategories = [NounSubtypeKey.Dashboard];
	typeMappings[TypeFilterValueKey.Invoice].subCategories = [NounSubtypeKey.Invoice];
	typeMappings[TypeFilterValueKey.Deal].subCategories = [NounSubtypeKey.Deal];
	typeMappings[TypeFilterValueKey.WorkItem].subCategories = [NounSubtypeKey.Task];

	cachedTypeMappingWithSmartlinkSubtypes = typeMappings;
	return typeMappings;
};

/**
 * @returns an array of disabled ATIs in the 'All' types filter state, if there are any
 */
export const featureGateNotRequestedATIsInAllTypesFilter = (
	dropAttachmentsComments: boolean,
): EntityATI[] => {
	return (
		[
			[dropAttachmentsComments, 'ati:cloud:confluence:comment' satisfies EntityATI],
			[dropAttachmentsComments, 'ati:cloud:confluence:attachment' satisfies EntityATI],
		] as const
	).flatMap(([dropATI, entityATI]) => (dropATI ? entityATI : []));
};

export const getTypeMapping: () => UniversalTypeFilterMapping = () => {
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (fg('smartlink-subtypes')) {
		return getTypeMappingWithSmartlinkSubtypes();
	} else {
		return getCachedTypeMapping();
	}
};

import React, { useCallback } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import type { ProductKeys3P } from '../../../common/constants/3p-product-configs';
import { is3pProductKey } from '../../../common/constants/products';
import { BulkConnectDialog } from '../../../common/ui/bulk-connect-dialog';
import {
	useBulkConnectModalOpenState,
	useBulkConnectState,
	useInitializeBulkConnect,
	useNewlyConnectedProducts,
} from '../../../controllers/bulk-connect';
import { useOAuthHandlers } from '../../../controllers/oauth';
import { useBootstrap } from '../../../controllers/store';
import { useFeatureFlaggedLocalStorageCallback } from '../../../controllers/store/external-storage';
import { useIsBulkConnectModalOpen as useIsBulkConnectModalOpenQuickFind } from '../../../controllers/store/quick-find';

export const BulkConnectModal = ({ isOnSearchDialog }: { isOnSearchDialog?: boolean }) => {
	const [{ thirdPartyConfigs, products: availableProducts }] = useBootstrap();
	const newlyConnectedProducts = useNewlyConnectedProducts();
	const availableProductsAs3P = availableProducts.filter(
		(productKey): productKey is ProductKeys3P => is3pProductKey(productKey),
	);
	const useLocalStorage = useFeatureFlaggedLocalStorageCallback();
	const [storedState, setState] = useLocalStorage();
	const quickFindState = useIsBulkConnectModalOpenQuickFind();
	const fullPageState = useBulkConnectModalOpenState();

	const [isBulkConnectModalOpen, { setIsBulkConnectModalOpen }] = isOnSearchDialog
		? quickFindState
		: [
				fullPageState.isBulkConnectModalOpen,
				{ setIsBulkConnectModalOpen: fullPageState.setIsBulkConnectModalOpen },
			];

	const onCloseModal = useCallback(
		(wasConnected: boolean, closedByButton: boolean) => {
			if (wasConnected && closedByButton) {
				setState({
					...storedState,
					bulkConnectBanner: {
						initialBannerDismissed: true,
						secondaryBannerDismissed: true,
					},
				});
			}
			setIsBulkConnectModalOpen(false);
		},
		[setIsBulkConnectModalOpen, setState, storedState],
	);

	return (
		<ModalTransition>
			{isBulkConnectModalOpen && (
				<BulkConnectDialog
					availableProducts={availableProductsAs3P}
					newlyConnected={newlyConnectedProducts}
					onClose={onCloseModal}
					thirdPartyConfigs={thirdPartyConfigs}
					useOAuthHandlers={useOAuthHandlers}
					useInitializeBulkConnect={useInitializeBulkConnect}
					useBulkConnectState={useBulkConnectState}
				/>
			)}
		</ModalTransition>
	);
};

import { type MercuryFocusAreaType, mercuryFocusAreaTypesQuery } from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import { type FetchedSelectFilterOption } from '../../../../../common/constants/filters/select-filter/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

/**
 * Queries all focus area types from graphQL and returns all options as suggested but unselected
 * @param config
 * @returns
 */
const getInitialOptions = async (config: CloudConfig): Promise<FetchedSelectFilterOption[]> => {
	const focusAreaTypesResponse = await mercuryFocusAreaTypesQuery(config.cloudId || '');
	const focusAreaTypes: MercuryFocusAreaType[] =
		focusAreaTypesResponse.data?.mercury.focusAreaTypes ?? [];

	return focusAreaTypes.map((focusAreaType) => ({
		trackingKey: focusAreaType.id,
		label: focusAreaType.name,
		value: focusAreaType.id,
		queryParamValue: focusAreaType.id,
	}));
};

/**
 * Queries all focus area types from graphQL and returns all options as suggested and query params are suggested
 * @param queryParams
 * @param config
 * @returns
 */
const getOptionsWithSelectionsFromQueryParams = async (
	queryParams: string[],
	config: CloudConfig,
): Promise<FetchedSelectFilterOption[]> => {
	const focusAreaTypeOptions = await getInitialOptions(config);
	return focusAreaTypeOptions.filter((option) => queryParams.includes(option.value));
};

/**
 * Queries all focus area types from graphQL and filters out options that do not match the text query
 * @param query
 * @param config
 * @returns
 */
const getOptionsFilteredByTextQuery = async (
	query: string,
	config: CloudConfig,
): Promise<FetchedSelectFilterOption[]> => {
	const focusAreaTypeOptions = await getInitialOptions(config);
	return focusAreaTypeOptions.filter((option) =>
		option.label.toLowerCase().includes(query.toLowerCase()),
	);
};

const productKeys1P: ProductKeys1P[] = [ProductKeys.Focus];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('owner');

export const filter = createMultiSelectFilter({
	id: 'focusAreaType',
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: true,
	universal: false,
	queryParams: {
		key: 'focusAreaTypes',
		fetcher: getOptionsWithSelectionsFromQueryParams,
	},
	initialOptions: {
		fetcher: getInitialOptions,
	},
	lookup: {
		fetcher: getOptionsFilteredByTextQuery,
	},
});

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

const iconContainerStyles = {
	small: xcss({
		width: '16px',
		height: '16px',
	}),
	medium: xcss({
		width: '24px',
		height: '24px',
	}),
	large: xcss({
		width: '40px',
		height: '40px',
	}),
	xlarge: xcss({
		width: '48px',
		height: '48px',
	}),
} as const;

const t25IconContainerStyles = {
	small: xcss({
		width: '16px',
		height: '16px',
	}),
	medium: xcss({
		width: '24px',
		height: '24px',
	}),
	large: xcss({
		width: '32px',
		height: '32px',
	}),
	//t25 tile icons do not get bumped up to 40/48px to avoid additional padding
	xlarge: xcss({
		width: '32x',
		height: '32px',
	}),
} as const;

type Size = 'small' | 'medium' | 'large' | 'xlarge';

export interface SVGIconProps {
	glyph: () => JSX.Element;
	label?: string;
	size: Size;
}

const iconStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const SVGIcon = ({ glyph: SVG, label, size }: SVGIconProps) => {
	const useT25Styles = fg('platform-team25-app-icon-tiles');
	return (
		<Box
			xcss={[useT25Styles ? t25IconContainerStyles[size] : iconContainerStyles[size], iconStyles]}
			aria-label={label}
		>
			<SVG />
		</Box>
	);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GoogleDriveIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-587.5"
				y="-28.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<g>
				<path
					d="M5.20962 17.0782L5.91523 18.2907C6.06186 18.546 6.27262 18.7466 6.52005 18.8925L9.04009 14.5529H4C4 14.8355 4.07331 15.1181 4.21993 15.3734L5.20962 17.0782Z"
					fill="#0066DA"
				/>
				<path
					d="M12 9.44751L9.47995 5.10791C9.23253 5.25378 9.02176 5.45435 8.87514 5.70962L4.21993 13.7324C4.07601 13.9822 4.00019 14.265 4 14.5529H9.04009L12 9.44751Z"
					fill="#00AC47"
				/>
				<path
					d="M17.4799 18.8925C17.7273 18.7466 17.9381 18.546 18.0847 18.2907L18.3779 17.7893L19.78 15.3734C19.9266 15.1181 19.9999 14.8355 19.9999 14.5529H14.9595L16.032 16.6497L17.4799 18.8925Z"
					fill="#EA4335"
				/>
				<path
					d="M12 9.4475L14.5201 5.1079C14.2726 4.96203 13.9886 4.8891 13.6953 4.8891H10.3047C10.0115 4.8891 9.7274 4.97115 9.47998 5.1079L12 9.4475Z"
					fill="#00832D"
				/>
				<path
					d="M14.9594 14.5529H9.03958L6.51953 18.8925C6.76695 19.0383 7.05103 19.1113 7.34427 19.1113H16.6547C16.9479 19.1113 17.232 19.0292 17.4794 18.8925L14.9594 14.5529Z"
					fill="#2684FC"
				/>
				<path
					d="M17.4525 9.72101L15.1249 5.70962C14.9782 5.45435 14.7675 5.25378 14.52 5.10791L12 9.44751L14.9599 14.5529H19.9908C19.9908 14.2703 19.9175 13.9877 19.7709 13.7324L17.4525 9.72101Z"
					fill="#FFBA00"
				/>
			</g>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_271_181643)">
				<g clipPath="url(#clip1_271_181643)">
					<rect x="2" y="2" width="20" height="20" rx="0.75" fill="white" fillOpacity="0.01" />
					<g clipPath="url(#clip2_271_181643)">
						<path
							d="M3.51203 18.5459L4.39404 20.1089C4.57732 20.438 4.84078 20.6965 5.15006 20.8845L8.30011 15.2905H2C2 15.6548 2.09164 16.0192 2.27491 16.3482L3.51203 18.5459Z"
							fill="#0066DA"
						/>
						<path
							d="M12 8.70925L8.84994 3.11523C8.54066 3.30327 8.27721 3.56182 8.09393 3.89088L2.27491 14.2328C2.09501 14.5547 2.00024 14.9193 2 15.2904H8.30011L12 8.70925Z"
							fill="#00AC47"
						/>
						<path
							d="M18.85 20.8845C19.1593 20.6965 19.4227 20.438 19.606 20.1089L19.9725 19.4625L21.7251 16.3482C21.9084 16.0192 22 15.6548 22 15.2905H15.6995L17.0401 17.9935L18.85 20.8845Z"
							fill="#EA4335"
						/>
						<path
							d="M12.0002 8.70932L15.1502 3.1153C14.8409 2.92727 14.4858 2.83325 14.1193 2.83325H9.88103C9.51447 2.83325 9.15938 2.93902 8.8501 3.1153L12.0002 8.70932Z"
							fill="#00832D"
						/>
						<path
							d="M15.6997 15.2905H8.29996L5.1499 20.8845C5.45918 21.0726 5.81428 21.1666 6.18083 21.1666H17.8189C18.1854 21.1666 18.5405 21.0608 18.8498 20.8845L15.6997 15.2905Z"
							fill="#2684FC"
						/>
						<path
							d="M18.8156 9.06182L15.9061 3.89088C15.7228 3.56182 15.4593 3.30327 15.1501 3.11523L12 8.70925L15.6999 15.2904H21.9885C21.9885 14.9261 21.8969 14.5618 21.7136 14.2328L18.8156 9.06182Z"
							fill="#FFBA00"
						/>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_271_181643">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip1_271_181643">
					<rect x="2" y="2" width="20" height="20" rx="0.75" fill="white" />
				</clipPath>
				<clipPath id="clip2_271_181643">
					<rect width="20" height="18.3333" fill="white" transform="translate(2 2.83325)" />
				</clipPath>
			</defs>
		</svg>
	);
};

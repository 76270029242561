import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AzureDevopsIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#0078D4"
			/>
			<path
				d="M19.5 7.30929V16.4293L15.75 19.5L9.9375 17.3839V19.4818L6.64714 15.1821L16.2375 15.9321V7.71964L19.5 7.30929ZM16.3029 7.76786L10.9221 4.5V6.64393L5.98071 8.09571L4.5 9.99643V14.3132L6.61929 15.2486V9.71679L16.3029 7.76786Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19 7.622V16.134L15.5 19L10.075 17.025V18.983L7.004 14.97L15.955 15.67V8.005L19 7.622ZM16.016 8.05L10.994 5V7.001L6.382 8.356L5 10.13V14.159L6.978 15.032V9.869L16.016 8.05Z"
				fill="url(#paint0_linear_24_1001)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_24_1001"
					x1="12"
					y1="18.956"
					x2="12"
					y2="5.026"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0078D4" />
					<stop offset="0.16" stopColor="#1380DA" />
					<stop offset="0.53" stopColor="#3C91E5" />
					<stop offset="0.82" stopColor="#559CEC" />
					<stop offset="1" stopColor="#5EA0EF" />
				</linearGradient>
			</defs>
		</svg>
	);
};

import { type ProductKeys3P } from '../../common/constants/3p-product-configs';
import { is3pProductKey } from '../../common/constants/products';
import { isProductSmartlink } from '../../common/utils/oauth-container';
import { useBootstrap } from '../store';
import {
	useBulkConnectActions,
	useIsBulkConnectModalOpen,
	useProductConnectedInSession,
	useProductOrder,
	useProductStatuses,
} from '../store/3p-bulk-connect';
import { useFeatureFlaggedLocalStorageCallback } from '../store/external-storage';

const TWENTY_FOUR_HOURS_MS = 24 * 60 * 60 * 1000;

export type ProductKeyWithTimestampMap = Partial<Record<ProductKeys3P, string>>;

export const isProductKeyWithTimestampMap = (value: any): value is ProductKeyWithTimestampMap => {
	if (!value || typeof value !== 'object') {
		return false;
	}

	return Object.entries(value).every(
		([key, timestamp]) =>
			is3pProductKey(key) && typeof timestamp === 'string' && !isNaN(parseInt(timestamp, 10)),
	);
};

export const useNewlyConnectedProducts = () => {
	const useLocalStorage = useFeatureFlaggedLocalStorageCallback();
	const [storedState] = useLocalStorage();
	const [{ thirdPartyConfigs }] = useBootstrap();

	const getNewProducts = () => {
		const currentTime = Date.now();
		const previous3pProducts: ProductKeyWithTimestampMap = isProductKeyWithTimestampMap(
			storedState?.previous3pProducts,
		)
			? storedState.previous3pProducts
			: {};

		const entries: [ProductKeys3P, number][] = Object.entries(previous3pProducts).map(
			([key, timestamp]) => [key as ProductKeys3P, parseInt(timestamp, 10)],
		);
		return entries
			.filter(([productKey, timestamp]) => {
				const productConfig = thirdPartyConfigs?.[productKey];
				if (!productConfig) {
					return false;
				}
				return (
					currentTime - timestamp < TWENTY_FOUR_HOURS_MS && // check if the product was noticed within the last 24 hours
					productConfig.userNeedsOAuth && // check if the user needs to OAuth
					!isProductSmartlink({ productKey, thirdPartyConfigs }) && // make sure the connector is not a smartlink type
					!productConfig.isFederated && // make sure the connector is not federated type
					productConfig.isEnabled
				);
			})
			.sort((a, b) => b[1] - a[1]) // ordered by the most recent product
			.map(([productKey]) => productKey);
	};

	return getNewProducts();
};

export const useInitializeBulkConnect = (
	availableProducts: ProductKeys3P[],
	recentlyConnectedProducts: ProductKeys3P[],
) => {
	const [{ thirdPartyConfigs }] = useBootstrap();
	const { initializeProductState } = useBulkConnectActions();
	initializeProductState(availableProducts, recentlyConnectedProducts, thirdPartyConfigs);
};

export const useBulkConnectState = () => {
	const { setProductStatus, setProductConnectedInSession } = useBulkConnectActions();
	const [productStatuses] = useProductStatuses();
	const [productOrder] = useProductOrder();
	const [productConnectedInSession] = useProductConnectedInSession();
	return {
		productStatuses,
		productOrder,
		productConnectedInSession,
		setProductStatus,
		setProductConnectedInSession,
	};
};

export const useBulkConnectModalOpenState = () => {
	const [isBulkConnectModalOpen] = useIsBulkConnectModalOpen();
	const { setIsBulkConnectModalOpen } = useBulkConnectActions();
	return {
		isBulkConnectModalOpen,
		setIsBulkConnectModalOpen,
	};
};

import type { SearchExperimentLayer } from '@atlassian/search-experiment';

import type { EntityATI, SearchJiraProductType } from '../../../common/types';

import { getAtlasPartials } from './atlas';
import { getCompassPartials } from './compass';
import { getConfluencePartials } from './confluence';
import { getJiraPartials } from './jira';
import { getMercuryPartials } from './mercury';
import { type ResultsSort } from './results-sort';
import { getFederatedPartials } from './third-party-federated-entity';
import { getGraphPartials } from './third-party-graph-entity';
import { getTrelloPartials } from './trello';

export interface CommonFilters {
	range?: {
		lastModified?: {
			gt?: string;
			lt?: string;
		};
	};
	contributorsFilter?: string[];
}

export type SearchConfluenceRangeField = 'LASTMODIFIED' | 'CREATED';

export interface ConfluenceRangeFilter {
	field: SearchConfluenceRangeField;
	gt?: string;
	lt?: string;
}

export type SearchContainerStatus = 'ARCHIVED' | 'CURRENT';

export type SearchConfluenceDocumentStatus = 'CURRENT' | 'ARCHIVED' | 'DRAFT';

export interface ConfluenceFilters {
	spacesFilter: string[];
	contributorsFilter: string[];
	ancestorIdsFilter: string[];
	range: ConfluenceRangeFilter[];
	isVerified?: boolean;
	owners: string[];
	labelsFilter: string[];
	titleMatchOnly?: boolean;
	pageStatus?: string;
	contentStatuses: SearchConfluenceDocumentStatus[];
	containerStatus: SearchContainerStatus[];
}

export interface JiraFilters {
	issueFilter: {
		projectARIs: string[];
		assigneeARIs: string[];
		issueTypeIDs: string[];
	};
	projectFilter?: {
		projectTypes?: SearchJiraProductType[];
	};
}

export interface MercuryFilters {
	owners: string[];
	focusAreaTypeIds: string[];
}

export type SearchThirdPartyRangeField = 'LASTMODIFIED' | 'CREATED';

export interface ThirdPartyRangeFilter {
	field: SearchThirdPartyRangeField;
	gt?: string;
	lt?: string;
}

export interface ThirdPartyFilters {
	range?: ThirdPartyRangeFilter[];
	titleMatchOnly: boolean;
	subtypes: string[];
	integrations?: string[];
	thirdPartyProducts?: ThirdPartyProductFilter[] | undefined;
	assignees?: string[];
	containerAris?: string[];
}

export interface ThirdPartyProductFilter {
	integrationId: string;
	providerId?: string;
	subtypes: string[];
	product: string;
	connectorSources?: string[];
}

export type SearchPageQueryVariables = {
	query: string;
	cloudIdARI: string;
	tenantId: string;
	limit: number;
	entities: EntityATI[]; // ATI of the types needed
	commonFilters: CommonFilters;
	confluenceFilters: ConfluenceFilters;
	jiraFilters: JiraFilters;
	mercuryFilters: MercuryFilters;
	thirdPartyFilters: ThirdPartyFilters;
	queryVersion: number;
	searchSessionId: string;
	sourceProduct?: string;
	sort: ResultsSort[];
	experimentId?: string;
	shadowExperimentId?: string;
	experimentLayers?: SearchExperimentLayer[];
	after?: string;
	disableQueryReplacement?: boolean;
	experience?: string;
	enableHighlighting?: boolean;
	skip3pEntityHydration?: boolean;
};
export type ResultsCountQueryVariables = {
	query: string;
	cloudIdARI: string;
	limit: number;
	entities: EntityATI[]; // ATI of the types needed
	commonFilters: CommonFilters;
	confluenceFilters: ConfluenceFilters;
	jiraFilters: JiraFilters;
	mercuryFilters: MercuryFilters;
	thirdPartyFilters: ThirdPartyFilters;
	queryVersion: number;
	searchSessionId: string;
	sourceProduct?: string;
	sort: any[];
	after?: string;
	disableQueryReplacement?: boolean;
};

export type JiraDcProjectFilterOptionsQueryVariables = {
	query: string;
	cloudIdARI: string;
	limit: number;
	entities: EntityATI[];
	product: string;
};

/**
 * These attributes are the base search result attributes and are requested for every entity.
 */
export const SEARCH_RESULTS_ATTRIBUTES = `
description
iconUrl
id
title
type
url
__typename
`;

export const getSupportedPartials = () => {
	return `
		${getConfluencePartials()}
		${getJiraPartials()}
		${getTrelloPartials()}
		${getAtlasPartials()}
		${getCompassPartials()}
		${getMercuryPartials()}
		${getGraphPartials()}
		${getFederatedPartials()}
	`;
};

export const DEFERRED_SEARCH_PAGE_OPERATION_NAME = 'DeferredSearchPageQuery';
export const SEARCH_PAGE_OPERATION_NAME = 'SearchPageQuery';
export const SEARCH_DIALOG_OPERATION_NAME = 'SearchDialogQuery';

export const queryGenerator = (operationName: string) => {
	const partials = getSupportedPartials();

	const graphQuery = `
	query ${operationName}(
		$query: String!
		$cloudIdARI: String!
		$tenantId: ID!
		$limit: Int!
		$entities: [String!]!
		$queryVersion: Int
		$searchSessionId: String
		$commonFilters: SearchCommonFilter
		$confluenceFilters: SearchConfluenceFilter
		$jiraFilters: SearchJiraFilter
		$mercuryFilters: SearchMercuryFilter
		$thirdPartyFilters:  SearchThirdPartyFilter
		$sort: [SearchSortInput]
		$sourceProduct: String
		$experimentId: String
		$shadowExperimentId: String
		$experimentLayers: [SearchExperimentLayer]
		$after: String
		$disableQueryReplacement: Boolean
		$experience: String!
		$enableHighlighting: Boolean
		$skip3pEntityHydration: Boolean!
	) {
		search {
			results: search(
				experience: $experience
				enableHighlighting: $enableHighlighting
				query: $query
				first: $limit
				after: $after
				sort: $sort
				disableQueryReplacement: $disableQueryReplacement
				filters: {
					entities: $entities
					locations: [$cloudIdARI]
					commonFilters: $commonFilters
					confluenceFilters: $confluenceFilters
					jiraFilters: $jiraFilters
					mercuryFilters: $mercuryFilters
					thirdPartyFilters: $thirdPartyFilters
				}
				analytics: {
					queryVersion: $queryVersion
					searchSessionId: $searchSessionId
					sourceProduct: $sourceProduct
				}
				experimentContext: {
					experimentId: $experimentId
					shadowExperimentId: $shadowExperimentId
					experimentLayers: $experimentLayers
				}
			) {
				edges {
					node {
						${SEARCH_RESULTS_ATTRIBUTES}
						${partials}
					}
				}
				queryInfo {
					originalQuery
					replacementQuery
					suggestedQuery
					originalQueryType
					confidenceScore
					shouldTriggerAutocorrectionExperience
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
				totalCounts {
					product
					count
				}
        pageInfo {
          endCursor
        }
			}
		}
	}
	`;
	return graphQuery;
};

export const deferredQueryGenerator = (operationName: string) => {
	const partials = getSupportedPartials();

	const graphQuery = `
	query ${operationName}(
		$query: String!
		$cloudIdARI: String!
		$tenantId: ID!
		$limit: Int!
		$entities: [String!]!
		$queryVersion: Int
		$searchSessionId: String
		$commonFilters: SearchCommonFilter
		$confluenceFilters: SearchConfluenceFilter
		$jiraFilters: SearchJiraFilter
		$mercuryFilters: SearchMercuryFilter
		$thirdPartyFilters:  SearchThirdPartyFilter
		$sort: [SearchSortInput]
		$sourceProduct: String
		$experimentId: String
		$shadowExperimentId: String
		$experimentLayers: [SearchExperimentLayer]
		$after: String
		$disableQueryReplacement: Boolean
		$experience: String!
		$enableHighlighting: Boolean
	) {
		search {
			results: search(
				experience: $experience
				enableHighlighting: $enableHighlighting
				query: $query
				first: $limit
				after: $after
				sort: $sort
				disableQueryReplacement: $disableQueryReplacement
				filters: {
					entities: $entities
					locations: [$cloudIdARI]
					commonFilters: $commonFilters
					confluenceFilters: $confluenceFilters
					jiraFilters: $jiraFilters
					mercuryFilters: $mercuryFilters
					thirdPartyFilters: $thirdPartyFilters
				}
		analytics: {
					queryVersion: $queryVersion
					searchSessionId: $searchSessionId
					sourceProduct: $sourceProduct
				}
		experimentContext: {
			experimentId: $experimentId
			shadowExperimentId: $shadowExperimentId
			experimentLayers: $experimentLayers
		}
			) {
				edges {
					node {
						${SEARCH_RESULTS_ATTRIBUTES}
						${partials}
					}
				}
				... @defer {
					deferredEdges {
						node {
							${SEARCH_RESULTS_ATTRIBUTES}
							${partials}
						}
					}
				}
				queryInfo {
					originalQuery
					replacementQuery
					suggestedQuery
					originalQueryType
					confidenceScore
					shouldTriggerAutocorrectionExperience
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
				totalCounts {
					product
					count
				}
				pageInfo {
					endCursor
				}
			}
		}
	}
	`;
	return graphQuery;
};

export const resultsCountOperationName = 'ResultsCountQuery';

export const resultsCountQueryGenerator = () => {
	const graphQuery = `
	query ${resultsCountOperationName}(
		$query: String!
		$cloudIdARI: String!
		$limit: Int!
		$entities: [String!]!
		$queryVersion: Int
		$searchSessionId: String
		$commonFilters: SearchCommonFilter
		$confluenceFilters: SearchConfluenceFilter
		$jiraFilters: SearchJiraFilter
		$mercuryFilters: SearchMercuryFilter
		$thirdPartyFilters:  SearchThirdPartyFilter
		$sort: [SearchSortInput]
		$sourceProduct: String
		$experimentId: String
		$shadowExperimentId: String
		$experimentLayers: [SearchExperimentLayer]
		$disableQueryReplacement: Boolean
	) {
		search {
			results: search(
				experience: "search-page"
				query: $query
				first: $limit
				sort: $sort
				disableQueryReplacement: $disableQueryReplacement
				filters: {
					entities: $entities
					locations: [$cloudIdARI]
					commonFilters: $commonFilters
					confluenceFilters: $confluenceFilters
					jiraFilters: $jiraFilters
					mercuryFilters: $mercuryFilters
					thirdPartyFilters: $thirdPartyFilters
				}
		analytics: {
					queryVersion: $queryVersion
					searchSessionId: $searchSessionId
					sourceProduct: $sourceProduct
				}
		experimentContext: {
			experimentId: $experimentId
			shadowExperimentId: $shadowExperimentId
			experimentLayers: $experimentLayers
		}
			) {

				queryInfo {
					originalQuery
					replacementQuery
					originalQueryType
					confidenceScore
					shouldTriggerAutocorrectionExperience
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
				totalCounts {
					product
					count
				}
			}
		}
	}
	`;
	return graphQuery;
};

export const jiraDcProjectFilterOptionsOperationName = 'jiraDcProjectFilterOptionsQuery';

export const jiraDcProjectFilterOptionsQueryGenerator = () => {
	const graphQuery = `
		query ${jiraDcProjectFilterOptionsOperationName}(
		$query: String!
		$cloudIdARI: String!
		$limit: Int!
		$entities: [String!]!
		$product: String!
		) {
		search {
			search(
			query: $query
			first: $limit
			filters: {
				entities: $entities,
				thirdPartyFilters: {
					thirdPartyProducts:[
						{
							product: $product,
							integrationId:"ari:cloud:platform::integration/jira-dc",
					subtypes:[]
					}
					]
				},
				locations: [$cloudIdARI]
			}
			experience: "search-page"
			) {
			edges {
				node {
				...on SearchResultGraphDocument {
					entity {
					...on ExternalSpace {
							id
							key
							displayName
							icon {
								height
								isDefault
								url
								width
							}
					}
					}
				}
				}
			}
			}
		}
	}
	`;
	return graphQuery;
};

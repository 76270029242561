import React, { useMemo } from 'react';

import {
	AtlassianIcon,
	BitbucketIcon as oldBitbucketIcon,
	CompassIcon as oldCompassIcon,
	ConfluenceIcon as oldConfluenceIcon,
	FocusIcon as oldFocusIcon,
	JiraIcon as oldJiraIcon,
	JiraProductDiscoveryIcon as oldJiraProductDiscoveryIcon,
	JiraServiceManagementIcon as oldJiraServiceManagementIcon,
	TrelloIcon as oldTrelloIcon,
} from '@atlaskit/logo';
import { BitbucketIcon } from '@atlaskit/temp-nav-app-icons/bitbucket';
//Imported these individually because they're imported as default exports
import { CompassIcon } from '@atlaskit/temp-nav-app-icons/compass';
import { ConfluenceIcon } from '@atlaskit/temp-nav-app-icons/confluence';
import { FocusIcon } from '@atlaskit/temp-nav-app-icons/focus';
import { JiraIcon } from '@atlaskit/temp-nav-app-icons/jira';
import { JiraProductDiscoveryIcon } from '@atlaskit/temp-nav-app-icons/jira-product-discovery';
import { JiraServiceManagementIcon } from '@atlaskit/temp-nav-app-icons/jira-service-management';
import { TrelloIcon } from '@atlaskit/temp-nav-app-icons/trello';

// This is the only place we want to import this
import {
	DO_NOT_IMPORT_ProductKeys3P,
	DO_NOT_IMPORT_ProductsOrder3P,
	ThirdPartyConfigs,
} from './3p-product-configs';
import {
	get3PLogo,
	getIconVersion,
	getLogoVersion,
	type RovoLogo,
	RovoLogoFromLogo,
} from './icons';
import { AtlasSearchResultIcon } from './icons/atlas-search';
import { BitbucketSearchResultIcon } from './icons/bitbucket';
import { CompassSearchResultIcon } from './icons/compass';
import { ConfluenceSearchResultIcon } from './icons/confluence-search';
import { FocusSearchResultIcon } from './icons/focus';
import { JiraProductDiscoverySearchResultIcon } from './icons/jira-product-discovery';
import { JiraSearchResultIcon } from './icons/jira-search';
import { JiraServiceManagementSearchResultIcon } from './icons/jira-service-management';
import { TrelloSearchResultIcon } from './icons/trello';

export interface ProductConfig {
	displayName: React.ReactNode;
	Icon: RovoLogo;
	is3P?: boolean;
	SearchResultIcon?: RovoLogo;
}

const ProductKeys1P = {
	Atlas: 'townsquare',
	Bitbucket: 'bitbucket',
	Compass: 'compass',
	Confluence: 'confluence',
	Jira: 'jira',
	JiraProductDiscovery: 'jira-product-discovery',
	JiraServiceManagement: 'jira-servicedesk',
	Trello: 'trello',
	Focus: 'mercury',
	// Can't add these until they're ready as we use this type to enforce that product configs are available for all products
	// Opsgenie: 'opsgenie',
} as const;

export const ProductKeys3P = DO_NOT_IMPORT_ProductKeys3P;

export const ProductKeys = {
	...ProductKeys1P,
	...DO_NOT_IMPORT_ProductKeys3P,
} as const;

export type ProductKeys3P =
	(typeof DO_NOT_IMPORT_ProductKeys3P)[keyof typeof DO_NOT_IMPORT_ProductKeys3P];
export type ProductKeys1P = (typeof ProductKeys1P)[keyof typeof ProductKeys1P];
export type ProductKeys = (typeof ProductKeys)[keyof typeof ProductKeys];
export const allProductKeys: ProductKeys[] = Object.values(ProductKeys);
export const all3pProductKeys: ProductKeys3P[] = Object.values(DO_NOT_IMPORT_ProductKeys3P);
export const all1pProductKeys: ProductKeys1P[] = Object.values(ProductKeys1P);
export const is1pProductKey = (key: string): key is ProductKeys1P =>
	all1pProductKeys.includes(key as ProductKeys1P);
export const is3pProductKey = (key: string): key is ProductKeys3P =>
	all3pProductKeys.includes(key as ProductKeys3P);

export const PRODUCTS_ORDER: ProductKeys[] = [
	ProductKeys.Confluence,
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
	ProductKeys.Atlas,
	ProductKeys.Trello,
	ProductKeys.Bitbucket,
	ProductKeys.Compass,
	ProductKeys.Focus,
	// ProductKeys.Opsgenie,

	...DO_NOT_IMPORT_ProductsOrder3P,
];

// This is different to ProductKeys, it represents products we have support for mounting Search Page in
export const PrimaryProductKeys = {
	// Fully supported as GA
	Atlas: 'townsquare',
	Confluence: 'confluence',
	// In development
	Assets: 'assets',
	Focus: 'mercury',
	Jira: 'jira',
	Elevate: 'elevate',
} as const;
export type PrimaryProductKey = (typeof PrimaryProductKeys)[keyof typeof PrimaryProductKeys];

/**
 * This is the source of truth for the products that are supported in Full Page Search.
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * For 3P products please see ./3p-product-configs
 */
const products1P: Record<ProductKeys1P, ProductConfig> = {
	[ProductKeys.Confluence]: {
		displayName: 'Confluence',
		Icon: RovoLogoFromLogo(getLogoVersion(ConfluenceIcon, oldConfluenceIcon), 'Confluence'),
		SearchResultIcon: get3PLogo(ConfluenceSearchResultIcon, 'Confluence', 'large'),
	},
	[ProductKeys.Atlas]: {
		displayName: 'Home',
		Icon: RovoLogoFromLogo(AtlassianIcon, 'Home'),
		SearchResultIcon: get3PLogo(AtlasSearchResultIcon, 'Home', 'large'),
	},
	[ProductKeys.Jira]: {
		displayName: 'Jira',
		Icon: RovoLogoFromLogo(getLogoVersion(JiraIcon, oldJiraIcon), 'Jira'),
		SearchResultIcon: get3PLogo(JiraSearchResultIcon, 'Jira', 'large'),
	},
	[ProductKeys.JiraProductDiscovery]: {
		displayName: 'Jira Product Discovery',
		Icon: RovoLogoFromLogo(
			getLogoVersion(JiraProductDiscoveryIcon, oldJiraProductDiscoveryIcon),
			'Jira Product Discovery',
		),
		SearchResultIcon: get3PLogo(
			JiraProductDiscoverySearchResultIcon,
			'Jira Product Discovery',
			'large',
		),
	},
	[ProductKeys.JiraServiceManagement]: {
		displayName: 'Jira Service Management',
		Icon: RovoLogoFromLogo(
			getLogoVersion(JiraServiceManagementIcon, oldJiraServiceManagementIcon),
			'Jira Service Management',
		),
		SearchResultIcon: get3PLogo(
			JiraServiceManagementSearchResultIcon,
			'Jira Service Management',
			'large',
		),
	},
	[ProductKeys.Bitbucket]: {
		displayName: 'Bitbucket',
		Icon: RovoLogoFromLogo(getLogoVersion(BitbucketIcon, oldBitbucketIcon), 'Bitbucket'),
		SearchResultIcon: getIconVersion(
			BitbucketIcon,
			BitbucketSearchResultIcon,
			'Bitbucket',
			'large',
		),
	},
	[ProductKeys.Trello]: {
		displayName: 'Trello',
		Icon: RovoLogoFromLogo(getLogoVersion(TrelloIcon, oldTrelloIcon), 'Trello'),
		SearchResultIcon: get3PLogo(TrelloSearchResultIcon, 'Trello', 'large'),
	},
	[ProductKeys.Compass]: {
		displayName: 'Compass',
		Icon: RovoLogoFromLogo(getLogoVersion(CompassIcon, oldCompassIcon), 'Compass'),
		SearchResultIcon: get3PLogo(CompassSearchResultIcon, 'Compass', 'large'),
	},
	[ProductKeys.Focus]: {
		displayName: 'Focus',
		Icon: RovoLogoFromLogo(getLogoVersion(FocusIcon, oldFocusIcon), 'Focus'),
		SearchResultIcon: get3PLogo(FocusSearchResultIcon, 'Focus', 'large'),
	},
};

const getProducts3PFromConfig = (): Record<ProductKeys3P, ProductConfig> => {
	// The use of Partial, the if (config) and the cast here are intentional TS gymnastics
	// We KNOW that this returns a config for all the ProductKeys3P, so we cast that
	// The TS compiler can't know that, and we'd be forced to write UI code like:
	//
	// const Icon = configs[productKey].Icon
	// return Icon && <Icon />
	//
	// Because TS can't prove productKey can be guaranteed to index into configs. But we know it can!
	const thirdPartyProducts = ThirdPartyConfigs;
	const configs: Partial<Record<ProductKeys3P, ProductConfig>> = {};

	all3pProductKeys.map((productKey3P) => {
		const config = thirdPartyProducts.get(productKey3P);
		if (config) {
			configs[productKey3P] = {
				displayName: config.displayName,
				Icon: config.icon,
				is3P: true,
				...(config.searchResultIcon && { SearchResultIcon: config.searchResultIcon }),
			};
		}
	});

	return configs as Record<ProductKeys3P, ProductConfig>;
};

/**
 * This is the source of truth for the products that are supported in Full Page Search for use by the UI
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * If you just need the set of productKeys, please import { allProductKeys } or other similar helpers
 *
 * To get the list of products available to a user (a state concern) please see the exports in store/search
 */
export const useProductConfigs = (): Record<ProductKeys, ProductConfig> => {
	return useMemo(() => {
		const thirdPartyProducts = getProducts3PFromConfig();

		return {
			...products1P,
			...thirdPartyProducts,
		};
	}, []);
};

export const useProductConfig = (productKey: ProductKeys): ProductConfig => {
	return useProductConfigs()[productKey];
};

import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { type ProductKeys, ProductKeys3P, useProductConfigs } from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { useBulkConnectBanner } from '../../utils/bulk-connect-banner';
import { BannerType } from '../../utils/bulk-connect-banner/types';
import { OAuthMessage } from '../oauth-message';

import { getBannerContent } from './utils';

const bulkConnectBannerContainerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

type BulkConnectBannerProps = {
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	useNewlyConnectedProducts: () => ProductKeys3P[];
	isOnSearchDialog?: boolean;
	useFeatureFlaggedLocalStorageCallback: () => () => any[]; // this hook does not have strict typing
	useIsBulkConnectModalOpenQuickFind?: () => [
		boolean,
		{ setIsBulkConnectModalOpen: (value: boolean) => void },
	]; // this pertains to bulk connect modal instance on quickfind. It was necessary to prevent QF (and hence, the modal) from closing when clicking while the modal is open
	useBulkConnectModalOpenState?: () => {
		isBulkConnectModalOpen: boolean;
		setIsBulkConnectModalOpen: (status: boolean) => void;
	}; // this pertains to bulk connect modal instance on full page search. It was necessary to prevent the modal from closing upon successful OAuth connection
};

/**
 * BulkConnectBanner component
 * This component displays a banner prompting users to connect their 3p products, and is the entrypoint to the modal dialog for connecting the products.
 */
export const BulkConnectBanner = ({
	thirdPartyConfigs,
	availableProducts,
	useNewlyConnectedProducts,
	isOnSearchDialog,
	useFeatureFlaggedLocalStorageCallback,
	useIsBulkConnectModalOpenQuickFind,
	useBulkConnectModalOpenState,
}: BulkConnectBannerProps) => {
	const allProducts = useProductConfigs();
	const newlyConnectedProducts = useNewlyConnectedProducts();

	// Get Full Page Search modal state if available
	const fullPageSearchModalState = useBulkConnectModalOpenState?.() ?? {
		isBulkConnectModalOpen: false,
		setIsBulkConnectModalOpen: () => {},
	};

	// For QuickFind, use store state store/quick-find. For Full Page Search, use store/3p-bulk-connect
	const modalState = useIsBulkConnectModalOpenQuickFind?.() ?? [
		fullPageSearchModalState.isBulkConnectModalOpen,
		{ setIsBulkConnectModalOpen: fullPageSearchModalState.setIsBulkConnectModalOpen },
	];

	const [_, { setIsBulkConnectModalOpen }] = modalState;

	const { bannerType, dismissBanner } = useBulkConnectBanner({
		thirdPartyConfigs,
		availableProducts,
		newlyConnectedProducts,
		useFeatureFlaggedLocalStorageCallback,
	});

	if (bannerType === BannerType.NONE) {
		return null;
	}

	const handleAuthorize = () => {
		setIsBulkConnectModalOpen(true);
	};

	const messageContent = getBannerContent(newlyConnectedProducts, allProducts, bannerType);
	if (!messageContent) {
		return null;
	}

	const messageProps = {
		content: messageContent,
		isBulkConnect: true,
		onAuthorize: handleAuthorize,
		onDismiss: dismissBanner,
	};

	return isOnSearchDialog ? (
		<Box xcss={bulkConnectBannerContainerStyles}>
			<OAuthMessage {...messageProps} isOnSearchDialog />
		</Box>
	) : (
		<>
			<OAuthMessage {...messageProps} />
		</>
	);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AzureDevopsSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M26 9.74571V21.9057L21 26L13.25 23.1786V25.9757L8.86286 20.2429L21.65 21.2429V10.2929L26 9.74571ZM21.7371 10.3571L14.5629 6V8.85857L7.97429 10.7943L6 13.3286V19.0843L8.82571 20.3314V12.9557L21.7371 10.3571Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M35 10.6186V28.8586L27.5 35L15.875 30.7679V34.9636L9.29429 26.3643L28.475 27.8643V11.4393L35 10.6186ZM28.6057 11.5357L17.8443 5V9.28786L7.96143 12.1914L5 15.9929V24.6264L9.23857 26.4971V15.4336L28.6057 11.5357Z"
				fill="url(#paint0_linear_azure_devops)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_azure_devops"
					x1="20"
					y1="34.9057"
					x2="20"
					y2="5.05571"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0078D4" />
					<stop offset="0.16" stopColor="#1380DA" />
					<stop offset="0.53" stopColor="#3C91E5" />
					<stop offset="0.82" stopColor="#559CEC" />
					<stop offset="1" stopColor="#5EA0EF" />
				</linearGradient>
			</defs>
		</svg>
	);
};

import type { CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createSingleSelectFilter } from '../../../../filters/single-select-filter';

import { getAllPageStatusOptions } from './utils';

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('pageStatus');

const loadQueryParams = (queryParams: string[], config: CloudConfig) => {
	const allOptions = getAllPageStatusOptions(config);
	return allOptions
		.filter((option) => !option.isDefault)
		.filter((option) => queryParams.includes(option.queryParamValue));
};

const getInitialOptions = (config: CloudConfig) => {
	const allOptions = getAllPageStatusOptions(config);
	return allOptions;
};

export const filter = createSingleSelectFilter({
	id: 'pageStatus',
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'pageStatus',
		fetcher: async (queryParams, config) => {
			return loadQueryParams(queryParams, config);
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return getInitialOptions(config);
		},
	},
});

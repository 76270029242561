import { fg } from '@atlaskit/platform-feature-flags';
import { Ati } from '@atlassian/ari';
import type { EntityATI } from '@atlassian/search-client';
import {
	type ExperimentExperience,
	mapDynamicExperiments,
	type ProductDataRegions,
	type ProductEdition,
} from '@atlassian/search-experiment';

import { ThirdPartyConfigs } from '../constants/3p-product-configs';
import { ApplicationModes } from '../constants/application-modes';
import type { BootstrapState } from '../constants/boostrap';
import type { ProductKeys } from '../constants/products';
import type { ExperimentConfig } from '../constants/types';

import { isHello } from './is-hello';

/**
 * Utility to get the accessible products based on the lifecycle of fetching
 * the configuration of what a user is authorised to use.
 *
 * NOTE: The `products` parameter is deprecated as we are removing it as a
 * prop from the root <SearchPage/>
 *
 * @param supportedProducts Record of products supported by FPS
 * @param products Overrides the list of products a user can access
 * @param accessibleProducts The list of products a user is authorized to use. Initially
 * undefined while the user configuration is being retrieved.
 */
export const getAccessibleProducts = ({
	supportedProducts,
	products,
	accessibleProducts,
}: {
	supportedProducts: ProductKeys[];
	products?: ProductKeys[];
	accessibleProducts?: ProductKeys[];
}): ProductKeys[] => {
	if (!accessibleProducts) {
		return [];
	}

	const featureGatedNotEnabledProducts1P = [
		[fg('enable_rovo_search_bitbucket'), 'bitbucket'] as const,
		[fg('enable_1p_search_trello'), 'trello'] as const,
	].flatMap(([isProductEnabled, product]) => (isProductEnabled ? [] : product));

	const disabledProducts3PFromConfig: ProductKeys[] = [];
	ThirdPartyConfigs.forEach((config, productKey3P) => {
		if (config && !config.isEnabled()) {
			disabledProducts3PFromConfig.push(productKey3P);
		}
	});

	const featureGatedNotEnabledProducts = [
		...featureGatedNotEnabledProducts1P,
		...disabledProducts3PFromConfig,
	];

	const accessibleAndSupportedProducts = accessibleProducts
		.filter(
			(productKey) =>
				!featureGatedNotEnabledProducts.find(
					(featureGatedProduct) => featureGatedProduct === productKey,
				),
		)
		.filter((productKey: ProductKeys) => supportedProducts.includes(productKey));

	if (products) {
		const allowedProducts = products.filter((product) =>
			accessibleAndSupportedProducts.includes(product),
		);

		return allowedProducts;
	} else {
		return accessibleAndSupportedProducts;
	}
};

export const getBackendExperiment = ({
	experimentConfig,
	entities,
	productEdition,
	isHello,
	productDataRegions,
	experience,
}: {
	experimentConfig: ExperimentConfig;
	entities: EntityATI[];
	productEdition?: ProductEdition;
	isHello: boolean;
	productDataRegions?: ProductDataRegions;
	experience?: ExperimentExperience;
}) => {
	if (
		!fg('platform_search-scaleable-statsig-layers') ||
		fg('search-experiment-overrides-backend')
	) {
		return experimentConfig.backendExperiment;
	}

	if (!experimentConfig.dynamicExperiments || experimentConfig.isMutuallyExclusive) {
		return undefined;
	}

	const requestedEntities = entities.flatMap((entity) => {
		try {
			const { resourceOwner, resourceType } = Ati.parse(entity);
			return [{ entity: resourceOwner, subEntity: resourceType.toUpperCase() }];
		} catch {
			return [];
		}
	});

	return mapDynamicExperiments({
		dynamicExperiments: experimentConfig.dynamicExperiments,
		productEdition: productEdition,
		isHello,
		requestedEntities,
		productDataRegions: productDataRegions,
		experience,
	});
};

export const findBackendExperiment = (
	state: Pick<
		BootstrapState,
		'experimentConfig' | 'productEdition' | 'cloudId' | 'productDataRegions'
	>,
	entities: EntityATI[],
) =>
	getBackendExperiment({
		experimentConfig: state.experimentConfig,
		entities,
		productEdition: state.productEdition,
		isHello: isHello(state.cloudId),
		productDataRegions: state.productDataRegions,
	});

export const getApplicationMode = ({ isRovoEnabled }: { isRovoEnabled: boolean }) => {
	if (isRovoEnabled) {
		return ApplicationModes.Rovo;
	}
	return ApplicationModes.Unified;
};

export const getFrontendExperiments = (state: Pick<BootstrapState, 'experimentConfig'>) =>
	state.experimentConfig.frontendExperiments;

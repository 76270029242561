import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const FigmaIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-555.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#292A2E"
			/>
			<g>
				<path
					d="M12 12C12 11.337 12.254 10.7011 12.7061 10.2322C13.1582 9.76339 13.7714 9.5 14.4107 9.5C15.0501 9.5 15.6633 9.76339 16.1153 10.2322C16.5674 10.7011 16.8214 11.337 16.8214 12C16.8214 12.663 16.5674 13.2989 16.1153 13.7678C15.6633 14.2366 15.0501 14.5 14.4107 14.5C13.7714 14.5 13.1582 14.2366 12.7061 13.7678C12.254 13.2989 12 12.663 12 12Z"
					fill="#1ABCFE"
				/>
				<path
					d="M7.17871 17C7.17871 16.337 7.4327 15.7011 7.88479 15.2322C8.33689 14.7634 8.95006 14.5 9.58943 14.5H12.0001V17C12.0001 17.663 11.7462 18.2989 11.2941 18.7678C10.842 19.2366 10.2288 19.5 9.58943 19.5C8.95006 19.5 8.33689 19.2366 7.88479 18.7678C7.4327 18.2989 7.17871 17.663 7.17871 17V17Z"
					fill="#0ACF83"
				/>
				<path
					d="M12 4.5V9.5H14.4107C15.0501 9.5 15.6633 9.23661 16.1153 8.76777C16.5674 8.29893 16.8214 7.66304 16.8214 7C16.8214 6.33696 16.5674 5.70107 16.1153 5.23223C15.6633 4.76339 15.0501 4.5 14.4107 4.5H12Z"
					fill="#FF7262"
				/>
				<path
					d="M7.17871 7C7.17871 7.66304 7.4327 8.29893 7.88479 8.76777C8.33689 9.23661 8.95006 9.5 9.58943 9.5H12.0001V4.5H9.58943C8.95006 4.5 8.33689 4.76339 7.88479 5.23223C7.4327 5.70107 7.17871 6.33696 7.17871 7V7Z"
					fill="#F24E1E"
				/>
				<path
					d="M7.17871 12C7.17871 12.663 7.4327 13.2989 7.88479 13.7678C8.33689 14.2366 8.95006 14.5 9.58943 14.5H12.0001V9.5H9.58943C8.95006 9.5 8.33689 9.76339 7.88479 10.2322C7.4327 10.7011 7.17871 11.337 7.17871 12V12Z"
					fill="#A259FF"
				/>
			</g>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_7_728)">
				<path
					d="M9.22776 20C10.6998 20 11.8944 18.8053 11.8944 17.3333V14.6666H9.22776C7.75576 14.6666 6.5611 15.8613 6.5611 17.3333C6.5611 18.8053 7.75576 20 9.22776 20Z"
					fill="#0ACF83"
				/>
				<path
					d="M6.5611 12C6.5611 10.528 7.75576 9.33337 9.22776 9.33337H11.8944V14.6667H9.22776C7.75576 14.6667 6.5611 13.472 6.5611 12Z"
					fill="#A259FF"
				/>
				<path
					d="M6.5611 6.66667C6.5611 5.19467 7.75576 4 9.22776 4H11.8944V9.33333H9.22776C7.75576 9.33333 6.5611 8.13867 6.5611 6.66667Z"
					fill="#F24E1E"
				/>
				<path
					d="M11.8943 4H14.561C16.033 4 17.2277 5.19467 17.2277 6.66667C17.2277 8.13867 16.033 9.33333 14.561 9.33333H11.8943V4Z"
					fill="#FF7262"
				/>
				<path
					d="M17.2277 12C17.2277 13.472 16.033 14.6667 14.561 14.6667C13.089 14.6667 11.8943 13.472 11.8943 12C11.8943 10.528 13.089 9.33337 14.561 9.33337C16.033 9.33337 17.2277 10.528 17.2277 12Z"
					fill="#1ABCFE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7_728">
					<rect width="16" height="16" fill="white" transform="translate(4 4)" />
				</clipPath>
			</defs>
		</svg>
	);
};

import { fg } from '@atlaskit/platform-feature-flags';

import {
	ProductKeys,
	type ProductKeys1P,
	ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createBooleanFilter } from '../../../../filters/boolean-filter';

const productKeys1P: ProductKeys1P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('archived');

const getProducts1P = () => {
	return fg('enable_confluence_archived_content_filter') ? [ProductKeys.Confluence] : productKeys1P;
};

export const filter = createBooleanFilter({
	id: 'archived',
	queryParams: { key: 'archived' },
	products: [...getProducts1P(), ...products3PFromConfig],
	alwaysVisible: true,
	universal: false,
});

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const FigmaSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-550.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#292A2E"
			/>
			<g>
				<path
					d="M15.9995 16C15.9995 15.1159 16.3382 14.2681 16.941 13.6429C17.5438 13.0178 18.3613 12.6666 19.2138 12.6666C20.0663 12.6666 20.8838 13.0178 21.4866 13.6429C22.0894 14.2681 22.4281 15.1159 22.4281 16C22.4281 16.884 22.0894 17.7319 21.4866 18.357C20.8838 18.9821 20.0663 19.3333 19.2138 19.3333C18.3613 19.3333 17.5438 18.9821 16.941 18.357C16.3382 17.7319 15.9995 16.884 15.9995 16Z"
					fill="#1ABCFE"
				/>
				<path
					d="M9.57104 22.6666C9.57104 21.7825 9.90969 20.9347 10.5125 20.3096C11.1153 19.6844 11.9328 19.3333 12.7853 19.3333H15.9996V22.6666C15.9996 23.5506 15.661 24.3985 15.0582 25.0236C14.4554 25.6487 13.6378 25.9999 12.7853 25.9999C11.9328 25.9999 11.1153 25.6487 10.5125 25.0236C9.90969 24.3985 9.57104 23.5506 9.57104 22.6666V22.6666Z"
					fill="#0ACF83"
				/>
				<path
					d="M15.9995 6V12.6667H19.2138C20.0663 12.6667 20.8838 12.3155 21.4866 11.6904C22.0894 11.0652 22.4281 10.2174 22.4281 9.33333C22.4281 8.44928 22.0894 7.60143 21.4866 6.97631C20.8838 6.35119 20.0663 6 19.2138 6H15.9995Z"
					fill="#FF7262"
				/>
				<path
					d="M9.57129 9.33333C9.57129 10.2174 9.90994 11.0652 10.5127 11.6904C11.1155 12.3155 11.9331 12.6667 12.7856 12.6667H15.9999V6H12.7856C11.9331 6 11.1155 6.35119 10.5127 6.97631C9.90994 7.60143 9.57129 8.44928 9.57129 9.33333V9.33333Z"
					fill="#F24E1E"
				/>
				<path
					d="M9.57104 16C9.57104 16.884 9.90969 17.7319 10.5125 18.357C11.1153 18.9821 11.9328 19.3333 12.7853 19.3333H15.9996V12.6666H12.7853C11.9328 12.6666 11.1153 13.0178 10.5125 13.6429C9.90969 14.2681 9.57104 15.1159 9.57104 16V16Z"
					fill="#A259FF"
				/>
			</g>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_18930)">
				<g clipPath="url(#clip1_2230_18930)">
					<path
						d="M14.8021 35C17.5621 35 19.8021 32.76 19.8021 30V25H14.8021C12.0421 25 9.80206 27.24 9.80206 30C9.80206 32.76 12.0421 35 14.8021 35Z"
						fill="#0ACF83"
					/>
					<path
						d="M9.80206 20C9.80206 17.24 12.0421 15 14.8021 15H19.8021V25H14.8021C12.0421 25 9.80206 22.76 9.80206 20Z"
						fill="#A259FF"
					/>
					<path
						d="M9.80206 10C9.80206 7.24 12.0421 5 14.8021 5H19.8021V15H14.8021C12.0421 15 9.80206 12.76 9.80206 10Z"
						fill="#F24E1E"
					/>
					<path
						d="M19.8019 5H24.8019C27.5619 5 29.8019 7.24 29.8019 10C29.8019 12.76 27.5619 15 24.8019 15H19.8019V5Z"
						fill="#FF7262"
					/>
					<path
						d="M29.8019 20C29.8019 22.76 27.5619 25 24.8019 25C22.0419 25 19.8019 22.76 19.8019 20C19.8019 17.24 22.0419 15 24.8019 15C27.5619 15 29.8019 17.24 29.8019 20Z"
						fill="#1ABCFE"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_2230_18930">
					<rect width="30" height="30" fill="white" transform="translate(5 5)" />
				</clipPath>
				<clipPath id="clip1_2230_18930">
					<rect width="30" height="30" fill="white" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

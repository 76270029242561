/**
 * This file was adapted from confluence/next/packages/search-utils/src/excerpt.tsx
 */

import React, { type ReactElement } from 'react';

import { Box, Text } from '@atlaskit/primitives';
import { PlatformIntegrationAri } from '@atlassian/ari';
import {
	type SearchThirdPartyDocumentPartial,
	type ThirdPartyUser,
} from '@atlassian/search-client';
import { type PersonCardProps } from '@atlassian/search-ui/person-card';

import type { ThirdPartyConfigsBootstrap } from '../../common/constants/schemas/3p-config';
import { NounKeys } from '../constants/nouns';
import { is3pProductKey, ProductKeys } from '../constants/products';

const SPECIAL_CHARS_REGEX = /^[!#$%^&*() ,.<>?\/\[\]_\-+=`~\|:;{}\\]+/;

const MAX_EXCERPT_LENGTH = 80;
export const replaceHtmlEntities = (inputStr: string) => {
	const htmlEntities: { [key: string]: string } = {
		'&nbsp;': ' ',
		'&lt;': '<',
		'&amp;': '&',
		'&quot;': '"',
		'&gt;': '>',
	};
	return Object.keys(htmlEntities).reduce((str, entity) => {
		return str.replace(new RegExp(entity, 'g'), htmlEntities[entity]);
	}, inputStr);
};

export const getWorkspaceNameFromProduct = (
	product: ProductKeys | NounKeys | undefined,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
) => {
	let workspaceName;
	if (product && is3pProductKey(product) && thirdPartyConfigs) {
		workspaceName = thirdPartyConfigs[product]?.workspaceName;
	}
	return workspaceName;
};

/**
 * Make excerpt more readable by stripping leading non-alpha characters,
 * adding an ellipsis prefix and suffix, replacing line breaks with separators,
 * and bolding search terms.
 */

// TODO: deprecate this function in favor of enrichExcerpt
export const processExcerpt = (excerpt?: string, noLeadingEllipsis?: boolean, prefix?: string) => {
	if (!excerpt) {
		return [<Text key="empty">{''}</Text>];
	}

	excerpt = replaceHtmlEntities(excerpt).trim();

	if (!excerpt) {
		return [<Text key="empty">{''}</Text>];
	}

	const beginsWithHighlight = excerpt.slice(0, 8) === '@@@hl@@@';
	const beginsWithUrl =
		excerpt.slice(0, 4) === 'http' || (beginsWithHighlight && excerpt.slice(8, 12) === 'http');

	// Strip leading special characters unless the excerpt begins with a highlight
	if (!beginsWithHighlight) {
		excerpt = excerpt.replace(SPECIAL_CHARS_REGEX, '');
	}

	// Add ellipsis prefix if beginning of excerpt isn't a URL and excerpt length is greater than 80
	if (!noLeadingEllipsis && !beginsWithUrl && excerpt.length > MAX_EXCERPT_LENGTH) {
		excerpt = '...'.concat(excerpt);
	}

	// Add ellipsis suffix if excerpt doesn't end with ending punctuation and if length is greater than 80
	if (excerpt.length > MAX_EXCERPT_LENGTH && !excerpt.slice(-1).match(/[.!?]/)) {
		excerpt = excerpt.concat('...');
	}

	// Replace line breaks with separators
	excerpt = excerpt.replace(/\n+/g, ' · ');
	return enrichExcerpt(excerpt, prefix);
};

// Utility function to insert zero-width space every N characters
const insertZeroWidthSpace = (str: string, interval: number) => {
	let result = '';
	for (let i = 0; i < str.length; i++) {
		result += str[i];
		if ((i + 1) % interval === 0) {
			result += '\u200B';
		}
	}
	return result;
};

// Adding zero-width spaces to prevent overflow on long words or sequence of characters
export const processSegment = (segment: string) => {
	return segment
		.split(' ')
		.map((word) => {
			if (word.length > 20) {
				return insertZeroWidthSpace(word, 5);
			}
			return word;
		})
		.join(' ');
};
/**
 * Replaces highlight markers with <strong> tags.
 * Typically used for highlighting search terms in excerpts.
 */
export const enrichExcerpt = (excerpt?: string, prefix?: string) => {
	if (!excerpt) {
		return [<Text key="empty">{''}</Text>];
	}

	excerpt = replaceHtmlEntities(excerpt).trim();

	if (!excerpt) {
		return [<Text key="empty">{''}</Text>];
	}

	const res: ReactElement[] = [];
	let pre, textMatch;
	let i = 0;
	while (excerpt) {
		const match: RegExpMatchArray | null = excerpt.match(/(.*?)@@@hl@@@(.*?)@@@endhl@@@(.*)/s);
		if (!match) {
			res.push(
				<Box as="span" key={excerpt}>
					{processSegment(excerpt)}
				</Box>,
			);
			break;
		}
		[, pre, textMatch, excerpt] = match;
		if (pre) {
			res.push(
				<Box as="span" key={`pre-${i}`}>
					{processSegment(pre)}
				</Box>,
			);
		}
		if (textMatch) {
			res.push(
				<Box as="strong" key={`textMatch-${i}`}>
					{processSegment(textMatch)}
				</Box>,
			);
		}
		i++;
	}
	return (
		// NOTE: wrapping these in <div> previously broke focus rings
		// in search results
		<>
			{prefix}
			{res}
		</>
	);
};

export function isNonPublic(
	permissionLevel: SearchThirdPartyDocumentPartial['permissionLevel'],
): permissionLevel is 'private' | 'restricted' {
	return permissionLevel === 'restricted' || permissionLevel === 'private';
}

export const truncateDescription = (input: string, maxLength: number): string => {
	if (input.length <= maxLength) {
		return input;
	}
	return input.substring(0, maxLength) + '...';
};

export const isAWSThumbnailUrlExpired = (thumbnailUrl: string) => {
	// Parse the URL
	const urlObj = new URL(thumbnailUrl);
	const params = urlObj.searchParams;

	// Extract the required parameters
	const amzExpires = params.get('X-Amz-Expires');
	const amzDate = params.get('X-Amz-Date');

	// if we lose the format, we should not attempt to render the thumbnail
	if (!amzExpires || !amzDate) {
		return true;
	}

	// Convert X-Amz-Date to a Date object
	const amzDateObj = new Date(
		parseInt(amzDate.substring(0, 4)), // Year
		parseInt(amzDate.substring(4, 6)) - 1, // Month (0-based index)
		parseInt(amzDate.substring(6, 8)), // Day
		parseInt(amzDate.substring(9, 11)), // Hour
		parseInt(amzDate.substring(11, 13)), // Minute
		parseInt(amzDate.substring(13, 15)), // Second
	);

	// Calculate the expiration time in milliseconds
	const expirationTime = amzDateObj.getTime() + parseInt(amzExpires) * 1000;

	// Get the current time in milliseconds
	const currentTime = Date.now();

	// Determine if the current time is greater than the expiration time
	return currentTime > expirationTime;
};
type TimeSinceDate = {
	years: number;
	days: number;
	hours: number;
	minutes: number;
	months: number;
	seconds: number;
};

type RelativeTime = {
	unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
	value: number;
};

export const getRelativeTimeSince = (timestamp: Date | undefined): RelativeTime => {
	return getTimeValuesWrapper(timeSinceDate(timestamp));
};

export const secondsSinceDate = (timestamp: Date | undefined): number => {
	if (!timestamp) {
		timestamp = new Date();
	}

	const now = new Date();
	return (now.getTime() - new Date(timestamp).getTime()) / 1000;
};
export const timeSinceDate = (timestamp: Date | undefined): TimeSinceDate => {
	if (!timestamp) {
		timestamp = new Date();
	}
	const secondsPast = secondsSinceDate(timestamp);

	const timeUnits = {
		seconds: secondsPast,
		minutes: secondsPast / 60,
		hours: secondsPast / 3600,
		days: secondsPast / 86400,
		months: secondsPast / 2592000,
		years: secondsPast / 31536000,
	};

	return {
		seconds: Math.floor(timeUnits.seconds),
		minutes: Math.floor(timeUnits.minutes),
		hours: Math.floor(timeUnits.hours),
		days: Math.floor(timeUnits.days),
		months: Math.floor(timeUnits.months),
		years: Math.floor(timeUnits.years),
	};
};

export const getTimeValuesWrapper = ({
	seconds,
	minutes,
	hours,
	days,
	months,
	years,
}: {
	seconds: number;
	minutes: number;
	hours: number;
	days: number;
	months: number;
	years: number;
}) => {
	return getTimeValues(seconds, minutes, hours, days, months, years);
};

export const getTimeValues = (
	seconds: number,
	minutes: number,
	hours: number,
	days: number,
	months: number,
	years: number,
): RelativeTime => {
	if (years > 0) {
		return { unit: 'year', value: years };
	} else if (months > 0) {
		return { unit: 'month', value: months };
	} else if (days > 0) {
		return { unit: 'day', value: days };
	} else if (hours > 0) {
		return { unit: 'hour', value: hours };
	} else if (minutes > 0) {
		return { unit: 'minute', value: minutes };
	} else {
		return { unit: 'second', value: seconds };
	}
};

export const getIntegrationKey = (integrationId?: string) => {
	return integrationId ? PlatformIntegrationAri.parse(integrationId).integrationKey : undefined;
};

/**
 * @returns information for the feature to Direct Message the user
 */
export const getExternalMessageDmIntegrationInfo = (
	integrationId?: string,
	thirdPartyUser?: ThirdPartyUser,
): PersonCardProps['externalMessageIntegration'] | undefined => {
	if (!integrationId || !thirdPartyUser) {
		return;
	}

	const isSlack = integrationId === 'ari:cloud:platform::integration/slack';
	const isMsTeams = integrationId === 'ari:cloud:platform::integration/microsoft';

	if (isSlack) {
		const openDmUrl = getOpenInSlackUrl(thirdPartyUser);
		if (openDmUrl) {
			return { integration: 'slack', openDmUrl };
		}
	} else if (isMsTeams) {
		const openDmUrl = getOpenInTeamsUrl(thirdPartyUser);
		if (openDmUrl) {
			return { integration: 'ms-teams', openDmUrl };
		}
	}
};

/**
 * @returns user data for the person card
 */
export const getUserData = (
	thirdPartyUser?: ThirdPartyUser,
): PersonCardProps['user'] | undefined => {
	if (!thirdPartyUser || !thirdPartyUser.name) {
		return;
	}

	// We are waiting for this commit ticket to complete for jobTitle & profileUrl
	// https://hello.jira.atlassian.cloud/browse/COMMIT-14774

	return {
		name: thirdPartyUser.name,
		profilePicUrl: thirdPartyUser.picture ?? undefined,
		jobTitle: thirdPartyUser.extendedProfile?.jobTitle,
		profileUrl: null, // not available as of late Oct 2024
	};
};

/**
 * @returns Open in Slack URL for the user
 */
export const getOpenInSlackUrl = (thirdPartyUser?: ThirdPartyUser) => {
	const externalId = thirdPartyUser?.externalId;
	if (!externalId) {
		return;
	}
	return `https://slack.com/app_redirect?channel=${externalId}`;
};

/**
 * @returns Open in MS Teams URL for the user
 */
export const getOpenInTeamsUrl = (thirdPartyUser?: ThirdPartyUser) => {
	const email = thirdPartyUser?.email;
	if (!email) {
		return;
	}
	return `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
};

export const getProductFromProviderId = (
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
	providerId?: string,
) => {
	if (!providerId) {
		return undefined;
	}

	const [product] =
		Object.entries(thirdPartyConfigs).find(([, config]) => config.providerId === providerId) || [];
	return product;
};

export const filterSpacer = (node: React.ReactNode): React.ReactNode => {
	if (!React.isValidElement(node)) {
		return node;
	}

	// Check if element is a Spacer component
	if (typeof node.type === 'function' && (node.type as any).IS_METADATA_SPACER) {
		return null;
	}

	// If the element has children, filter out spacers
	if (node.props.children) {
		const children = React.Children.toArray(node.props.children);
		const filteredChildren = children.filter(
			(child) =>
				!(
					React.isValidElement(child) &&
					typeof child.type === 'function' &&
					(child.type as any).IS_METADATA_SPACER
				),
		);

		return React.cloneElement(node, node.props, ...filteredChildren);
	}

	return node;
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AdobeSignSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-166.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#584CCC" />
			<path
				d="M25.7066 18.7523C25.0666 18.0399 23.8399 17.7112 22.0799 17.7112C21.1199 17.7112 20.1066 17.8208 19.1466 17.9852C18.5066 17.3824 17.9732 16.6701 17.4399 15.9578C17.0666 15.4099 16.6932 14.8619 16.3732 14.2592C16.9066 12.725 17.2266 11.0812 17.3332 9.43743C17.3332 7.95802 16.7466 6.36902 15.1466 6.36902C14.6132 6.36902 14.0799 6.69778 13.8132 7.19091C13.1199 8.39636 13.3866 10.8621 14.5066 13.4373C14.1332 14.588 13.7066 15.7386 13.2266 16.9989C12.7999 18.0947 12.3199 19.1358 11.7332 20.1221C10.0799 20.7796 6.61323 22.4234 6.29323 24.2316C6.18656 24.7795 6.34656 25.3274 6.77323 25.6562C7.14656 25.9849 7.6799 26.2041 8.21323 26.1493C10.2932 26.1493 12.3732 23.1905 13.8132 20.6152C14.6132 20.3413 15.4132 20.0673 16.2666 19.8481C17.1732 19.6289 18.0266 19.4098 18.8266 19.2454C21.0666 21.2179 23.0399 21.5467 23.9999 21.5467C25.3332 21.5467 25.8666 20.944 26.0266 20.4508C26.2399 19.8481 26.1332 19.1906 25.7066 18.7523ZM24.2666 19.7933C24.2132 20.1769 23.7866 20.4508 23.2532 20.4508C23.0932 20.4508 22.9332 20.4508 22.7732 20.396C21.7066 20.1221 20.6932 19.5742 19.8399 18.8618C20.5332 18.7523 21.2799 18.6975 21.9732 18.6975C22.4532 18.6975 22.9332 18.7523 23.4132 18.807C23.8932 18.9166 24.4266 19.1358 24.2666 19.7933ZM14.7199 7.68405C14.8266 7.51967 14.9866 7.41009 15.1999 7.35529C15.7332 7.35529 15.8399 7.95802 15.8399 8.50595C15.7866 9.76619 15.5732 10.9716 15.1999 12.1771C14.2932 9.82098 14.3999 8.23198 14.7199 7.68405ZM17.7066 18.2043C17.1199 18.3139 16.5332 18.4783 15.9466 18.6427C15.5199 18.7523 15.0932 18.9166 14.6132 19.0262C14.8266 18.5331 15.0399 18.0399 15.2532 17.6016C15.5199 16.9989 15.7332 16.3962 15.9466 15.7934C16.1599 16.1222 16.3199 16.3962 16.5332 16.6701C16.9066 17.218 17.3332 17.7112 17.7066 18.2043ZM11.2532 21.3823C9.9199 23.574 8.58656 24.9987 7.8399 24.9987C7.73323 24.9987 7.57323 24.9439 7.5199 24.8891C7.3599 24.7795 7.30656 24.5603 7.3599 24.3959C7.46656 23.574 9.01323 22.4234 11.2532 21.3823Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.3 5H29.7C32.6 5 35 7.4 35 10.3V28.9C35 31.85 32.6 34.2 29.7 34.2H10.3C7.4 34.25 5 31.85 5 28.95V10.3C5 7.4 7.4 5 10.3 5Z"
				fill="#584CCC"
			/>
			<path
				d="M29.1 21.9501C28.5 21.3001 27.35 21.0001 25.7 21.0001C24.8 21.0001 23.85 21.1001 22.95 21.2501C22.35 20.7001 21.85 20.0501 21.35 19.4001C21 18.9001 20.65 18.4001 20.35 17.8501C20.85 16.4501 21.15 14.9501 21.25 13.4501C21.25 12.1001 20.7 10.6501 19.2 10.6501C18.7 10.6501 18.2 10.9501 17.95 11.4001C17.3 12.5001 17.55 14.7501 18.6 17.1001C18.25 18.1501 17.85 19.2001 17.4 20.3501C17 21.3501 16.55 22.3001 16 23.2001C14.45 23.8001 11.2 25.3001 10.9 26.9501C10.8 27.4501 10.95 27.9501 11.35 28.2501C11.7 28.5501 12.2 28.7501 12.7 28.7001C14.65 28.7001 16.6 26.0001 17.95 23.6501C18.7 23.4001 19.45 23.1501 20.25 22.9501C21.1 22.7501 21.9 22.5501 22.65 22.4001C24.75 24.2001 26.6 24.5001 27.5 24.5001C28.75 24.5001 29.25 23.9501 29.4 23.5001C29.6 22.9501 29.5 22.3501 29.1 21.9501ZM27.75 22.9001C27.7 23.2501 27.3 23.5001 26.8 23.5001C26.65 23.5001 26.5 23.5001 26.35 23.4501C25.35 23.2001 24.4 22.7001 23.6 22.0501C24.25 21.9501 24.95 21.9001 25.6 21.9001C26.05 21.9001 26.5 21.9501 26.95 22.0001C27.4 22.1001 27.9 22.3001 27.75 22.9001ZM18.8 11.8501C18.9 11.7001 19.05 11.6001 19.25 11.5501C19.75 11.5501 19.85 12.1001 19.85 12.6001C19.8 13.7501 19.6 14.8501 19.25 15.9501C18.4 13.8001 18.5 12.3501 18.8 11.8501ZM21.6 21.4501C21.05 21.5501 20.5 21.7001 19.95 21.8501C19.55 21.9501 19.15 22.1001 18.7 22.2001C18.9 21.7501 19.1 21.3001 19.3 20.9001C19.55 20.3501 19.75 19.8001 19.95 19.2501C20.15 19.5501 20.3 19.8001 20.5 20.0501C20.85 20.5501 21.25 21.0001 21.6 21.4501ZM15.55 24.3501C14.3 26.3501 13.05 27.6501 12.35 27.6501C12.25 27.6501 12.1 27.6001 12.05 27.5501C11.9 27.4501 11.85 27.2501 11.9 27.1001C12 26.3501 13.45 25.3001 15.55 24.3501Z"
				fill="white"
			/>
		</svg>
	);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const TeamsSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-598.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<path
				d="M19.2836 13.7715H24.4497C24.9377 13.7715 25.3334 14.1744 25.3334 14.6715V19.4637C25.3334 21.2905 23.8792 22.7714 22.0855 22.7714H22.0701C20.2764 22.7717 18.822 21.291 18.8218 19.4642C18.8218 19.464 18.8218 19.4639 18.8218 19.4637V14.2419C18.8218 13.9821 19.0286 13.7715 19.2836 13.7715Z"
				fill="#5059C9"
			/>
			<path
				d="M22.7749 12.8244C23.9308 12.8244 24.8679 11.8701 24.8679 10.6928C24.8679 9.51561 23.9308 8.56128 22.7749 8.56128C21.619 8.56128 20.6819 9.51561 20.6819 10.6928C20.6819 11.8701 21.619 12.8244 22.7749 12.8244Z"
				fill="#5059C9"
			/>
			<path
				d="M16.2635 12.8244C17.9332 12.8244 19.2867 11.4459 19.2867 9.74543C19.2867 8.04499 17.9332 6.6665 16.2635 6.6665C14.5938 6.6665 13.2402 8.04499 13.2402 9.74543C13.2402 11.4459 14.5938 12.8244 16.2635 12.8244Z"
				fill="#7B83EB"
			/>
			<path
				d="M20.2947 13.7715H11.7673C11.2851 13.7836 10.9036 14.1912 10.9148 14.6824V20.1482C10.8474 23.0955 13.1371 25.5414 16.031 25.6135C18.925 25.5414 21.2146 23.0955 21.1473 20.1482V14.6824C21.1584 14.1912 20.777 13.7836 20.2947 13.7715Z"
				fill="#7B83EB"
			/>
			<path
				opacity="0.1"
				d="M16.4959 13.7715V21.4309C16.4936 21.7821 16.2846 22.0977 15.9657 22.2314C15.8641 22.2752 15.755 22.2977 15.6448 22.2977H11.3239C11.2634 22.1414 11.2076 21.9851 11.1611 21.8241C10.9982 21.2805 10.9152 20.7155 10.9145 20.1472V14.681C10.9034 14.1906 11.2842 13.7836 11.7657 13.7715H16.4959Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.0308 13.7715V21.9046C16.0308 22.0169 16.0086 22.128 15.9657 22.2314C15.8344 22.5562 15.5245 22.7691 15.1796 22.7714H11.5425C11.4634 22.6151 11.389 22.4588 11.3239 22.2977C11.2587 22.1367 11.2076 21.9851 11.1611 21.8241C10.9982 21.2805 10.9152 20.7155 10.9145 20.1472V14.681C10.9034 14.1906 11.2842 13.7836 11.7657 13.7715H16.0308Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.0308 13.7715V20.9572C16.0272 21.4345 15.6482 21.8205 15.1796 21.8241H11.1611C10.9982 21.2805 10.9152 20.7155 10.9145 20.1472V14.681C10.9034 14.1906 11.2842 13.7836 11.7657 13.7715H16.0308Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.5657 13.7715V20.9572C15.5621 21.4345 15.1831 21.8205 14.7145 21.8241H11.1611C10.9982 21.2805 10.9152 20.7155 10.9145 20.1472V14.681C10.9034 14.1906 11.2842 13.7836 11.7657 13.7715H15.5657Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M16.4963 11.3226V12.8147C16.4172 12.8195 16.3428 12.8242 16.2637 12.8242C16.1847 12.8242 16.1102 12.8195 16.0312 12.8147C15.8742 12.8041 15.7185 12.7788 15.5661 12.7389C14.6242 12.5118 13.8461 11.8391 13.4731 10.9295C13.4089 10.7767 13.359 10.6181 13.3242 10.4558H15.6451C16.1145 10.4576 16.4945 10.8446 16.4963 11.3226Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.0308 11.7965V12.8149C15.8738 12.8043 15.7181 12.779 15.5657 12.7391C14.6238 12.512 13.8457 11.8393 13.4727 10.9297H15.1796C15.649 10.9315 16.029 11.3185 16.0308 11.7965Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.0308 11.7965V12.8149C15.8738 12.8043 15.7181 12.779 15.5657 12.7391C14.6238 12.512 13.8457 11.8393 13.4727 10.9297H15.1796C15.649 10.9315 16.029 11.3185 16.0308 11.7965Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.5657 11.7965V12.7391C14.6238 12.512 13.8457 11.8393 13.4727 10.9297H14.7145C15.1839 10.9315 15.5639 11.3185 15.5657 11.7965Z"
				fill="black"
			/>
			<path
				d="M6.1858 10.9297H14.7132C15.184 10.9297 15.5657 11.3184 15.5657 11.7979V20.4824C15.5657 20.9619 15.184 21.3507 14.7132 21.3507H6.1858C5.71495 21.3507 5.33325 20.9619 5.33325 20.4824V11.7979C5.33325 11.3184 5.71496 10.9297 6.1858 10.9297Z"
				fill="#5059C9"
			/>
			<path
				d="M12.693 14.2347H10.9883V18.962H9.9023V14.2347H8.20557V13.3176H12.693V14.2347Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.9254 16.9079H33.6744C34.4065 16.9079 35 17.5123 35 18.2579V25.4462C35 28.1865 32.8188 30.4078 30.1282 30.4078H30.1051C27.4145 30.4082 25.233 28.1872 25.2326 25.4469C25.2326 25.4467 25.2326 25.4465 25.2326 25.4462V17.6135C25.2326 17.2238 25.5428 16.9079 25.9254 16.9079Z"
				fill="#5059C9"
			/>
			<path
				d="M31.1625 15.4868C32.8964 15.4868 34.3021 14.0553 34.3021 12.2894C34.3021 10.5236 32.8964 9.09207 31.1625 9.09207C29.4286 9.09207 28.023 10.5236 28.023 12.2894C28.023 14.0553 29.4286 15.4868 31.1625 15.4868Z"
				fill="#5059C9"
			/>
			<path
				d="M21.3953 15.4868C23.8999 15.4868 25.9302 13.4191 25.9302 10.8684C25.9302 8.31773 23.8999 6.25 21.3953 6.25C18.8908 6.25 16.8605 8.31773 16.8605 10.8684C16.8605 13.4191 18.8908 15.4868 21.3953 15.4868Z"
				fill="#7B83EB"
			/>
			<path
				d="M27.4421 16.9079H14.651C13.9276 16.9261 13.3554 17.5375 13.3722 18.2742V26.473C13.2712 30.894 16.7056 34.5628 21.0466 34.671C25.3875 34.5628 28.8219 30.894 28.7209 26.473V18.2742C28.7377 17.5375 28.1655 16.9261 27.4421 16.9079Z"
				fill="#7B83EB"
			/>
			<path
				opacity="0.1"
				d="M21.7441 16.9079V28.3971C21.7406 28.9239 21.4271 29.3972 20.9487 29.5978C20.7964 29.6635 20.6327 29.6973 20.4673 29.6973H13.986C13.8953 29.4628 13.8115 29.2284 13.7418 28.9868C13.4976 28.1715 13.3729 27.3239 13.372 26.4715V18.2721C13.3552 17.5366 13.9265 16.9261 14.6487 16.9079H21.7441Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M21.0464 16.9079V29.1076C21.0464 29.276 21.0132 29.4427 20.9487 29.5978C20.7518 30.085 20.287 30.4043 19.7697 30.4078H14.3139C14.1953 30.1734 14.0836 29.9389 13.986 29.6973C13.8883 29.4557 13.8115 29.2284 13.7418 28.9868C13.4976 28.1715 13.3729 27.3239 13.372 26.4715V18.2721C13.3552 17.5366 13.9265 16.9261 14.6487 16.9079H21.0464Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M21.0464 16.9079V27.6865C21.0411 28.4024 20.4726 28.9814 19.7697 28.9868H13.7418C13.4976 28.1715 13.3729 27.3239 13.372 26.4715V18.2721C13.3552 17.5366 13.9265 16.9261 14.6487 16.9079H21.0464Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M20.3487 16.9079V27.6865C20.3434 28.4024 19.7749 28.9814 19.072 28.9868H13.7418C13.4976 28.1715 13.3729 27.3239 13.372 26.4715V18.2721C13.3552 17.5366 13.9265 16.9261 14.6487 16.9079H20.3487Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M21.7443 13.2344V15.4726C21.6257 15.4797 21.5141 15.4868 21.3955 15.4868C21.2769 15.4868 21.1653 15.4797 21.0467 15.4726C20.8112 15.4567 20.5776 15.4186 20.349 15.3589C18.9362 15.0182 17.769 14.0091 17.2095 12.6447C17.1132 12.4156 17.0384 12.1777 16.9862 11.9342H20.4676C21.1716 11.9369 21.7417 12.5175 21.7443 13.2344Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M21.0464 13.945V15.4726C20.8109 15.4567 20.5773 15.4186 20.3487 15.3589C18.9359 15.0182 17.7687 14.0091 17.2092 12.6447H19.7696C20.4736 12.6474 21.0437 13.228 21.0464 13.945Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M21.0464 13.945V15.4726C20.8109 15.4567 20.5773 15.4186 20.3487 15.3589C18.9359 15.0182 17.7687 14.0091 17.2092 12.6447H19.7696C20.4736 12.6474 21.0437 13.228 21.0464 13.945Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M20.3487 13.945V15.3589C18.9359 15.0182 17.7687 14.0091 17.2092 12.6447H19.0719C19.776 12.6474 20.346 13.228 20.3487 13.945Z"
				fill="black"
			/>
			<path
				d="M6.27882 12.6447H19.0699C19.7762 12.6447 20.3487 13.2278 20.3487 13.9471V26.9738C20.3487 27.6931 19.7762 28.2762 19.0699 28.2762H6.27882C5.57254 28.2762 5 27.6931 5 26.9738V13.9471C5 13.2278 5.57256 12.6447 6.27882 12.6447Z"
				fill="url(#paint0_linear_teams)"
			/>
			<path
				d="M16.0398 17.6027H13.4829V24.6938H11.8538V17.6027H9.30872V16.2272H16.0398V17.6027Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_teams"
					x1="7.66639"
					y1="11.6271"
					x2="17.9591"
					y2="29.1312"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5A62C3" />
					<stop offset="0.5" stopColor="#4D55BD" />
					<stop offset="1" stopColor="#3940AB" />
				</linearGradient>
			</defs>
		</svg>
	);
};

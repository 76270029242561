import { useEffect, useMemo, useState } from 'react';

import { type IntlShape, type MessageDescriptor, useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { IdentityUserAri } from '@atlassian/ari';
import {
	type ConfluenceRangeFilter,
	type EntityATI,
	jiraIssueTypeJQLQuery,
	type SearchConfluenceDocumentStatus,
	type SearchContainerStatus,
	type SearchJiraProductType,
	type ThirdPartyFilters,
} from '@atlassian/search-client';

import { type AllFilterStates, CUSTOM_OPTION_VALUE } from '../../../common/constants/filters/types';
import { TypeFilterValueKey } from '../../../common/constants/filters/universal-type';
import { ProductKeys } from '../../../common/constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../../common/constants/schemas/3p-config';
import { type ExperimentConfig, type SelectedFiltersType } from '../../../common/constants/types';
import {
	baseIntegrationARIs,
	convertCustomDateToRange,
	convertLastUpdatedToDateRange,
	getConfluenceDateOptionFromCustomDates,
	getConfluenceDateOptionValues,
	getThirdPartyFilters,
} from '../../../common/utils/filters';
import { getProducts } from '../../../common/utils/search';
import {
	getIsDropAttachmentsCommentsFrmAllTypesEnabled,
	useIsDropAttachmentsCommentsFrmAllTypesEnabled,
} from '../../store/bootstrap';
import {
	getTypeEntities,
	type GetTypeTypeEntitiesProps,
	type GetTypeTypeEntitiesState,
	useFilters,
	useProducts,
	useTypeEntities,
} from '../../store/search';
import {
	archivedFilter,
	assigneeFilter,
	contentFilter,
	contributorFilter,
	focusAreaTypeFilter,
	labelFilter,
	lastUpdatedFilter,
	ownerFilter,
	pageStatusFilter,
	projectFilter,
	spaceFilter,
	titleMatchFilter,
	verifiedFilter,
} from '../../store/search/filters';
import { isSelectFilter } from '../base-select-filter/utils';

import { messages } from './messages';

const getSelectFilterValue = (filter?: AllFilterStates) => {
	return isSelectFilter(filter?.type) ? filter.value : [];
};

const getSelectFilterCustomOptionValue = (filter?: AllFilterStates) => {
	return isSelectFilter(filter?.type) ? filter.customValue : {};
};

const getBooleanFilterValue = (filter?: AllFilterStates) => {
	return filter?.type === 'boolean' ? filter.value : false;
};

const getSelectedDateRange = (filter?: AllFilterStates) => {
	const selectedDateOption = getSelectFilterValue(filter).at(0);
	if (selectedDateOption && selectedDateOption !== CUSTOM_OPTION_VALUE) {
		return convertLastUpdatedToDateRange(selectedDateOption);
	} else if (selectedDateOption === CUSTOM_OPTION_VALUE) {
		const customDateRange = getSelectFilterCustomOptionValue(filter);
		if (customDateRange?.from || customDateRange?.to) {
			return convertCustomDateToRange(customDateRange.from, customDateRange.to);
		}
	}
	return;
};

const getFilterDateRange = (filter?: AllFilterStates): ConfluenceRangeFilter[] => {
	const selectedDateOption = getSelectFilterValue(filter).at(0);

	if (selectedDateOption && selectedDateOption !== CUSTOM_OPTION_VALUE) {
		return [getConfluenceDateOptionValues(selectedDateOption)];
	} else if (selectedDateOption === CUSTOM_OPTION_VALUE) {
		const customDateRange = getSelectFilterCustomOptionValue(filter);
		if (customDateRange?.from && customDateRange?.to) {
			return [getConfluenceDateOptionFromCustomDates(customDateRange.from, customDateRange.to)];
		}
	}
	return [];
};

type SearchStatusKeys = 'showCurrent' | 'showArchived';

export const ContainerStatusKeys: {
	[K in SearchStatusKeys]: SearchContainerStatus[];
} = {
	['showCurrent']: [],
	['showArchived']: ['ARCHIVED', 'CURRENT'],
};

export const ContentStatusKeys: {
	[K in SearchStatusKeys]: SearchConfluenceDocumentStatus[];
} = {
	['showCurrent']: [],
	['showArchived']: ['ARCHIVED', 'CURRENT', 'DRAFT'],
};

const getContentStatusValue = (filter?: AllFilterStates) => {
	const archivedContentOption = getBooleanFilterValue(filter);

	return archivedContentOption
		? ContentStatusKeys['showArchived']
		: ContentStatusKeys['showCurrent'];
};

const getContainerStatusValue = (filter?: AllFilterStates) => {
	const archivedContentOption = getBooleanFilterValue(filter);

	return archivedContentOption
		? ContainerStatusKeys['showArchived']
		: ContainerStatusKeys['showCurrent'];
};

type JiraProductKeys =
	| typeof ProductKeys.Jira
	| typeof ProductKeys.JiraProductDiscovery
	| typeof ProductKeys.JiraServiceManagement;

export const JiraProductProjectFilterKey: {
	[K in JiraProductKeys]: SearchJiraProductType[];
} = {
	[ProductKeys.Jira]: ['software', 'business'],
	[ProductKeys.JiraProductDiscovery]: ['product_discovery'],
	[ProductKeys.JiraServiceManagement]: ['service_desk'],
};

export const getJiraProjectFilter = (selectedProduct?: ProductKeys): SearchJiraProductType[] => {
	// when all products are searched
	if (!selectedProduct) {
		return Object.values(JiraProductProjectFilterKey).flatMap((x) => x);
	}

	switch (selectedProduct) {
		case ProductKeys.Jira:
		case ProductKeys.JiraProductDiscovery:
		case ProductKeys.JiraServiceManagement:
			return JiraProductProjectFilterKey[selectedProduct];
	}

	return [];
};

export const omitNonJiraFilterValues = (
	filterValues: string[],
	selectedProduct?: ProductKeys,
): string[] => {
	// when all products are searched
	if (!selectedProduct) {
		// don't return any values in all product selected mode for assignee and project filters
		return [];
	}

	switch (selectedProduct) {
		case ProductKeys.Jira:
		case ProductKeys.JiraProductDiscovery:
		case ProductKeys.JiraServiceManagement:
			return filterValues;
	}

	return [];
};

// providing dummy value when no results returned so that it still filters on the type
// otherwise an empty array would return all types and not match what the user filters
export const DUMMY_JIRA_ISSUE_TYPE_ID = 'DUMMY_JIRA_ISSUE_TYPE_ID';

export const getJiraIssueTypesFilter = async (
	cloudId: string | undefined,
	intl: IntlShape,
	issueType: Partial<TypeFilterValueKey>,
): Promise<string> => {
	if (!cloudId) {
		return DUMMY_JIRA_ISSUE_TYPE_ID;
	}
	const searchString = intl.formatMessage(messages[issueType] as MessageDescriptor);
	const issueTypes = await jiraIssueTypeJQLQuery(cloudId, searchString);

	const ids = issueTypes?.data?.jira.jqlBuilder.fieldValues.edges;
	if (!ids || ids.length === 0) {
		return DUMMY_JIRA_ISSUE_TYPE_ID;
	}
	if (ids.length > 1) {
		const issueTypeNode = ids.find((id) => id.node?.displayName === searchString);
		if (!issueTypeNode) {
			return DUMMY_JIRA_ISSUE_TYPE_ID;
		}
		const issueType = issueTypeNode.node?.issueTypes[0];
		return issueType && issueType.issueTypeId ? issueType.issueTypeId : DUMMY_JIRA_ISSUE_TYPE_ID;
	} else {
		const node = ids[0].node;
		if (!node) {
			return DUMMY_JIRA_ISSUE_TYPE_ID;
		}
		return node.issueTypes[0].issueTypeId || DUMMY_JIRA_ISSUE_TYPE_ID;
	}
};

export const useJiraIssueTypeFilters = (
	cloudId: string | undefined,
	selectedEntities: string[],
) => {
	const intl = useIntl();
	const [jiraIssueTypeFilters, setJiraIssueTypeFilters] = useState<string[]>([]);

	useEffect(() => {
		const getJiraIssues = async () => {
			if (selectedEntities.includes('incident')) {
				const issueType = await getJiraIssueTypesFilter(cloudId, intl, 'incident');
				setJiraIssueTypeFilters([issueType]);
			} else {
				setJiraIssueTypeFilters([]);
			}
		};
		getJiraIssues();
	}, [selectedEntities, cloudId, intl]);

	return jiraIssueTypeFilters;
};

type GetSelectedFiltersProps = Pick<GetTypeTypeEntitiesProps, 'thirdPartyConfigs'> &
	GetTypeTypeEntitiesState & {
		experimentConfig: ExperimentConfig;
	};

export const getSelectedFilters = ({
	filters,
	selectedProducts,
	availableProducts: products,
	allFilterExcludedProducts,
	thirdPartyConfigs,
	experimentConfig,
}: GetSelectedFiltersProps) => {
	const isDropAttachmentsCommentsFrmAllTypesEnabled =
		getIsDropAttachmentsCommentsFrmAllTypesEnabled(experimentConfig);
	const { selectedProduct, filteredProducts, availableProducts } = getProducts({
		selectedProducts,
		availableProducts: products,
	});
	const { selectedEntities, entitiesForQuery, entitiesForResultsCountQuery } = getTypeEntities(
		{ availableProducts, filters, selectedProducts, allFilterExcludedProducts },
		{
			thirdPartyConfigs,
			dropAttachmentsComments: isDropAttachmentsCommentsFrmAllTypesEnabled,
		},
	);

	const titleMatchValue = getBooleanFilterValue(filters[titleMatchFilter.id]);
	const selectedDateRange = getSelectedDateRange(filters[lastUpdatedFilter.id]);
	const filterDateRange = getFilterDateRange(filters[lastUpdatedFilter.id]);
	const commonContributors = getSelectFilterValue(filters[contributorFilter.id]).length
		? getSelectFilterValue(filters[contributorFilter.id])
		: undefined;
	const pageStatusValue = getSelectFilterValue(filters[pageStatusFilter.id])[0];
	const assignee3PValues = getSelectFilterValue(filters[assigneeFilter.id]).map((assigneeId) => {
		if (assigneeId.startsWith('ari:cloud:')) {
			return IdentityUserAri.parse(assigneeId).resourceId;
		}
		return assigneeId;
	});

	const selectedFilters = {
		commonFilters: {
			range: {
				lastModified: selectedDateRange,
			},
			contributorsFilter: commonContributors,
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: getSelectFilterValue(filters[projectFilter.id]),
				assigneeARIs: getSelectFilterValue(filters[assigneeFilter.id]),
				// issueTypeIDs: fg('enable_jsm_incident_type') ? jiraIssueTypeFilters : [],
				// I'm ommiting the above line because it does a fetch request and
				// has a dependency on IntlShape
				issueTypeIDs: [],
			},
			projectFilter: {
				projectTypes: getJiraProjectFilter(selectedProduct),
			},
		},
		confluenceFilters: {
			spacesFilter: getSelectFilterValue(filters[spaceFilter.id]),
			isVerified: getBooleanFilterValue(filters[verifiedFilter.id]) || undefined,
			contributorsFilter: getSelectFilterValue(filters[contributorFilter.id]),
			ancestorIdsFilter: getSelectFilterValue(filters[contentFilter.id]),
			range: selectedProduct !== ProductKeys.Confluence ? filterDateRange : [],
			owners: getSelectFilterValue(filters[ownerFilter.id]),
			labelsFilter: getSelectFilterValue(filters[labelFilter.id]),
			...(fg('add_confluence_title_match_filter') ? { titleMatchOnly: titleMatchValue } : {}),
			...(pageStatusValue && fg('add_confluence_status_filter')
				? { pageStatus: pageStatusValue }
				: {}),
			contentStatuses:
				selectedProduct === ProductKeys.Confluence
					? getContentStatusValue(filters[archivedFilter.id])
					: [],
			containerStatus:
				selectedProduct === ProductKeys.Confluence
					? getContainerStatusValue(filters[archivedFilter.id])
					: [],
		},
		mercuryFilters: {
			owners: getSelectFilterValue(filters[ownerFilter.id]),
			focusAreaTypeIds: getSelectFilterValue(filters[focusAreaTypeFilter.id]),
		},
		thirdPartyFilters: getThirdPartyFilters(
			filteredProducts,
			titleMatchValue,
			selectedEntities as TypeFilterValueKey[],
			thirdPartyConfigs,
			baseIntegrationARIs,
			filterDateRange,
			assignee3PValues,
		),
	} satisfies SelectedFiltersType;

	const unselectedProductsThirdPartyFilters = getThirdPartyFilters(
		availableProducts,
		titleMatchValue,
		selectedEntities as TypeFilterValueKey[],
		thirdPartyConfigs,
		baseIntegrationARIs,
		filterDateRange,
	);

	const { commonFilters, jiraFilters, confluenceFilters, mercuryFilters, thirdPartyFilters } =
		selectedFilters;

	const isEmpty =
		!commonFilters.range.lastModified &&
		selectedEntities.length === 0 &&
		confluenceFilters.spacesFilter.length === 0 &&
		!confluenceFilters.isVerified &&
		!confluenceFilters.titleMatchOnly &&
		confluenceFilters.contentStatuses.length === 0 &&
		confluenceFilters.containerStatus.length === 0 &&
		confluenceFilters.contributorsFilter.length === 0 &&
		confluenceFilters.ancestorIdsFilter.length === 0 &&
		confluenceFilters.range.length === 0 &&
		confluenceFilters.owners.length === 0 &&
		confluenceFilters.labelsFilter.length === 0 &&
		(!confluenceFilters.pageStatus || confluenceFilters.pageStatus.length === 0) &&
		jiraFilters.issueFilter.assigneeARIs.length === 0 &&
		jiraFilters.issueFilter.projectARIs.length === 0 &&
		mercuryFilters.owners.length === 0 &&
		mercuryFilters.focusAreaTypeIds.length === 0 &&
		(!thirdPartyFilters.range || thirdPartyFilters?.range?.length === 0) &&
		!thirdPartyFilters.titleMatchOnly &&
		thirdPartyFilters.subtypes.length === 0;

	return {
		selectedFilters,
		unselectedProductsThirdPartyFilters,
		selectedEntities,
		entitiesForResultsCountQuery,
		entities: entitiesForQuery,
		hasFiltersSelected: !isEmpty,
	};
};

export const useSelectedFilters = ({
	cloudId,
	thirdPartyConfigs,
}: {
	cloudId: string | undefined;
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
}): {
	selectedFilters: SelectedFiltersType;
	unselectedProductsThirdPartyFilters: ThirdPartyFilters;
	hasFiltersSelected: boolean;
	selectedEntities: string[];
	entitiesForResultsCountQuery: EntityATI[];
	entities: EntityATI[];
} => {
	const filters = useFilters();
	const [isDropAttachmentsCommentsFrmAllTypesEnabled] =
		useIsDropAttachmentsCommentsFrmAllTypesEnabled();
	const { selectedProduct, filteredProducts, availableProducts } = useProducts();

	const useTypeEntitiesArgs = useMemo(
		() => ({
			thirdPartyConfigs,
			dropAttachmentsComments: isDropAttachmentsCommentsFrmAllTypesEnabled,
		}),
		[thirdPartyConfigs, isDropAttachmentsCommentsFrmAllTypesEnabled],
	);

	const { selectedEntities, entitiesForQuery, entitiesForResultsCountQuery } =
		useTypeEntities(useTypeEntitiesArgs);
	const jiraIssueTypeFilters = useJiraIssueTypeFilters(cloudId, selectedEntities);

	const titleMatchValue = useMemo(
		() => getBooleanFilterValue(filters[titleMatchFilter.id]),
		[filters],
	);

	const selectedDateRange = useMemo(
		() => getSelectedDateRange(filters[lastUpdatedFilter.id]),
		[filters],
	);

	const filterDateRange = useMemo(
		() => getFilterDateRange(filters[lastUpdatedFilter.id]),
		[filters],
	);

	const commonContributors = useMemo(() => {
		const contributors = getSelectFilterValue(filters[contributorFilter.id]);
		return contributors.length ? contributors : undefined;
	}, [filters]);

	const pageStatusValue = useMemo(
		() => getSelectFilterValue(filters[pageStatusFilter.id])[0],
		[filters],
	);

	const assignee3PValues = useMemo(
		() =>
			getSelectFilterValue(filters[assigneeFilter.id]).map((assigneeId) => {
				if (assigneeId.startsWith('ari:cloud:')) {
					return IdentityUserAri.parse(assigneeId).resourceId;
				}
				return assigneeId;
			}),
		[filters],
	);

	const containerAris = useMemo(() => getSelectFilterValue(filters[projectFilter.id]), [filters]);

	const selectedFilters = useMemo(
		() =>
			({
				commonFilters: {
					range: {
						lastModified: selectedDateRange,
					},
					contributorsFilter: commonContributors,
				},
				jiraFilters: {
					issueFilter: {
						projectARIs: omitNonJiraFilterValues(
							getSelectFilterValue(filters[projectFilter.id]),
							selectedProduct,
						),
						assigneeARIs: omitNonJiraFilterValues(
							getSelectFilterValue(filters[assigneeFilter.id]),
							selectedProduct,
						),
						issueTypeIDs: jiraIssueTypeFilters,
					},
					projectFilter: {
						projectTypes: getJiraProjectFilter(selectedProduct),
					},
				},
				confluenceFilters: {
					spacesFilter: getSelectFilterValue(filters[spaceFilter.id]),
					isVerified: getBooleanFilterValue(filters[verifiedFilter.id]) || undefined,
					contributorsFilter: getSelectFilterValue(filters[contributorFilter.id]),
					ancestorIdsFilter: getSelectFilterValue(filters[contentFilter.id]),
					range: selectedProduct !== ProductKeys.Confluence ? filterDateRange : [],
					owners: getSelectFilterValue(filters[ownerFilter.id]),
					labelsFilter: getSelectFilterValue(filters[labelFilter.id]),
					...(fg('add_confluence_title_match_filter') ? { titleMatchOnly: titleMatchValue } : {}),
					...(pageStatusValue && fg('add_confluence_status_filter')
						? { pageStatus: pageStatusValue }
						: {}),
					contentStatuses:
						selectedProduct === ProductKeys.Confluence
							? getContentStatusValue(filters[archivedFilter.id])
							: [],
					containerStatus:
						selectedProduct === ProductKeys.Confluence
							? getContainerStatusValue(filters[archivedFilter.id])
							: [],
				},
				mercuryFilters: {
					owners: getSelectFilterValue(filters[ownerFilter.id]),
					focusAreaTypeIds: getSelectFilterValue(filters[focusAreaTypeFilter.id]),
				},
				thirdPartyFilters: getThirdPartyFilters(
					filteredProducts,
					titleMatchValue,
					selectedEntities as TypeFilterValueKey[],
					thirdPartyConfigs,
					baseIntegrationARIs,
					filterDateRange,
					assignee3PValues,
					containerAris,
				),
			}) satisfies SelectedFiltersType,
		[
			filterDateRange,
			filteredProducts,
			filters,
			thirdPartyConfigs,
			selectedDateRange,
			selectedEntities,
			selectedProduct,
			titleMatchValue,
			commonContributors,
			jiraIssueTypeFilters,
			pageStatusValue,
			assignee3PValues,
			containerAris,
		],
	);

	const unselectedProductsThirdPartyFilters = useMemo(
		() =>
			getThirdPartyFilters(
				availableProducts,
				titleMatchValue,
				selectedEntities as TypeFilterValueKey[],
				thirdPartyConfigs,
				baseIntegrationARIs,
				filterDateRange,
			),

		[availableProducts, titleMatchValue, selectedEntities, thirdPartyConfigs, filterDateRange],
	);

	const { commonFilters, jiraFilters, confluenceFilters, mercuryFilters, thirdPartyFilters } =
		selectedFilters;

	const isEmpty =
		!commonFilters.range.lastModified &&
		selectedEntities.length === 0 &&
		confluenceFilters.spacesFilter.length === 0 &&
		!confluenceFilters.isVerified &&
		!confluenceFilters.titleMatchOnly &&
		confluenceFilters.contentStatuses.length === 0 &&
		confluenceFilters.containerStatus.length === 0 &&
		confluenceFilters.contributorsFilter.length === 0 &&
		confluenceFilters.ancestorIdsFilter.length === 0 &&
		confluenceFilters.range.length === 0 &&
		confluenceFilters.owners.length === 0 &&
		confluenceFilters.labelsFilter.length === 0 &&
		(!confluenceFilters.pageStatus || confluenceFilters.pageStatus.length === 0) &&
		jiraFilters.issueFilter.assigneeARIs.length === 0 &&
		jiraFilters.issueFilter.projectARIs.length === 0 &&
		mercuryFilters.owners.length === 0 &&
		mercuryFilters.focusAreaTypeIds.length === 0 &&
		(!thirdPartyFilters.range || thirdPartyFilters?.range?.length === 0) &&
		!thirdPartyFilters.titleMatchOnly &&
		thirdPartyFilters.subtypes.length === 0;

	return useMemo(() => {
		return {
			selectedFilters,
			unselectedProductsThirdPartyFilters,
			selectedEntities,
			entitiesForResultsCountQuery,
			entities: entitiesForQuery,
			hasFiltersSelected: !isEmpty,
		};
	}, [
		entitiesForQuery,
		entitiesForResultsCountQuery,
		isEmpty,
		selectedEntities,
		selectedFilters,
		unselectedProductsThirdPartyFilters,
	]);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PowerBIIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-602.5"
				y="-507.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<path
				d="M17.4031 4H13.1618C12.7971 4 12.5015 4.29563 12.5015 4.66032V19.3397C12.5015 19.7044 12.7971 20 13.1618 20H17.4031C17.7677 20 18.0634 19.7044 18.0634 19.3397V4.66032C18.0634 4.29563 17.7677 4 17.4031 4Z"
				fill="#B25400"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.787 19.3394V19.9998H9.88542C9.52072 19.9998 9.2251 19.7041 9.2251 19.3394V8.64738C9.2251 8.28268 9.52072 7.98706 9.88542 7.98706H14.1267C14.4914 7.98706 14.787 8.28268 14.787 8.64738V19.3394Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.787 19.3394V19.9998H9.88542C9.52072 19.9998 9.2251 19.7041 9.2251 19.3394V8.64738C9.2251 8.28268 9.52072 7.98706 9.88542 7.98706H14.1267C14.4914 7.98706 14.787 8.28268 14.787 8.64738V19.3394Z"
				fill="#DE9800"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5619 19.3394V19.9998H6.66032C6.29562 19.9998 6 19.7041 6 19.3394V12.6601C6 12.2954 6.29562 11.9998 6.66032 11.9998H10.9016C11.2663 11.9998 11.5619 12.2954 11.5619 12.6601V19.3394Z"
				fill="#F3CD32"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.4032 4H13.1619C12.7972 4 12.5016 4.29563 12.5016 4.66032V19.3397C12.5016 19.7044 12.7972 20 13.1619 20H17.4032C17.7679 20 18.0635 19.7044 18.0635 19.3397V4.66032C18.0635 4.29563 17.7679 4 17.4032 4Z"
				fill="url(#paint0_linear_384_141)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.7873 19.3397V20H9.88572C9.52102 20 9.2254 19.7044 9.2254 19.3397V8.64762C9.2254 8.28292 9.52102 7.9873 9.88572 7.9873H14.127C14.4917 7.9873 14.7873 8.28292 14.7873 8.64762V19.3397Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.7873 19.3397V20H9.88572C9.52102 20 9.2254 19.7044 9.2254 19.3397V8.64762C9.2254 8.28292 9.52102 7.9873 9.88572 7.9873H14.127C14.4917 7.9873 14.7873 8.28292 14.7873 8.64762V19.3397Z"
				fill="url(#paint1_linear_384_141)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5619 19.3397V20H6.66032C6.29562 20 6 19.7044 6 19.3397V12.6603C6 12.2956 6.29562 12 6.66032 12H10.9016C11.2663 12 11.5619 12.2956 11.5619 12.6603V19.3397Z"
				fill="url(#paint2_linear_384_141)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_384_141"
					x1="15.2561"
					y1="3.97968"
					x2="15.2561"
					y2="19.9797"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBBB14" />
					<stop offset="1" stopColor="#B25400" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_384_141"
					x1="11.9799"
					y1="7.93778"
					x2="11.9799"
					y2="19.9505"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E583" />
					<stop offset="1" stopColor="#DE9800" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_384_141"
					x1="8.75454"
					y1="11.9797"
					x2="8.75454"
					y2="19.9797"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E68B" />
					<stop offset="1" stopColor="#F3CD32" />
				</linearGradient>
			</defs>
		</svg>
	);
};

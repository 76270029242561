import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GoogleDriveSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-23.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<g>
				<path
					d="M6.94608 22.7709L7.8869 24.3877C8.08239 24.728 8.36342 24.9955 8.69331 25.1899L12.0534 19.4038H5.33325C5.33325 19.7806 5.431 20.1575 5.62649 20.4978L6.94608 22.7709Z"
					fill="#0066DA"
				/>
				<path
					d="M15.9999 12.5966L12.6399 6.81049C12.31 7.00498 12.0289 7.2724 11.8334 7.61276L5.62649 18.3098C5.43459 18.6428 5.33351 19.02 5.33325 19.4038H12.0534L15.9999 12.5966Z"
					fill="#00AC47"
				/>
				<path
					d="M23.3066 25.1899C23.6365 24.9955 23.9175 24.728 24.113 24.3877L24.504 23.7191L26.3734 20.4978C26.5689 20.1575 26.6667 19.7806 26.6667 19.4038H19.946L21.3761 22.1996L23.3066 25.1899Z"
					fill="#EA4335"
				/>
				<path
					d="M16 12.5967L19.36 6.81054C19.0301 6.61604 18.6513 6.5188 18.2604 6.5188H13.7395C13.3486 6.5188 12.9698 6.6282 12.6399 6.81054L16 12.5967Z"
					fill="#00832D"
				/>
				<path
					d="M19.9463 19.4038H12.0532L8.69312 25.1899C9.02301 25.3844 9.40178 25.4817 9.79277 25.4817H22.2067C22.5977 25.4817 22.9764 25.3723 23.3063 25.1899L19.9463 19.4038Z"
					fill="#2684FC"
				/>
				<path
					d="M23.2697 12.9613L20.1662 7.61276C19.9707 7.2724 19.6897 7.00498 19.3598 6.81049L15.9998 12.5966L19.9463 19.4038H26.6542C26.6542 19.027 26.5565 18.6502 26.361 18.3098L23.2697 12.9613Z"
					fill="#FFBA00"
				/>
			</g>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_18945)">
				<path
					d="M7.26804 29.8189L8.59107 32.1635C8.86598 32.6571 9.26117 33.0449 9.72509 33.3269L14.4502 24.9359H5C5 25.4824 5.13746 26.0288 5.41237 26.5224L7.26804 29.8189Z"
					fill="#0066DA"
				/>
				<path
					d="M20 15.0641L15.2749 6.6731C14.811 6.95515 14.4158 7.34297 14.1409 7.83656L5.41237 23.3494C5.14251 23.8323 5.00036 24.3792 5 24.9359H14.4502L20 15.0641Z"
					fill="#00AC47"
				/>
				<path
					d="M30.2751 33.3269C30.739 33.0449 31.1342 32.6571 31.4091 32.1635L31.9589 31.1939L34.5878 26.5224C34.8627 26.0288 35.0002 25.4824 35.0002 24.9359H25.5493L27.5603 28.9904L30.2751 33.3269Z"
					fill="#EA4335"
				/>
				<path
					d="M20.0001 15.0641L24.7252 6.67308C24.2613 6.39103 23.7286 6.25 23.1788 6.25H16.8214C16.2716 6.25 15.7389 6.40865 15.275 6.67308L20.0001 15.0641Z"
					fill="#00832D"
				/>
				<path
					d="M25.5497 24.9359H14.4501L9.72498 33.3269C10.1889 33.609 10.7215 33.75 11.2714 33.75H28.7284C29.2782 33.75 29.8109 33.5913 30.2748 33.3269L25.5497 24.9359Z"
					fill="#2684FC"
				/>
				<path
					d="M30.2234 15.593L25.8591 7.83656C25.5842 7.34297 25.189 6.95515 24.7251 6.6731L20 15.0641L25.5498 24.9359H34.9828C34.9828 24.3894 34.8454 23.843 34.5704 23.3494L30.2234 15.593Z"
					fill="#FFBA00"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2230_18945">
					<rect width="30" height="27.5" fill="white" transform="translate(5 6.25)" />
				</clipPath>
			</defs>
		</svg>
	);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GoogleCalendarSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-119.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<g>
				<path
					d="M21.0942 10.8996L16.5094 10.3902L10.9057 10.8996L10.3962 15.9939L10.9056 21.0881L15.9999 21.7249L21.0942 21.0881L21.6036 15.8666L21.0942 10.8996Z"
					fill="white"
				/>
				<path
					d="M12.9956 18.8033C12.6149 18.546 12.3512 18.1704 12.2073 17.6737L13.0912 17.3094C13.1714 17.6151 13.3115 17.8519 13.5114 18.0201C13.7101 18.1882 13.9521 18.271 14.2348 18.271C14.5239 18.271 14.7723 18.1831 14.9798 18.0073C15.1873 17.8315 15.2919 17.6074 15.2919 17.3362C15.2919 17.0586 15.1823 16.8318 14.9633 16.6561C14.7442 16.4804 14.4692 16.3925 14.1406 16.3925H13.6299V15.5176H14.0883C14.371 15.5176 14.6092 15.4412 14.8028 15.2884C14.9964 15.1355 15.0932 14.9267 15.0932 14.6605C15.0932 14.4236 15.0065 14.2351 14.8334 14.0938C14.6602 13.9525 14.4411 13.8811 14.1749 13.8811C13.9151 13.8811 13.7088 13.95 13.5559 14.0887C13.4031 14.2275 13.2923 14.3982 13.2223 14.5994L12.3474 14.2352C12.4633 13.9066 12.676 13.6162 12.988 13.3653C13.3 13.1145 13.6986 12.9883 14.1826 12.9883C14.5404 12.9883 14.8626 13.0572 15.148 13.196C15.4332 13.3348 15.6574 13.5271 15.8191 13.7716C15.9808 14.0173 16.0611 14.2925 16.0611 14.5981C16.0611 14.9101 15.986 15.1737 15.8356 15.3902C15.6853 15.6067 15.5007 15.7722 15.2816 15.8882V15.9403C15.5707 16.0613 15.8063 16.246 15.9923 16.4944C16.1769 16.7427 16.2698 17.0395 16.2698 17.3859C16.2698 17.7323 16.182 18.0418 16.0062 18.3131C15.8304 18.5844 15.5872 18.7983 15.279 18.9536C14.9696 19.109 14.6219 19.1879 14.236 19.1879C13.789 19.1892 13.3764 19.0606 12.9956 18.8033Z"
					fill="#1A73E8"
				/>
				<path
					d="M18.4191 14.4171L17.4537 15.1189L16.9685 14.3828L18.7095 13.127H19.3768V19.0503H18.4191V14.4171Z"
					fill="#1A73E8"
				/>
				<path
					d="M21.0943 25.6728L25.679 21.088L23.3866 20.0692L21.0943 21.088L20.0754 23.3804L21.0943 25.6728Z"
					fill="#EA4335"
				/>
				<path
					d="M9.88672 23.3805L10.9055 25.6729H21.094V21.0881H10.9055L9.88672 23.3805Z"
					fill="#34A853"
				/>
				<path
					d="M7.84902 6.31488C7.00472 6.31488 6.3208 6.9988 6.3208 7.8431V21.0881L8.61318 22.1069L10.9056 21.0881V10.8996H21.094L22.1128 8.60726L21.0941 6.31488H7.84902Z"
					fill="#4285F4"
				/>
				<path
					d="M6.3208 21.0881V24.1447C6.3208 24.9891 7.00472 25.6729 7.84902 25.6729H10.9056V21.0881H6.3208Z"
					fill="#188038"
				/>
				<path
					d="M21.0938 10.8997V21.0882H25.6785V10.8997L23.3861 9.88092L21.0938 10.8997Z"
					fill="#FBBC04"
				/>
				<path
					d="M25.6785 10.8996V7.84304C25.6785 6.99864 24.9946 6.31482 24.1503 6.31482H21.0938V10.8996H25.6785Z"
					fill="#1967D2"
				/>
			</g>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_18795)">
				<path
					d="M27.8948 12.1052L20.7896 11.3158L12.1054 12.1052L11.3158 20L12.1052 27.8948L20 28.8817L27.8948 27.8948L28.6843 19.8028L27.8948 12.1052Z"
					fill="white"
				/>
				<path
					d="M15.3441 24.3539C14.754 23.9552 14.3454 23.373 14.1224 22.6032L15.4922 22.0388C15.6165 22.5125 15.8336 22.8795 16.1435 23.1401C16.4514 23.4006 16.8264 23.5289 17.2646 23.5289C17.7126 23.5289 18.0975 23.3927 18.4191 23.1203C18.7407 22.8479 18.9027 22.5005 18.9027 22.0802C18.9027 21.65 18.7329 21.2985 18.3935 21.0263C18.054 20.754 17.6277 20.6177 17.1185 20.6177H16.3271V19.2618H17.0375C17.4756 19.2618 17.8448 19.1435 18.1448 18.9066C18.4448 18.6698 18.5948 18.3461 18.5948 17.9336C18.5948 17.5665 18.4605 17.2743 18.1922 17.0553C17.9238 16.8363 17.5842 16.7258 17.1717 16.7258C16.7691 16.7258 16.4493 16.8324 16.2125 17.0475C15.9756 17.2626 15.8039 17.5271 15.6954 17.8389L14.3396 17.2745C14.5191 16.7652 14.8488 16.3152 15.3323 15.9264C15.8159 15.5376 16.4336 15.3422 17.1836 15.3422C17.7381 15.3422 18.2375 15.4488 18.6797 15.6639C19.1217 15.879 19.4691 16.1771 19.7198 16.556C19.9704 16.9368 20.0948 17.3633 20.0948 17.8368C20.0948 18.3204 19.9784 18.7289 19.7454 19.0644C19.5125 19.4 19.2263 19.6565 18.8868 19.8362V19.917C19.3349 20.1045 19.7 20.3907 19.9881 20.7756C20.2743 21.1605 20.4183 21.6204 20.4183 22.1573C20.4183 22.6941 20.2821 23.1737 20.0097 23.5941C19.7373 24.0146 19.3604 24.3461 18.8828 24.5868C18.4032 24.8276 17.8644 24.95 17.2664 24.95C16.5737 24.9519 15.9342 24.7526 15.3441 24.3539Z"
					fill="#1A73E8"
				/>
				<path
					d="M23.7495 17.5566L22.2534 18.6441L21.5015 17.5034L24.1995 15.5573H25.2338V24.7368H23.7495V17.5566Z"
					fill="#1A73E8"
				/>
				<path
					d="M27.8948 35L35 27.8948L31.4474 26.3159L27.8948 27.8948L26.3159 31.4474L27.8948 35Z"
					fill="#EA4335"
				/>
				<path
					d="M10.5262 31.4474L12.1051 35H27.8946V27.8948H12.1051L10.5262 31.4474Z"
					fill="#34A853"
				/>
				<path
					d="M7.36835 5C6.0599 5 5 6.0599 5 7.36835V27.8946L8.5526 29.4736L12.1052 27.8946V12.1052H27.8946L29.4736 8.5526L27.8948 5H7.36835Z"
					fill="#4285F4"
				/>
				<path
					d="M5 27.8948V32.6317C5 33.9403 6.0599 35 7.36835 35H12.1052V27.8948H5Z"
					fill="#188038"
				/>
				<path
					d="M27.8943 12.1053V27.8948H34.9995V12.1053L31.4469 10.5264L27.8943 12.1053Z"
					fill="#FBBC04"
				/>
				<path
					d="M34.9995 12.1052V7.36835C34.9995 6.05975 33.9396 5 32.6311 5H27.8943V12.1052H34.9995Z"
					fill="#1967D2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2230_18795">
					<rect width="30" height="30" fill="white" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

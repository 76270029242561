import {
	createClient,
	type JiraDCFilterOption,
	jiraDcProjectFilterOptionsQuery,
	type JiraProject,
	jiraProjectsQuery,
	JiraScope,
} from '@atlassian/search-client';

import { DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI } from '../../../../../common/constants/entities';
import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

const projectIdToARI = (cloudId: string, projectId: string) =>
	`ari:cloud:jira:${cloudId}:project/${projectId}`;

const searchProject = async (query: string, config: CloudConfig) => {
	const searchClient = createClient({
		cloudId: config.cloudId || '',
	});

	let searchProjectsResponse, searchedProjects: JiraProject[];

	const product = config.product;
	if (product === ProductKeys.JiraDC) {
		searchProjectsResponse = await jiraDcProjectFilterOptionsQuery({
			variables: {
				query,
				cloudIdARI: `ari:cloud:confluence::site/${config.cloudId}`,
				limit: 5,
				entities: DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
				product,
			},
		});
		return (
			searchProjectsResponse?.data?.search?.search?.edges?.map((edge: JiraDCFilterOption) => ({
				trackingKey: edge.node.entity.id,
				value: edge.node.entity.id,
				label: edge.node.entity.displayName,
				avatarUrl: edge?.node?.entity?.icon?.url,
				queryParamValue: edge.node.entity.id,
			})) || []
		);
	} else {
		searchProjectsResponse = await searchClient.search({
			query,
			resultLimit: 5,
			scopes: [JiraScope.JiraProject],
			analytics: {
				identifier: 'search-page',
			},
		});
		searchedProjects =
			searchProjectsResponse.response.rawData.scopes.length > 0
				? searchProjectsResponse.response.rawData.scopes[0].results.map((projectResult) => ({
						projectId: projectResult.id,
						name: projectResult.name,
						avatar: {
							small:
								'url' in projectResult.attributes.avatar
									? projectResult.attributes.avatar.url
									: undefined,
						},
					}))
				: [];

		return searchedProjects.map((project) => ({
			trackingKey: projectIdToARI(config.cloudId || '', project.projectId),
			value: projectIdToARI(config.cloudId || '', project.projectId),
			label: project.name,
			avatarUrl: project.avatar?.small,
			queryParamValue: project.projectId,
		}));
	}
};

const productKeys1P: ProductKeys1P[] = [
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('project');

export const filter = createMultiSelectFilter({
	id: 'project',
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: true,
	universal: false,
	queryParams: {
		key: 'jProject',
		fetcher: async (queryParams, config) => {
			const initialProjectARIs = queryParams.map((projectId) =>
				projectIdToARI(config.cloudId || '', projectId),
			);
			const initialProjectsResponse = await jiraProjectsQuery(initialProjectARIs);

			const initialProjects: JiraProject[] = initialProjectsResponse.data?.jira.jiraProjects ?? [];

			const allProjects: JiraProject[] = initialProjects;
			const projectOptions = allProjects.map((project) => ({
				trackingKey: projectIdToARI(config.cloudId || '', project.projectId),
				value: projectIdToARI(config.cloudId || '', project.projectId),
				label: project.name,
				avatarUrl: project.avatar?.small,
				queryParamValue: project.projectId,
			}));
			return projectOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return searchProject('', config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchProject(query, config);
		},
	},
});

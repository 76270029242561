export type Maybe<T> = T | null;

export type ProductType =
	| 'jira'
	| 'confluence'
	| 'townsquare' // townsquare = atlas
	| 'drive'
	| 'microsoft'
	| 'sharepoint'
	| 'github'
	| 'slack'
	| 'graph'
	| 'onedrive'
	| 'dropbox'
	| 'google-calendar'
	| 'trello'
	| 'compass'
	| 'mercury' // mercury (internal name) = focus (external name)
	| 'gmail'
	| 'outlook-calendar'
	| 'box'
	| 'salesforce'
	| string;

export type JiraEntityType = 'project' | 'issue' | 'filter' | 'dashboard' | 'board'; // supported jira entities

export type JiraEntityATI = `ati:cloud:jira:${JiraEntityType}`;

export type CompassEntityATI = 'ati:cloud:compass:component';

export type TrelloEntityType = 'board' | 'card';

export type TrelloEntityATI = `ati:cloud:trello:${TrelloEntityType}`;

export type ConfluenceEntityType =
	| 'attachment'
	| 'blogpost'
	| 'calendar'
	| 'space-calendar'
	| 'answer'
	| 'question'
	| 'comment'
	| 'database'
	| 'embed' // smart links
	| 'folder'
	| 'page'
	| 'space'
	| 'whiteboard'
	| 'gliffy';

export type ConfluenceEntityATI =
	| `ati:cloud:confluence:${ConfluenceEntityType}`
	| 'confluence.com.atlassian.confluence.extra.team-calendars:calendar-content-type'
	| 'confluence.com.atlassian.confluence.extra.team-calendars:space-calendars-view-content-type'
	| 'confluence.ac:com.atlassian.confluence.plugins.confluence-questions:question'
	| 'confluence.ac:com.atlassian.confluence.plugins.confluence-questions:answer'
	| 'confluence.ac:com.gliffy.integration.confluence:gliffy-diagram';

export type AtlasEntityType = 'project' | 'goal' | 'tag' | 'question' | 'learning'; // supported atlas entities

export type AtlasEntityATI = `ati:cloud:townsquare:${AtlasEntityType}`;

export type MercuryEntityType = 'focus-area' | 'focus-area-status-update'; // supported mercury entities

export type MercuryEntityATI = `ati:cloud:mercury:${MercuryEntityType}`;

export type GoogleEntityType = 'document' | 'presentation' | 'spreadsheet';

export type MicrosoftEntityType = 'document' | 'presentation' | 'spreadsheet';

export type SlackEntityType = 'message';

export type GithubEntityType = 'repository' | 'pull-request' | 'commit' | 'branch';

export type SalesforceEntityType = 'remote-link' | 'deal' | 'customer-org';

// Ingested entities
export type CloudGraphDocumentEntityAti = 'ati:cloud:graph:document';
export type CloudGraphMessageEntityAti = 'ati:cloud:graph:message';
export type CloudGraphConversationEntityAti = 'ati:cloud:graph:conversation';
export type CloudGraphDesignEntityAti = 'ati:cloud:graph:design';
export type CloudGraphVideoEntityAti = 'ati:cloud:graph:video';
export type CloudGraphSpaceEntityAti = 'ati:cloud:graph:space';
export type CloudGraphCommentEntityAti = 'ati:cloud:graph:comment';
export type CloudGraphRepositoryEntityAti = 'ati:cloud:graph:repository';
export type CloudGraphPullRequestEntityAti = 'ati:cloud:graph:pull-request';
export type CloudGraphCommitEntityAti = 'ati:cloud:graph:commit';
export type CloudGraphBranchEntityAti = 'ati:cloud:graph:branch';
export type CloudGraphCalendarEventEntityAti = 'ati:cloud:graph:calendar-event';
export type CloudGraphWorkItemEntityAti = 'ati:cloud:graph:work-item';
export type CloudGraphRemoteLinkEntityAti = 'ati:cloud:graph:remote-link';
export type CloudGraphProjectEntityAti = 'ati:cloud:graph:project';
export type CloudGraphDealEntityAti = 'ati:cloud:graph:deal';
export type CloudGraphCustomerOrgEntityAti = 'ati:cloud:graph:customer-org';

// federated entities
export type FederatedGoogleEmailEntityAti = 'ati:third-party:google:email';
export type FederatedMicrosoftEmailEntityAti = 'ati:third-party:microsoft:email';
export type FederatedEmailProducts = 'google' | 'microsoft';
export type FederatedEmailEntityAti = `ati:third-party:${FederatedEmailProducts}:email`;

export type CloudGraphDevInfoEntityAti =
	| CloudGraphRepositoryEntityAti
	| CloudGraphPullRequestEntityAti
	| CloudGraphCommitEntityAti
	| CloudGraphBranchEntityAti;

export type ThirdPartyEntityATI =
	| CloudGraphCommentEntityAti
	| CloudGraphConversationEntityAti
	| CloudGraphDocumentEntityAti
	| CloudGraphMessageEntityAti
	| CloudGraphDevInfoEntityAti
	| CloudGraphDesignEntityAti
	| CloudGraphVideoEntityAti
	| CloudGraphSpaceEntityAti
	| CloudGraphProjectEntityAti
	| CloudGraphCalendarEventEntityAti
	| CloudGraphWorkItemEntityAti
	| FederatedGoogleEmailEntityAti
	| FederatedMicrosoftEmailEntityAti
	| CloudGraphRemoteLinkEntityAti
	| CloudGraphDealEntityAti
	| CloudGraphCustomerOrgEntityAti;

export type ThirdPartyEntityType = GoogleEntityType | MicrosoftEntityType | SlackEntityType;

export type EntityType =
	| JiraEntityType
	| ConfluenceEntityType
	| AtlasEntityType
	| ThirdPartyEntityType;

export type EntityATI =
	| AtlasEntityATI
	| ConfluenceEntityATI
	| JiraEntityATI
	| MercuryEntityATI
	| TrelloEntityATI
	| CompassEntityATI
	| ThirdPartyEntityATI
	| CloudGraphRemoteLinkEntityAti;

export type SearchResultType =
	| 'attachment'
	| 'blogpost'
	| 'board'
	| 'branch'
	| 'calendar'
	| 'channel'
	| 'comment'
	| 'commit'
	| 'dashboard'
	| 'database'
	| 'document'
	| 'embed' // smart links
	| 'email'
	| 'filter'
	| 'focus_area'
	| 'focus_area_status_update'
	| 'folder'
	| 'gliffy'
	| 'goal'
	| 'incident'
	| 'issue'
	| 'learning'
	| 'message'
	| 'page'
	| 'plan'
	| 'presentation'
	| 'project'
	| 'pull-request'
	| 'question'
	| 'repository'
	| 'sharepoint-document'
	| 'space'
	| 'spreadsheet'
	| 'tag'
	| 'unrecognised'
	| 'whiteboard'
	| 'work-item'
	| 'card'
	| 'deal'
	| 'customer-org';

export enum SearchConfluenceSubtype {
	LIVE = 'LIVE',
}

export type SearchConfluenceRangeField = 'LASTMODIFIED' | 'CREATED';

export type SearchContainerStatus = 'ARCHIVED' | 'CURRENT';

export type SearchConfluenceDocumentStatus = 'CURRENT' | 'ARCHIVED' | 'DRAFT';

export type SearchJiraBoardProductType = 'SOFTWARE' | 'BUSINESS';

export type SearchJiraIssueProductType =
	| 'SOFTWARE'
	| 'BUSINESS'
	| 'SERVICE_DESK'
	| 'PRODUCT_DISCOVERY';

export type SearchJiraProductType = 'software' | 'business' | 'service_desk' | 'product_discovery';

export type SearchConfluenceSpaceType = 'GLOBAL' | 'PERSONAL';

export type SearchConfluenceRangeFilter = {
	/** The field to use to calculate the range */
	field: SearchConfluenceRangeField;
	/** Specify the timestamp that the field should be greater than */
	gt?: Maybe<string>;
	/** Specify the timestamp that the field should be less than */
	lt?: Maybe<string>;
};

export type SearchConfluenceFilter = {
	/** Space keys from which the results are desired. */
	spacesFilter?: Maybe<Array<string>>;
	/** AccountIds of the users. */
	contributorsFilter?: Maybe<Array<string>>;
	/** AccountIds of the users. */
	creatorsFilter?: Maybe<Array<string>>;
	/** Labels which must be present on the page or blogpost. */
	labelsFilter?: Maybe<Array<string>>;
	/** Id of the pages which must be parent of the result. */
	ancestorIdsFilter?: Maybe<Array<string>>;
	range?: Maybe<Array<Maybe<SearchConfluenceRangeFilter>>>;
	containerStatus?: Maybe<Array<Maybe<SearchContainerStatus>>>;
	/** Confluence document status */
	contentStatuses?: Maybe<Array<SearchConfluenceDocumentStatus>>;
	/** Search for only entities that have a title that contains the given query */
	titleMatchOnly?: Maybe<boolean>;
	/** Search for pages or blogposts with a specific page status */
	pageStatus?: Maybe<string>;
	/** Space or Page ARI under which the search will have to be made. Includes the space or page itself. Maps to Containers filter. */
	containerARIs?: Maybe<Array<string>>;
};

export type SearchResult = {
	id: string;
	title: string;
	description: string;
	type: SearchResultType;
	url: string;
	iconUrl?: Maybe<string>;
	__typename: string;
};

export type ExperimentLayer = {
	name?: string | null;
	/**
	 * @deprecated in favour of the layerId field within an experiment definition
	 */
	layerId: string | null;
	/**
	 * @deprecated in favour of the layerId field within an experiment definition
	 */
	shadowId: string | null;
};

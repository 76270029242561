import { type ConfluenceLabel, confluenceLabelsQuery } from '@atlassian/search-client';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('label');

export const filter = createMultiSelectFilter({
	id: 'label',
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'labels',
		fetcher: async (queryParams, _) => {
			return queryParams.map((label) => ({
				trackingKey: label,
				value: label,
				queryParamValue: label,
				label: label,
			}));
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			if (!config.intl) {
				return []; // base on existing interface, can only query contributors with intl
			}

			if (config.cloudId === undefined) {
				return [];
			}

			const initialLabelsResponse = await confluenceLabelsQuery(config.cloudId, query, 10);

			const suggestedLabels: ConfluenceLabel[] =
				initialLabelsResponse.data?.confluence.searchLabels.suggestedLabels || [];
			const otherLabels: ConfluenceLabel[] =
				initialLabelsResponse.data?.confluence.searchLabels.otherLabels || [];

			return (
				[...suggestedLabels, ...otherLabels].map((label) => ({
					trackingKey: label.label || '',
					value: label.label || '',
					queryParamValue: label.label || '',
					label: label.label || '',
				})) ?? []
			);
		},
	},
});

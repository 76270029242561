import { useEffect, useMemo, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	get3pSearchConfig,
	getSearchConfigV2,
	getUserLoomAuthStatus,
	type TSearch3pConfigProps,
	type TSearchConfigAPIResponse,
} from '@atlassian/search-client';

import { type ProductKeys3P, ThirdPartyConfigs } from '../../common/constants/3p-product-configs';
import { nouns as supportedNouns } from '../../common/constants/nouns';
import {
	allProductKeys,
	is3pProductKey,
	type PrimaryProductKey,
	PrimaryProductKeys,
	ProductKeys,
	useProductConfigs,
} from '../../common/constants/products';
import {
	type ConnectorSource,
	FEDERATED_CONNECTOR_SOURCE,
	FULL_CONNECTOR_SOURCE,
	SMARTLINKS_CONNECTOR_SOURCE,
	type ThirdPartyConfigsBootstrap,
	type ThirdPartyConfigsWithAPIResponse,
} from '../../common/constants/schemas/3p-config';
import { DEFAULT_BULK_CONNECT_BANNER_STATE } from '../../common/utils/bulk-connect-banner';
import { onDegradedDependency } from '../../common/utils/events/degraded-dependency';
import { useAnalytics } from '../analytics';
import { isProductKeyWithTimestampMap, type ProductKeyWithTimestampMap } from '../bulk-connect';
import { useLogException } from '../error-boundary';
import { useSearchSessionId } from '../store';
import { useFeatureFlaggedLocalStorageCallback } from '../store/external-storage';

const EXTRACT_PRODUCT = /ari:cloud:(.*)?::/;
const EXTRACT_CLOUD_ID = /ari:cloud:.*?\/(.*)/;
const BITBUCKET_WORKSPACE_ARI = 'ari:cloud:bitbucket::workspace';

export type UseFetchConfigProps = {
	thirdPartyAbsoluteUrl?: string;
	thirdPartyDisabled?: boolean;
	userId?: string | null;
	cloudId?: string;
	aggAbsoluteUrl?: string;
	primaryProduct?: PrimaryProductKey;
	/**
	 * When passed this would be used as is to invoke the endpoint which returns search-configuration i.e. list of products accessible by the logged in user.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 *
	 * No need to pass if the UI is being bootstrapped inside Jira, Confluence or any product tied to these sites.
	 */
	configFetchAbsoluteUrl?: string;
	aggregatorHostName?: string;
};

const getFirstCloudId = (firstPartyResponse: TSearchConfigAPIResponse) => {
	const match = firstPartyResponse.resources[0]?.id.match(EXTRACT_CLOUD_ID);
	const extractedCloudId = match ? match[1] : '';

	return extractedCloudId;
};

const isNounsAvailable = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isNounsAvailable;
};

const isRovoEnabled = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isRovoEnabled;
};

const getProductEdition = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return undefined;
	}

	return firstPartyResponse.siteMetadata.find(
		(metadata) => metadata.siteId === cloudId && !!metadata.productEdition,
	)?.productEdition;
};

const getProductDataRegions = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return undefined;
	}

	return firstPartyResponse.siteMetadata.find(
		(metadata) => metadata.siteId === cloudId && !!metadata.productDataRegions,
	)?.productDataRegions;
};

const getRovoFeatures = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return undefined;
	}

	return firstPartyResponse.siteMetadata.find(
		(metadata) => metadata.siteId === cloudId && !!metadata.rovoFeatures,
	)?.rovoFeatures;
};

/**
 * A React hook which handles fetching of all the config necessary to bootstrap 1P + 3P results.
 */
export const useFetchConfig = ({
	cloudId: cloudIdProp,
	configFetchAbsoluteUrl,
	aggregatorHostName,
	userId,
	thirdPartyAbsoluteUrl,
	thirdPartyDisabled,
	primaryProduct,
}: UseFetchConfigProps) => {
	const supportedProducts = useProductConfigs();
	const [firstPartyResponse, setFirstPartyResponse] = useState<TSearchConfigAPIResponse>();
	const [isUserLoomAuthed, setIsUserLoomAuthed] = useState<boolean>(false);
	const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);

	const [accessibleProductsConfig, setAccessibleProductsConfig] = useState<{
		products?: ProductKeys[];
		nouns: string[];
		thirdPartyConfigs?: ThirdPartyConfigsBootstrap;
	}>({
		nouns: [],
	});
	const { fireAnalyticsEvent } = useAnalytics();
	const [searchSessionId] = useSearchSessionId();

	const logException = useLogException();

	const firstCloudId = useMemo(() => {
		if (!cloudIdProp && firstPartyResponse) {
			return getFirstCloudId(firstPartyResponse);
		}
		return '';
	}, [firstPartyResponse, cloudIdProp]);

	const thirdPartyResponse = useThirdPartyConfig({
		cloudId: cloudIdProp || firstCloudId || '',
		userId: userId,
		absoluteUrl: thirdPartyAbsoluteUrl,
		disabled: thirdPartyDisabled,
	});

	const useLocalStorage = useFeatureFlaggedLocalStorageCallback();
	const [localStorageState, setLocalStorageState] = useLocalStorage();

	useEffect(() => {
		//this endpoint only works in confluence and atlas, show loom to other products for now
		if (!isUserLoomAuthed) {
			if (
				primaryProduct === PrimaryProductKeys.Confluence ||
				primaryProduct === PrimaryProductKeys.Atlas
			) {
				getUserLoomAuthStatus({
					userId,
					cloudId: cloudIdProp,
				})
					.then((response) => setIsUserLoomAuthed(response?.status === 'mastered'))
					.catch((error) => {
						logException(error);

						setIsUserLoomAuthed(false);
					});
			} else {
				setIsUserLoomAuthed(true);
			}
		}
	}, [isUserLoomAuthed, setIsUserLoomAuthed, userId, logException, cloudIdProp, primaryProduct]);

	useEffect(() => {
		if (!firstPartyResponse) {
			const degradationCallback = () => {
				fireAnalyticsEvent(
					onDegradedDependency({
						dependency: 'search-config',
						searchSessionId,
					}),
				);
			};

			const searchConfigParams = {
				cloudId: cloudIdProp,
				absoluteUrl: configFetchAbsoluteUrl,
				hostName: aggregatorHostName,
				userId,
				degradationCallback,
			};

			getSearchConfigV2(searchConfigParams)
				.then((response) => {
					setFirstPartyResponse(response);
				})
				.catch((error) => {
					logException(error);

					setFirstPartyResponse({
						resources: [],
						intercomHmac: '',
						siteMetadata: [],
					});
				});
		}
	}, [
		firstPartyResponse,
		setFirstPartyResponse,
		cloudIdProp,
		configFetchAbsoluteUrl,
		aggregatorHostName,
		userId,
		logException,
		fireAnalyticsEvent,
		searchSessionId,
	]);

	useEffect(() => {
		if (
			thirdPartyResponse.productKeys &&
			firstPartyResponse &&
			!accessibleProductsConfig.products
		) {
			let allAccessibleProducts: ProductKeys[] = [];

			const firstPartyProducts = [
				...new Set(
					firstPartyResponse.resources.flatMap((resource) => {
						const { id } = resource;
						const match = id.match(EXTRACT_PRODUCT);

						if (match && match.length >= 2 && match[1] in supportedProducts) {
							return [match[1]];
						}

						return [];
					}),
				),
			];

			if (firstPartyProducts.length === 0 && primaryProduct) {
				// Use the primary product if the 1P Search Config request fails
				allAccessibleProducts.push(primaryProduct as ProductKeys);
			} else {
				allAccessibleProducts.push(...(firstPartyProducts as ProductKeys[]));
			}

			// We are currently migrating Goal and Project nouns from townsquare (Atlas) into their own separate Products
			// within Identity. As part of this migration we are decommissioning the isNounsAvailable flag on siteMetadata
			// and moving the nouns into the first party product list.
			//
			// If a site has isNounsAvailable set to false and the feature gate is set then we want to return the available
			// nouns from the firstPartyResponse. If isNounsAvailable is true then the site hasn't been migrated yet and
			// should continue with the old behaviour.
			const areNounsEnabledThroughLegacyFlag = isNounsAvailable(firstPartyResponse, cloudIdProp);
			const useProductAppNouns =
				!areNounsEnabledThroughLegacyFlag && fg('enable_identity_powered_nouns');

			const accessibleNouns = useProductAppNouns
				? firstPartyResponse.resources.flatMap((resource) => {
						const { id } = resource;
						const match = id.match(EXTRACT_PRODUCT);

						if (match && match.length >= 2 && match[1] in supportedNouns) {
							return [match[1]];
						}

						return [];
					})
				: [];

			// If nouns are available we also need to add Townsquare to the list of accessible products.
			// This way Projects will be fetched by default when no filters are selected.
			if (accessibleNouns.length > 0 && useProductAppNouns) {
				allAccessibleProducts.push(ProductKeys.Atlas);
			}

			// Remove Bitbucket from the list of resources if:
			// * The user doesn't have access to the Bitbucket product
			// * There are no bitbucket workspaces
			// See https://hello.atlassian.net/wiki/x/1hI6CQE for details
			if (
				allAccessibleProducts.includes('bitbucket') &&
				!firstPartyResponse.resources.find((r) => r.id.includes(BITBUCKET_WORKSPACE_ARI))
			) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Bitbucket,
				);
			}

			// user may have loom entitlement but not the correct auth status
			if (!isUserLoomAuthed) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Loom,
				);
			}

			// Allow 3P products only if a user has Rovo enabled.
			// Long-term it won't be needed if the 3p-configuration check ensures Rovo eligibility.
			// See https://hello.atlassian.net/wiki/spaces/SEARCH/pages/4469899124.
			const doesUserHaveRovo = isRovoEnabled(firstPartyResponse, cloudIdProp);
			if (doesUserHaveRovo) {
				const thirdPartyProductKeys = thirdPartyResponse.productKeys || [];
				allAccessibleProducts.push(...(thirdPartyProductKeys as ProductKeys[]));
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					nouns: accessibleNouns,
					thirdPartyConfigs: thirdPartyResponse.thirdPartyConfigs,
				});
				if (fg('3p_bulk_connect')) {
					const currentTime = Date.now().toString();
					let hasNewProducts = false;

					const newState = {
						...localStorageState,
						previous3pProducts: {} as ProductKeyWithTimestampMap,
					};
					if (!localStorageState || !localStorageState.previous3pProducts) {
						// Local storage does not store this information yet. We assume this is the first time a user is seeing these products, and store all products with current timestamp
						newState.previous3pProducts = thirdPartyProductKeys.reduce(
							(acc, product) => ({
								...acc,
								[product]: currentTime,
							}),
							{} as ProductKeyWithTimestampMap,
						);
						hasNewProducts = true;
					} else {
						// Compare with previous state and store newly visible products with current timestamp, while keeping the previous timestamps for existing products
						const previous3pProducts: ProductKeyWithTimestampMap = isProductKeyWithTimestampMap(
							localStorageState.previous3pProducts,
						)
							? localStorageState.previous3pProducts
							: {};

						thirdPartyProductKeys.forEach((product) => {
							if (!is3pProductKey(product)) {
								return;
							}
							if (!previous3pProducts[product]) {
								newState.previous3pProducts[product] = currentTime;
								hasNewProducts = true;
							} else {
								newState.previous3pProducts[product] = previous3pProducts[product];
							}
						});
					}
					if (hasNewProducts) {
						// if there are new products, we need to reset the secondary banner dismissal state
						newState.bulkConnectBanner = {
							...(localStorageState?.bulkConnectBanner || DEFAULT_BULK_CONNECT_BANNER_STATE),
							secondaryBannerDismissed: false,
						};
					}

					setLocalStorageState(newState);
				}
			} else {
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					nouns: accessibleNouns,
					thirdPartyConfigs: {},
				});
			}
			setIsConfigLoaded(true);
		}
	}, [
		isUserLoomAuthed,
		thirdPartyResponse,
		firstPartyResponse,
		setAccessibleProductsConfig,
		primaryProduct,
		fireAnalyticsEvent,
		logException,
		cloudIdProp,
		accessibleProductsConfig.products,
		supportedProducts,
		localStorageState,
		setLocalStorageState,
	]);

	return {
		accessibleProducts: accessibleProductsConfig.products,
		accessibleNouns: accessibleProductsConfig.nouns,
		thirdPartyConfigs: accessibleProductsConfig.thirdPartyConfigs,
		firstCloudId,
		intercomHmac: firstPartyResponse?.intercomHmac,
		isNounsAvailable: isNounsAvailable(firstPartyResponse, cloudIdProp),
		isRovoEnabled: isRovoEnabled(firstPartyResponse, cloudIdProp),
		productEdition: getProductEdition(firstPartyResponse, cloudIdProp),
		productDataRegions: getProductDataRegions(firstPartyResponse, cloudIdProp),
		rovoFeatures: getRovoFeatures(firstPartyResponse, cloudIdProp),
		isConfigLoaded,
	};
};

export const getThirdPartyTypes = (): Record<string, string> => {
	// old sharepoint product key is put here (kept for backwards compatibility), as the new one is in the config
	const oldThirdPartyTypes: Record<string, string> = {
		'ai-3p-connector:sharepoint-domain': `sharepoint`,
		'ai-3p-connector:google-workspace': `drive`,
	};

	const thirdPartyTypes: Record<string, string> = {
		...oldThirdPartyTypes,
		// add thrid party types  for dual connectors
		'smartlinks-connector-gdrive': `drive`,
		'smartlinks-connector-salesforce': `salesforce`,
	};

	ThirdPartyConfigs.forEach((config) => {
		if (config.resourceType) {
			thirdPartyTypes[config.resourceType] = config.id;
		}
	});

	return thirdPartyTypes;
};

type ConnectorSourceMap = Map<string, Set<ConnectorSource>>;

export const get3pProductKeys = async ({
	cloudId,
	absoluteUrl,
	userId,
	degradationCallback,
}: TSearch3pConfigProps) => {
	const THIRD_PARTY_TYPES: Record<string, string> = getThirdPartyTypes();

	const resources = await get3pSearchConfig({
		cloudId,
		absoluteUrl,
		userId,
		degradationCallback,
	});

	// since we aren't getting anything back from connected-data-sources, we hardcode the connectors here
	if (fg('forge-connector-demo')) {
		resources.push({
			type: 'aws-s3-connector',
			providerId: '',
			outboundAuthUrl: '',
			isUserOAuthed: false,
			workspaceName: '',
		});

		// This is done for Pio
		const isProdProvider = fg('forge-team25-gitbook-prod-search-provider');
		resources.push({
			type: 'gitbook-connector',
			providerId: isProdProvider
				? 'dzfCxy061BNL8sEFZdIM9xXXkyL49RPb'
				: 'HBV0Q5GB1FgcIjYXt9jadwXSDjStqaMv',
			outboundAuthUrl: '',
			isUserOAuthed: true,
			workspaceName: '',
		});
	}

	// Use a map to track connector sources for each providerId
	// This is useful for deduping search results for providers that are hybrid smartlink and full connectors
	const connectorSourceMap: ConnectorSourceMap = new Map();

	let productKeys = [] as ProductKeys3P[];
	let thirdPartyConfigsWithAPIResponse: ThirdPartyConfigsBootstrap = {};

	resources.forEach((resource) => {
		const productKey = THIRD_PARTY_TYPES[resource.type] || '';
		const { providerId } = resource;

		if (productKey) {
			productKeys.push(productKey as ProductKeys3P);
			let config = {} as ThirdPartyConfigsWithAPIResponse;
			const staticConfig = ThirdPartyConfigs.get(productKey as ProductKeys3P);
			if (staticConfig) {
				const sourceType = staticConfig.isFederated
					? FEDERATED_CONNECTOR_SOURCE
					: resource.type.startsWith(SMARTLINKS_CONNECTOR_SOURCE)
						? SMARTLINKS_CONNECTOR_SOURCE
						: FULL_CONNECTOR_SOURCE;

				connectorSourceMap.set(
					providerId,
					(connectorSourceMap.get(providerId) || new Set()).add(sourceType),
				);

				config = {
					...staticConfig,
					oAuthOutboundUrl: resource.outboundAuthUrl,
					userNeedsOAuth: !resource.isUserOAuthed,
					providerId,
					workspaceName: resource.workspaceName,
					...(fg('ai3w_smartlink_ui_support')
						? { connectorSources: Array.from(connectorSourceMap.get(providerId) || []) }
						: {}),
				};
				thirdPartyConfigsWithAPIResponse[productKey as ProductKeys3P] = config;
			}
		}
	});

	productKeys = productKeys.filter((productKey) => allProductKeys.includes(productKey));

	return { productKeys, thirdPartyConfigs: thirdPartyConfigsWithAPIResponse };
};

const EMPTY_RESPONSE: string[] = [];

export const useThirdPartyConfig = ({
	cloudId,
	absoluteUrl,
	userId,
	disabled,
}: Partial<TSearch3pConfigProps> = {}) => {
	const [config, setConfig] = useState<{
		productKeys?: string[];
		thirdPartyConfigs?: ThirdPartyConfigsBootstrap;
	}>({});
	const logException = useLogException();
	const { fireAnalyticsEvent } = useAnalytics();
	const [searchSessionId] = useSearchSessionId();

	useEffect(() => {
		if (!config.productKeys) {
			if (!disabled && cloudId) {
				const degradationCallback = () => {
					fireAnalyticsEvent(
						onDegradedDependency({
							dependency: 'search-3p-config',
							searchSessionId,
						}),
					);
				};

				get3pProductKeys({ cloudId, absoluteUrl, userId, degradationCallback })
					.then((data) => {
						setConfig({
							productKeys: data.productKeys,
							thirdPartyConfigs: data.thirdPartyConfigs,
						});
					})
					.catch((error) => {
						setConfig({
							productKeys: EMPTY_RESPONSE,
							thirdPartyConfigs: {},
						});

						logException(error);
					});
			} else {
				setConfig({
					productKeys: EMPTY_RESPONSE,
					thirdPartyConfigs: {},
				});
			}
		}
	}, [
		cloudId,
		absoluteUrl,
		userId,
		disabled,
		config,
		logException,
		fireAnalyticsEvent,
		searchSessionId,
	]);

	return config;
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SlackIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-603.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="white"
			/>
			<path
				d="M0.385264 6C0.385264 2.89907 2.89907 0.385264 6 0.385264H18C21.1009 0.385264 23.6147 2.89907 23.6147 6V18C23.6147 21.1009 21.1009 23.6147 18 23.6147H6C2.89907 23.6147 0.385264 21.1009 0.385264 18V6Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.770528"
			/>
			<g>
				<path
					d="M7.71235 13.9487C7.71235 14.8109 7.0155 15.5078 6.15329 15.5078C5.29109 15.5078 4.59424 14.8109 4.59424 13.9487C4.59424 13.0865 5.29109 12.3896 6.15329 12.3896H7.71235V13.9487ZM8.49188 13.9487C8.49188 13.0865 9.18873 12.3896 10.0509 12.3896C10.9131 12.3896 11.61 13.0865 11.61 13.9487V17.8463C11.61 18.7085 10.9131 19.4054 10.0509 19.4054C9.18873 19.4054 8.49188 18.7085 8.49188 17.8463V13.9487Z"
					fill="#E01E5A"
				/>
				<path
					d="M10.051 7.68879C9.18882 7.68879 8.49197 6.99194 8.49197 6.12973C8.49197 5.26753 9.18882 4.57068 10.051 4.57068C10.9132 4.57068 11.6101 5.26753 11.6101 6.12973V7.68879H10.051ZM10.051 8.48013C10.9132 8.48013 11.6101 9.17698 11.6101 10.0392C11.6101 10.9014 10.9132 11.5982 10.051 11.5982H6.14157C5.27937 11.5982 4.58252 10.9014 4.58252 10.0392C4.58252 9.17698 5.27937 8.48013 6.14157 8.48013H10.051Z"
					fill="#36C5F0"
				/>
				<path
					d="M16.299 10.0392C16.299 9.17698 16.9959 8.48013 17.8581 8.48013C18.7203 8.48013 19.4171 9.17698 19.4171 10.0392C19.4171 10.9014 18.7203 11.5982 17.8581 11.5982H16.299V10.0392ZM15.5195 10.0392C15.5195 10.9014 14.8226 11.5982 13.9604 11.5982C13.0982 11.5982 12.4014 10.9014 12.4014 10.0392V6.12973C12.4014 5.26753 13.0982 4.57068 13.9604 4.57068C14.8226 4.57068 15.5195 5.26753 15.5195 6.12973V10.0392Z"
					fill="#2EB67D"
				/>
				<path
					d="M13.9604 16.2873C14.8226 16.2873 15.5195 16.9841 15.5195 17.8463C15.5195 18.7085 14.8226 19.4054 13.9604 19.4054C13.0982 19.4054 12.4014 18.7085 12.4014 17.8463V16.2873H13.9604ZM13.9604 15.5078C13.0982 15.5078 12.4014 14.8109 12.4014 13.9487C12.4014 13.0865 13.0982 12.3896 13.9604 12.3896H17.8699C18.7321 12.3896 19.4289 13.0865 19.4289 13.9487C19.4289 14.8109 18.7321 15.5078 17.8699 15.5078H13.9604Z"
					fill="#ECB22E"
				/>
			</g>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_271_181639)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.33321 2C8.22767 2.00082 7.33294 2.89621 7.33375 3.99954C7.33294 5.10288 8.22849 5.99827 9.33403 5.99909H11.3343V4.00036C11.3351 2.89702 10.4396 2.00163 9.33321 2C9.33403 2 9.33403 2 9.33321 2V2ZM9.33321 7.33321H4.00077C2.89522 7.33402 1.99967 8.22941 2.00049 9.33275C1.99886 10.4361 2.89441 11.3315 3.99995 11.3331H9.33321C10.4388 11.3323 11.3343 10.4369 11.3335 9.33357C11.3343 8.22941 10.4388 7.33402 9.33321 7.33321V7.33321Z"
					fill="#36C5F0"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.9998 9.33275C22.0006 8.22941 21.1051 7.33402 19.9995 7.33321C18.894 7.33402 17.9984 8.22941 17.9992 9.33275V11.3331H19.9995C21.1051 11.3323 22.0006 10.4369 21.9998 9.33275ZM16.6665 9.33275V3.99954C16.6673 2.89702 15.7726 2.00163 14.667 2C13.5615 2.00082 12.6659 2.89621 12.6668 3.99954V9.33275C12.6651 10.4361 13.5607 11.3315 14.6662 11.3331C15.7718 11.3323 16.6673 10.4369 16.6665 9.33275Z"
					fill="#2EB67D"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.6665 21.9995C15.7721 21.9987 16.6677 21.1033 16.6668 19.9999C16.6677 18.8966 15.7721 18.0012 14.6665 18.0004H12.6663V19.9999C12.6654 21.1025 13.561 21.9979 14.6665 21.9995ZM14.6665 16.6654H19.9998C21.1054 16.6646 22.0009 15.7692 22.0001 14.6659C22.0018 13.5626 21.1062 12.6672 20.0006 12.6655H14.6674C13.5618 12.6663 12.6663 13.5617 12.6671 14.6651C12.6663 15.7692 13.561 16.6646 14.6665 16.6654V16.6654Z"
					fill="#ECB22E"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.00073 14.6663C1.99992 15.7696 2.89547 16.665 4.00102 16.6659C5.10657 16.665 6.00212 15.7696 6.0013 14.6663V12.6667H4.00102C2.89547 12.6676 1.99992 13.563 2.00073 14.6663ZM7.33401 14.6663V19.9995C7.33237 21.1029 8.22792 21.9983 9.33347 21.9999C10.439 21.9991 11.3346 21.1037 11.3338 20.0003V14.6679C11.3354 13.5646 10.4398 12.6692 9.33429 12.6676C8.22792 12.6676 7.33319 13.563 7.33401 14.6663C7.33401 14.6671 7.33401 14.6663 7.33401 14.6663Z"
					fill="#E01E5A"
				/>
			</g>
			<defs>
				<clipPath id="clip0_271_181639">
					<rect width="20" height="20" fill="white" transform="translate(2 2)" />
				</clipPath>
			</defs>
		</svg>
	);
};

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const WebexIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-554.5"
				y="-363.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<mask
				id="mask0_719_13847"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ maskType: 'luminance' }}
				maskUnits="userSpaceOnUse"
				x="3"
				y="6"
				width="18"
				height="13"
			>
				<path d="M20.5 6.177H3.5V18.551H20.5V6.177Z" fill="white" />
			</mask>
			<g mask="url(#mask0_719_13847)">
				<path
					d="M8.77473 18.5019C5.62694 18.5019 4.55554 14.5209 4.31548 13.7465C3.71261 11.8018 3.52935 10.05 3.50994 9.86618C3.39799 8.80539 4.24877 8.06718 5.18252 8.06718C5.8895 8.06718 6.71005 8.49041 6.81877 9.51726C6.83761 9.68995 6.98136 11.1795 7.48984 12.8026C8.02049 14.4964 8.58341 15.1479 8.98879 15.1479C9.64347 15.1479 10.0429 13.4664 10.396 11.8653C10.6562 10.6857 10.9374 9.49712 11.4926 8.44128C12.4585 6.60445 13.9181 6.177 15.1791 6.177C17.944 6.177 19.3278 9.38049 19.3278 10.2105C19.3278 11.5975 18.1965 11.9513 17.7062 11.9513C16.6338 11.9513 16.2703 11.2327 15.9643 10.6029C15.689 10.0363 15.4016 9.56515 15.0299 9.56515C14.9078 9.56515 14.7946 9.62698 14.69 9.73341C13.7662 10.6723 13.4565 15.584 11.6095 17.4612C10.5856 18.5019 9.47152 18.5019 8.77473 18.5019Z"
					fill="#00BBFF"
				/>
				<path
					d="M18.8181 8.06718C18.6934 8.06718 18.5681 8.07894 18.4451 8.1032C19.0296 8.94596 19.3221 9.82891 19.3221 10.2104C19.3221 11.5975 18.1907 11.9512 17.7004 11.9512C17.3178 11.9512 17.0254 11.8597 16.7937 11.7134C16.7916 11.7221 16.7895 11.7307 16.7873 11.7394C16.7184 12.0147 16.664 12.3159 16.5698 12.6092C16.3888 13.1729 16.1446 13.8168 15.8839 14.3151C15.6028 14.8523 15.3246 15.1666 14.9929 15.1501C14.6157 15.1314 14.3099 14.6452 13.9298 13.2711C13.8072 12.8281 13.6937 12.3423 13.5885 11.8653C13.3276 10.6822 13.0074 9.49254 12.4598 8.44128C11.5653 6.72426 10.0396 6.177 8.82156 6.177C7.69192 6.177 6.84795 6.71182 6.18495 7.3857C6.00175 7.57192 5.80158 7.82283 5.60059 8.11766C6.17886 8.25694 6.72565 8.69224 6.81293 9.51716C6.83015 9.67444 6.95085 10.9237 7.35628 12.3714C7.4726 12.0084 7.64222 11.4709 8.03625 10.6029C8.22939 10.2054 8.40369 9.94325 8.56092 9.78348C8.70514 9.63686 8.83499 9.57634 8.95193 9.57634C9.07804 9.57634 9.50861 9.69177 9.92778 11.1628C10.4477 12.9869 10.8346 15.8898 12.3086 17.4312C12.9587 18.1111 13.8685 18.551 15.1294 18.551C16.2358 18.551 17.1582 18.0637 17.8159 17.3859C18.8973 16.2712 19.4898 14.4284 19.7011 13.7465C20.3026 11.8064 20.4711 10.0518 20.4907 9.86618C20.6026 8.80539 19.709 8.06718 18.8181 8.06718Z"
					fill="#316AFF"
				/>
			</g>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_647_117" maskUnits="userSpaceOnUse" x="4" y="6" width="16" height="12">
				<path d="M20 6H4V17.6461H20V6Z" fill="white" />
			</mask>
			<g mask="url(#mask0_647_117)">
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint0_linear_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint1_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint2_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint3_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint4_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint5_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint6_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint7_linear_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint8_radial_647_117)"
				/>
				<path
					d="M14.9922 6C13.8054 6 12.4317 6.4023 11.5225 8.13109C11 9.12482 10.7353 10.2435 10.4905 11.3537C10.4099 11.719 10.3267 12.0888 10.2382 12.4394V17.4624C10.6873 17.3353 11.1697 17.0908 11.6326 16.6205C13.3709 14.8536 13.6625 10.2308 14.5319 9.34721C14.6303 9.24703 14.7369 9.18884 14.8518 9.18884C15.2016 9.18884 15.4722 9.63224 15.7312 10.1655C16.0192 10.7583 16.3613 11.4346 17.3707 11.4346C17.8322 11.4346 18.8969 11.1017 18.8969 9.79624C18.8969 9.01505 17.5945 6 14.9922 6Z"
					fill="url(#paint9_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint10_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint11_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint12_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint13_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14128 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint14_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="#316AFF"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint15_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint16_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint17_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint18_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint19_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint20_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint21_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint22_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint23_radial_647_117)"
				/>
				<path
					d="M18.4171 7.77899C18.2997 7.77899 18.1817 7.79006 18.066 7.81289C18.6161 8.60608 18.8914 9.43709 18.8914 9.79614C18.8914 11.1016 17.8266 11.4346 17.3651 11.4346C17.005 11.4346 16.7299 11.3484 16.5118 11.2107C16.5097 11.2189 16.5078 11.227 16.5057 11.2352C16.4409 11.4943 16.3897 11.7778 16.3011 12.0538C16.1307 12.5843 15.9009 13.1904 15.6555 13.6594C15.3909 14.165 15.129 14.4608 14.8169 14.4453C14.4619 14.4277 14.174 13.97 13.8163 12.6768C13.701 12.2598 13.5941 11.8026 13.4951 11.3537C13.2496 10.2402 12.9481 9.12051 12.4328 8.13109C11.5909 6.51506 10.155 6 9.00856 6C7.94536 6 7.15104 6.50336 6.52704 7.1376C6.35462 7.31286 6.16623 7.54901 5.97705 7.82651C6.52131 7.95759 7.03593 8.36729 7.11808 9.14368C7.13429 9.29171 7.24788 10.4675 7.62947 11.83C7.73895 11.4884 7.89859 10.9825 8.26944 10.1655C8.45122 9.79144 8.61527 9.54471 8.76325 9.39433C8.89898 9.25634 9.02119 9.19937 9.13126 9.19937C9.24995 9.19937 9.65519 9.30802 10.0497 10.6925C10.539 12.4093 10.9031 15.1415 12.2905 16.5921C12.9023 17.2321 13.7586 17.6461 14.9454 17.6461C15.9867 17.6461 16.8548 17.1874 17.4738 16.5495C18.4916 15.5004 19.0492 13.766 19.2482 13.1242C19.8142 11.2983 19.9728 9.64689 19.9913 9.47216C20.0966 8.47378 19.2555 7.77899 18.4171 7.77899Z"
					fill="url(#paint24_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14133 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint25_linear_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14133 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint26_radial_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14133 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint27_linear_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14133 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint28_linear_647_117)"
				/>
				<path
					d="M8.96446 17.5999C6.00182 17.5999 4.99345 13.8531 4.76751 13.1242C4.20011 11.2939 4.02762 9.64517 4.00936 9.47216C3.90399 8.47378 4.70473 7.77899 5.58355 7.77899C6.24894 7.77899 7.02122 8.17733 7.12355 9.14378C7.14133 9.3063 7.27657 10.7083 7.75515 12.2358C8.25458 13.8301 8.78439 14.4432 9.16593 14.4432C9.78209 14.4432 10.158 12.8606 10.4903 11.3537C10.7352 10.2435 10.9999 9.12482 11.5224 8.13109C12.4315 6.4023 13.8053 6 14.9921 6C17.5944 6 18.8968 9.01505 18.8968 9.79624C18.8968 11.1017 17.832 11.4346 17.3706 11.4346C16.3612 11.4346 16.0191 10.7583 15.7311 10.1655C15.472 9.63224 15.2015 9.18884 14.8517 9.18884C14.7368 9.18884 14.6303 9.24703 14.5318 9.34721C13.6623 10.2308 13.3708 14.8536 11.6325 16.6205C10.6688 17.5999 9.62025 17.5999 8.96446 17.5999Z"
					fill="url(#paint29_radial_647_117)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_647_117"
					x1="17.2757"
					y1="9.40344"
					x2="6.80986"
					y2="12.8357"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5CEE64" />
					<stop offset="1" stopColor="#00BBFF" />
				</linearGradient>
				<radialGradient
					id="paint1_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.09 12.186) rotate(-120.86) scale(6.85625 5.75574)"
				>
					<stop stopColor="#007383" />
					<stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(14.5948 10.9103) rotate(-126.734) scale(5.59154 3.67393)"
				>
					<stop stopColor="#218970" />
					<stop offset="0.459527" stopColor="#267E6A" />
					<stop offset="1" stopColor="#026C51" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.6952 12.5099) rotate(-107.468) scale(4.554 2.37251)"
				>
					<stop stopColor="#178697" />
					<stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint4_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(11.7199 12.4856) rotate(-120.86) scale(11.5648 3.56733)"
				>
					<stop stopColor="#25342F" stopOpacity="0.3" />
					<stop offset="1" stopColor="#25342F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint5_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.7828 11.0371) rotate(85.0979) scale(4.35562 2.47045)"
				>
					<stop stopColor="#056D4F" />
					<stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
					<stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
					<stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint6_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.9754 3.99288) rotate(57.0774) scale(5.12012 2.6855)"
				>
					<stop stopColor="#22B67B" />
					<stop offset="0.407867" stopColor="#24BE82" />
					<stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
				</radialGradient>
				<linearGradient
					id="paint7_linear_647_117"
					x1="0.0633092"
					y1="11.6988"
					x2="7.4802"
					y2="11.7598"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint8_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1.65669 5.0183) rotate(49.6506) scale(6.68716 6.67046)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint9_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.01337 1.6515) rotate(48.0118) scale(13.5629 13.5415)"
				>
					<stop stopColor="#00B8FF" />
					<stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint10_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.73139 11.8243) rotate(-107.468) scale(6.53747 4.52974)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint11_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(17.6701 9.34133) rotate(-129.551) scale(4.62291 3.23649)"
				>
					<stop stopColor="#5FEC50" />
					<stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint12_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(17.0084 12.3189) rotate(-79.0944) scale(1.39957 0.968007)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint13_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(16.941 12.2159) rotate(-77.8798) scale(1.15379 1.61246)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint14_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(18.3155 10.1373) rotate(-132.79) scale(3.82739 2.68878)"
				>
					<stop stopColor="#5FF05B" />
					<stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint15_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(17.1186 11.2367) rotate(37.4559) scale(3.40381 4.73123)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint16_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(16.2718 11.6203) rotate(8.48611) scale(4.01618 5.99473)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint17_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(16.5977 12.1185) rotate(24.6631) scale(3.43068 6.11312)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint18_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(16.1948 12.3217) rotate(95.8028) scale(6.16725 3.37972)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
					<stop offset="1" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint19_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(17.1186 9.59893) rotate(93.6671) scale(7.42211 2.64877)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint20_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.70726 11.1422) rotate(84.8296) scale(3.93849 2.08658)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint21_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.70442 11.2481) rotate(87.2504) scale(4.11044 3.39354)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint22_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.63961 15.1104) rotate(-23.1725) scale(4.58388 8.95991)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint23_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.00865 10.2431) rotate(-159.526) scale(3.03689 5.19997)"
				>
					<stop stopColor="#1A3DA0" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint24_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(5.33541 11.2767) rotate(16.749) scale(5.79218 5.84719)"
				>
					<stop stopColor="#1E3882" />
					<stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint25_linear_647_117"
					x1="3.51831"
					y1="8.07299"
					x2="9.22598"
					y2="11.7159"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.229329" stopColor="#1DC4FF" />
					<stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint26_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1.65635 5.01803) rotate(49.6506) scale(6.68716 6.67046)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint27_linear_647_117"
					x1="3.8338"
					y1="8.25908"
					x2="5.43479"
					y2="9.71047"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_647_117"
					x1="3.40845"
					y1="9.36998"
					x2="5.62288"
					y2="9.52796"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint29_radial_647_117"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.73119 11.8243) rotate(-107.468) scale(6.53747 4.52974)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};

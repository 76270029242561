import { EVENT_SOURCE } from '../../constants/analytics';
import type { FireAnalyticsEvent } from '../../types';

type DegradableDependency = 'search-config' | `search-3p-config`;

type onDegradedDependencyArgs = {
	dependency: DegradableDependency;
	searchSessionId: string;
};

export const onDegradedDependency = ({
	dependency,
	searchSessionId,
}: onDegradedDependencyArgs): FireAnalyticsEvent => {
	return {
		source: EVENT_SOURCE,
		action: 'degraded',
		actionSubject: 'searchPage',
		eventType: 'track',
		attributes: {
			additionalAttributes: {
				degradedDependency: dependency,
				searchSessionId,
			},
		},
	};
};

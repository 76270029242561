import React from 'react';

import { useFlags } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/core/migration/check-mark--check';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { auth } from '@atlaskit/outbound-auth-flow-client';
import { G300, R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	generate3pHash,
	SEARCH_3P_CONFIG,
	searchPageQueryCache,
	sendSlackAuthSuccessRequest,
} from '@atlassian/search-client';

import { NounKeys } from '../../common/constants/nouns';
import {
	is3pProductKey,
	type ProductKeys,
	type ProductKeys3P,
} from '../../common/constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../common/constants/schemas/3p-config';
import { useAppContext } from '../../common/ui/app-context';
import { useFireOAuthCompleted, useFireOAuthStarted } from '../events';
import { useBootstrap, useSearchActions } from '../store';
import { useQuickFindActions } from '../store/quick-find';

interface OAuthHandlersProps {
	product: ProductKeys | NounKeys | undefined;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	onFailAction?: () => void;
	isOnSearchDialog?: boolean;
	isBulkConnect?: boolean;
	query?: string;
}

const refreshOAuthCache = (
	product: ProductKeys,
	userId: string,
	cloudId: string,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
): ThirdPartyConfigsBootstrap => {
	const key = generate3pHash(userId, cloudId);
	const newThirdPartyConfigs = { ...thirdPartyConfigs };

	const item = localStorage.getItem(SEARCH_3P_CONFIG);
	if (!item) {
		return newThirdPartyConfigs;
	}
	let object;
	try {
		object = JSON.parse(item);
	} catch (_) {
		return newThirdPartyConfigs;
	}
	const thirdPartyConfigCache = object?.[key]?.result?.find(
		(config: any) =>
			config.id === product ||
			/// can be removed after statis config is rolled out
			config.type.includes(product) ||
			(config.integrationId.includes('google') && product === 'drive'),
	);
	if (!thirdPartyConfigCache) {
		return newThirdPartyConfigs;
	}
	thirdPartyConfigCache.isUserOAuthed = true;
	const updatedConfig = newThirdPartyConfigs[product as ProductKeys3P];
	if (updatedConfig) {
		updatedConfig.userNeedsOAuth = false;
	}
	localStorage.setItem(SEARCH_3P_CONFIG, JSON.stringify(object));
	return newThirdPartyConfigs;
};

export const useOAuthHandlers = ({
	product,
	successFlagTitle,
	failFlagTitle,
	outboundAuthUrl,
	onCompletedAction = () => {},
	onDismissAction = () => {},
	onFailAction = () => {},
	isOnSearchDialog,
	isBulkConnect,
	query = '',
}: OAuthHandlersProps) => {
	const [{ cloudId, user, thirdPartyConfigs }, { setState }] = useBootstrap();
	const { generateSearchUrl, queryParams } = useAppContext();
	const userId = user?.id;
	const { retrySearch } = useSearchActions();
	const { showFlag } = useFlags();
	const { setQuickFindOpen } = useQuickFindActions();
	const onOAuthStarted = useFireOAuthStarted({
		product: product || '',
		cloudId: cloudId || '',
		isOnSearchDialog,
	});
	const onOAuthCompleted = useFireOAuthCompleted({
		product: product || '',
		cloudId: cloudId || '',
		isOnSearchDialog,
	});

	const onSuccess = () => {
		onOAuthCompleted();
		if (product === 'slack') {
			sendSlackAuthSuccessRequest({ cloudId });
		}

		onCompletedAction();

		showFlag({
			title: successFlagTitle,
			icon: <SuccessIcon spacing="spacious" label="" color={token('color.icon.success', G300)} />,
			isAutoDismiss: true,
		});

		const newOutboundAuthConfigs = refreshOAuthCache(
			product as ProductKeys3P,
			userId || '',
			cloudId || '',
			thirdPartyConfigs || {},
		);

		setState({ thirdPartyConfigs: newOutboundAuthConfigs });
		searchPageQueryCache.clear();
		retrySearch();

		if (!isBulkConnect && isOnSearchDialog) {
			setQuickFindOpen(false);
			/* TODO: use onNavigate callback once bootstrap store is lifted. Details in https://hello.atlassian.net/wiki/spaces/CASE/pages/4670854655/3P+Discoverability+Bug
			const onNavigateCallback =
				typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;
			onNavigateCallback(
				generateSearchUrl({
					...queryParams,
					text: query,
					product: product,
				}),
				'push',
			);
			*/
			const searchUrl = generateSearchUrl({
				...queryParams,
				text: query,
				product: product,
			});
			window.location.href = searchUrl;
		}
	};

	const onFail = () => {
		onFailAction();
		showFlag({
			title: failFlagTitle,
			icon: <ErrorIcon spacing="spacious" label="" color={token('color.icon.danger', R400)} />,
			isAutoDismiss: true,
		});
	};

	const onAuthorize = () => {
		onOAuthStarted();
		auth(outboundAuthUrl).then(onSuccess).catch(onFail);
	};

	const onDismiss = () => {
		onDismissAction();
	};

	return { onAuthorize, onDismiss, onSuccess, onFail };
};

export const useIsUserOAuthed = (productKey: ProductKeys | NounKeys | undefined) => {
	const [{ thirdPartyConfigs }] = useBootstrap();
	let userNeedsOAuth, outboundAuthUrl;
	if (!productKey || !is3pProductKey(productKey)) {
		return { userNeedsOAuth, outboundAuthUrl };
	}
	const productOutboundAuthConfig = thirdPartyConfigs[productKey];
	if (!productOutboundAuthConfig) {
		return { userNeedsOAuth, outboundAuthUrl };
	}
	userNeedsOAuth = productOutboundAuthConfig.userNeedsOAuth;
	outboundAuthUrl = productOutboundAuthConfig.oAuthOutboundUrl;

	return { userNeedsOAuth, outboundAuthUrl };
};

import {
	createClient,
	getAssignees,
	JiraScope,
	type User,
	usersQuery,
} from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';
import { loadRecommendedUsers } from '../utils/recommendedUser';

const MAX_SEARCHED_OPTIONS_RESULTS = 5;

const loadInitialOptions = async (config: CloudConfig) => {
	if (config.product === ProductKeys.JiraDC) {
		return loadRecommendedUsers(config);
	}
	return searchJiraCloudAssignee('', config);
};

const searchAssignee = async (query: string, config: CloudConfig) => {
	if (config.product === ProductKeys.JiraDC) {
		return searchAssignees3P(query, config);
	}
	return searchJiraCloudAssignee(query, config);
};

const searchAssignees3P = async (query: string, config: CloudConfig) => {
	if (!config.intl) {
		return []; // base on existing interface, can only query with intl
	}

	const response = await getAssignees({
		query,
		cloudId: config.cloudId || '',
		userId: config.userId || '',
		productKey: ProductKeys.Confluence,
		maxNumberOfResults: MAX_SEARCHED_OPTIONS_RESULTS,
		intl: config.intl,
	});

	if (!response) {
		return [];
	}

	return response.map((assignee) => ({
		trackingKey: assignee.id,
		value: assignee.id,
		label: assignee.name,
		avatarUrl: assignee.avatarUrl,
		queryParamValue: assignee.id,
	}));
};

const searchJiraCloudAssignee = async (query: string, config: CloudConfig) => {
	const searchClient = createClient({
		cloudId: config.cloudId || '',
	});

	const searchAssigneesResponse = await searchClient.search({
		query,
		resultLimit: 5,
		scopes: [JiraScope.People],
		analytics: {
			identifier: 'search-page',
		},
	});
	const [searchAssigneeResults] = searchAssigneesResponse.response.rawData.scopes ?? [];

	const allAssignees = searchAssigneeResults.results.map((user) => ({
		picture: user.avatarUrl,
		name: user.name,
		id: `ari:cloud:identity::user/${user.id}`,
		accountId: user.id,
	})) satisfies User[];

	const userIds = new Set();

	const assigneeOptions = allAssignees
		.filter((user) => !userIds.has(user.accountId) && userIds.add(user.accountId)) // Remove duplicates
		.map((user) => ({
			trackingKey: user.id,
			value: user.id,
			label: user.name,
			avatarUrl: user.picture,
			queryParamValue: user.accountId,
		}));

	return assigneeOptions;
};

const productKeys1P: ProductKeys1P[] = [
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('assignee');

export const filter = createMultiSelectFilter({
	id: 'assignee',
	products: [...productKeys1P, ...products3PFromConfig],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'jAssignee',
		fetcher: async (queryParams, _) => {
			const initialUsersResponse = await usersQuery(queryParams);

			const allAssignees = initialUsersResponse.data?.users ?? ([] satisfies User[]);

			const userIds = new Set();

			const assigneeOptions = allAssignees
				.filter((user) => !userIds.has(user.accountId) && userIds.add(user.accountId)) // Remove duplicates
				.map((user) => ({
					trackingKey: user.id,
					value: user.id,
					label: user.name,
					avatarUrl: user.picture,
					queryParamValue: user.accountId,
				}));
			return assigneeOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return loadInitialOptions(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchAssignee(query, config);
		},
	},
});

import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ClickUpSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-263.5"
				width="2060"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.30859 20.8905L10.5124 18.2109C12.2253 20.6157 14.0334 21.7494 16.0318 21.7494C18.0302 21.7494 19.8065 20.65 21.4243 18.2453L24.6915 20.8562C22.3442 24.2915 19.4259 26.1123 16.0318 26.1123C12.6694 26.1123 9.71936 24.2915 7.30859 20.8905Z"
				fill="#6686FB"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 10.1366L10.0515 15.0505L7.30859 11.9753L16.0331 4.77881L24.6915 11.9753L21.9155 15.0188L16 10.1366Z"
				fill="#FF6479"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 27.657L12.5053 23.8889C14.914 27.2706 17.4566 28.8648 20.2668 28.8648C23.077 28.8648 25.575 27.3189 27.85 23.9372L32.4444 27.6087C29.1436 32.4396 25.0397 35 20.2668 35C15.5385 35 11.3901 32.4396 8 27.657Z"
				fill="url(#paint0_linear_click_up)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2222 12.5343L11.8572 19.4444L8 15.12L20.2687 5L32.4444 15.12L28.5408 19.3999L20.2222 12.5343Z"
				fill="url(#paint1_linear_click_up)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_click_up"
					x1="8"
					y1="29.4499"
					x2="32.4643"
					y2="29.4499"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8930FD" />
					<stop offset="1" stopColor="#49CCF9" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_click_up"
					x1="7.99782"
					y1="12.2197"
					x2="32.4187"
					y2="12.2197"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF02F0" />
					<stop offset="1" stopColor="#FFC800" />
				</linearGradient>
			</defs>
		</svg>
	);
};
